body {
    font-family: $family-sans-serif;
    font-weight: 400;
    font-size: 14px;
}

#wrapper {
    strong,
    b {
        font-weight: $weight-bold;
    }

    button {
        &:focus {
            background: none;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $family-sans-serif;
        font-weight: 700;
    }

    .card {
        img {
            &.card {
                margin: 10px;
            }
        }

        .info {
            font-size: 16px;
            // margin-top: -18px;
            color: #808080;
        }

        &-title {
            display: block;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-content {
            padding: 24px;
        }
    }
}
