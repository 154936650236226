#wrapper {
    .page-content nav[id*="breadcrumb"] {
        background: transparent !important;
    }

    .breadcrumb {
        line-height: 30px;
        border-radius: 0;
        font-weight: 600;
        background: transparent !important;

        @include mobile {
            line-height: 20px
        }
    }

    ul.breadcrumb a {
        font-size: 13px;
        color: #9daba8;
        padding-right: 0;

        @include mobile {
            font-size: 10px;
        }
    }

    ul.breadcrumb li:first-child {
        padding-left: 9em;

        @include mobile {
            padding-left: 1.2em;
        }
    }

    ul.breadcrumb li {
        font-size: 13px;
        background: transparent;
        @include mobile {
            font-size: 10px;
            padding-left: 0px;
        }
    }

    nav ul li {
        background: transparent !important;
    }

    nav ul li.active {
        padding-left: 1.2em;
        font-weight: 700;
        color: #fff;

        @include darkMode {
            color: #fff;
        }
    }
}
