@charset "utf-8";

*,
*:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}


html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;

    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
    color: $text;
    margin: 0;

    &.hide-dockbar {
        top: 0 !important;
        padding-top: 0 !important;

        .page-header {
            top: 0 !important;
        }

        .control-menu {
            display: none;
        }
    }

    &.has-sub-navigation {
        .page-header-main {
            box-shadow: none;
        }
    }
}

body {
    position: relative;
    overflow-y: hidden;
    background-color: $bg-light-primary;
    font-size: 14px;
    line-height: 1.42857;

    &:not(.force-lightmode) {
        @media (prefers-color-scheme: dark) {
            background-color: $bg-dark-primary;
        }
    }

    &.force-lightmode {
        background-color: $bg-light-primary !important;
    }

    &.force-darkmode {
        background-color: $bg-dark-primary !important;
    }

    .brand {
        .icon {
            svg {
                width: 184px;
                height: 64px;

                @include mobile {
                    height: 58px;
                }
            }
        }
    }

    .logo {
        width: 184px;
        height: 64px;

        @include mobile {
            height: 58px;
        }
    }

    &.kundenportal {
        .main-navigation {
            position: relative;
        }

        .main-navigation,
        .secondary-navigation {
            ul {
                li {
                    a {
                        display: flex !important;
                        align-items: center !important;
                        font-size: 16px !important;
                        font-weight: normal !important;
                    }

                    .nav-item-label {
                        flex: 1 1 auto;
                    }
                }
            }
        }
    }

    &.signed-in {
        &.has-control-menu.show-dockbar {
            #wrapper {
                /* FIX because Admina panel on the top*/
                height: calc(100vh - 176px);
                height: calc(var(--vh, 1vh) * 100 - 176px);
            }

            &.staging {
                .portlet-staging-bar {
                    .staging-bar {
                        min-height: 94px !important;
                    }
                }

                #wrapper {
                    /* FIX because Admina panel on the top*/
                    height: calc(100vh - 198px);
                    height: calc(var(--vh, 1vh) * 100 - 198px);
                }
            }

            &.live-view {
                #wrapper {
                    /* FIX because Admina panel on the top*/
                    height: calc(100vh - 141px);
                    height: calc(var(--vh, 1vh) * 100 - 141px);
                }
            }

            &.has-edit-mode-menu {
                #wrapper {
                    /* FIX for edit mode*/
                    height: calc(100vh - 121px) !important;
                    height: calc(var(--vh, 1vh) * 100 - 121px) !important;
                    overflow-x: hidden !important;

                    &:not(.page-editor__wrapper--sidebar--hidden) {
                        .page-content {
                            max-width: calc(1200px + 20%);
                            width: calc(100% - 280px - 280px);
                            padding-left: 60px;

                            @media all and (max-width: calc(1200px + 20%)) {
                                padding-left: 0;
                            }
                        }
                    }
                }

                .page-editor__sidebar {
                    font-size: 17px;
                }
            }
        }

        .portlet-topper-helper {
            display: flex;
            margin-right: 30px !important;

            .portlet-header {
                position: relative;
                width: 12px !important;
                height: 20px;
                left: 3rem;
            }
        }

        .visible-interaction {
            z-index: 999;
            position: absolute;
            right: 11px;
            top: 7px;
            height: 32px;

            .lexicon-icon {
                color: #000;
            }

            .btn {
                padding: 0 !important;
                font-size: 16px !important;
                height: 32px !important;
                width: 32px !important;
                background: none !important;
                margin-top: -8px !important;
                align-items: center !important;
                display: inline-flex !important;
                vertical-align: middle !important;
                box-shadow: none !important;
                -webkit-box-shadow: none !important;
                border-radius: 4px !important;
                line-height: 24px !important;
                color: #000 !important;

                &:hover {
                    background: rgba(39, 40, 51, 0.03) !important;
                }
            }
        }
    }
}

.icon {
    display: inline-flex;
}

.page-header {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    margin: 0;
    padding: 0;
    max-width: 100%;
    border: 0 none;

    .has-control-menu & {
        top: 65px;

        @include mobile {
            top: 49px;
        }
    }

    &-main,
    &-secondary {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
        height: 64px;

        @include mobile {
            height: 58px;
            padding: 0 22px;
        }
    }

    &-main {
        background-color: $bg-light-primary;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

        @include darkMode {
            background-color: $bg-dark-secondary;
        }
    }

    &-secondary {
        display: none;
        background-color: #f2f3f2;
        color: var(--theme-primary, #{map-get($theme-background, "light")});
    }

    .toggle-navigation {
        border: 0 none;
        background: transparent;
        color: #ffffff;

        &:active,
        &:focus {
            background: transparent !important;
            color: #ffffff;
        }
    }

    .brand {
        display: inline-block;
        height: 100%;

        @include tablet {
            margin-left: 24px;
        }

        .icon {
            position: relative;
            max-height: 100%;
        }
    }

    .toggle-search {
        display: inline-flex;
        @include tablet {
            margin-left: auto;
        }
    }
}

#wrapper {
    height: calc(100vh);
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    overflow-y: auto;

    &.merkur-app {
        scrollbar-color: $accent $bg-dark-secondary;

        ::-webkit-scrollbar {
            width: 12px;
        }

        ::-webkit-scrollbar-thumb {
            background: $accent;
            border-radius: 8px;
        }
    }

    .page-header {
        top: 0;

        a.toggle-search,
        a.sign-in,
        a.sign-out {
            color: #ffffff;
        }

        a.sign-in,
        a.sign-out {
            display: inline-flex;
            align-items: center;
            padding: 5px 20px;
            border-radius: 99999em;
            font-size: 18px;
            font-weight: 700;

            @include tablet {
                margin-left: 3rem;
            }

            .icon {
                fill: #fff;
                @include tablet {
                    padding-right: 10px;
                }
            }
        }

        a.sign-in {
            background-color: var(--theme-accent, #{map-get($theme-colors, "accent")});
        }

        .page-header-subnav {
            background-color: #566367;
            box-shadow: 0 2px 10px -3px rgba(0, 0, 0, 0.2);

            @include darkMode {
                background-color: $bg-dark-secondary;
            }

            @include mobile {
                display: none;
            }

            .navbar {
                margin: 0;
                padding-left: 47px;

                > ul {

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    gap: 0.25rem 1rem;
                }

                ul.child-menu {
                    display: none;
                }

                li {
                    &.active {
                        a {
                            color: $white;
                            border-bottom-color: $white;
                            text-decoration: none;
                        }
                    }
                }

                a {
                    color: $grey-light;
                    text-transform: uppercase;
                    border-bottom: 2px solid transparent;
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                    outline: none;
                    padding: 10px 15px;
                    position: relative;
                    word-wrap: break-word;

                    .icon {
                        width: 2rem;
                        height: 2rem;
                        display: none;

                        > * {
                            width: 100%;
                            height: auto;
                        }
                    }

                    &:hover {
                        color: $white;
                        border-bottom-color: $white;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

#wrapper .page-navigation {
    position: fixed;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    max-width: 360px;
    height: calc(100vh);
    height: calc(var(--vh, 1vh) * 100);
    background-color: #{map-get($theme-colors, "bg-light-primary")};
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    transform: translateX(-100em);
    transition: transform 0.32s ease-out;

    @include darkMode {
        background-color: $bg-dark-secondary;
    }

    &-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 90;
        display: none;
        width: 100vw;
        height: 100vh;
        background-color: rgba(#1d2318, 0.2);
    }

    .nav-item-icon {
        margin-right: 20px;
        width: 28px;
        height: 28px;

        svg {
            width: 28px;
            height: 28px;

            &.icon-login {
                fill: #fff;
            }
        }
    }

    nav {
        background-color: transparent;
    }

    .portlet-title-text {
        display: none;
    }

    &-header {
        display: flex;
        flex: 0 0 auto;
        flex-direction: row;
        align-items: center;
        margin: 0;
        padding: 0 40px;
        height: 65px;
        background-color: $bg-light-primary;
        // box-shadow: 2px 0 10px 0 rgba(0,0,0,0.2);

        @include darkMode {
            background-color: $bg-dark-secondary;
        }

        @include mobile {
            margin: 0 0 10px;
        }

        .has-control-menu.show-dockbar:not(.staging) & {
            margin-top: 196px;
        }

        .has-control-menu.staging & {
            margin-top: 199px;
        }

        .brand {
            margin-left: 14px;
        }

        .toggle-navigation {
            flex: 0 0 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            width: 40px;
            height: 40px;
            background: transparent;
            border: 0 none;
            color: #ffffff;
            font-weight: 700;
            font-size: 40px;
        }
    }

    .secondary-navigation {
        .list-menu {
            margin-bottom: 36px;
        }

        ul {
            li {
                a {
                    @include mobile {
                        padding: 8px 20px 8px 30px !important;
                    }
                }
            }
        }

    }

    .main-navigation-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 1 auto;
        max-height: 100%;
        padding: 40px 0 0 8px;
        overflow-y: scroll;

        .main-navigation {
            flex: 1 0 auto;
            min-height: 40vh;
            margin-bottom: 40px;

            ul {
                li {
                    a {
                        font-size: 19px;
                    }
                }

                &.level-1 {
                    > li {
                        > a {
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        .secondary-navigation {
            flex: 0 auto;

            ul {
                li {
                    margin-bottom: 10px;

                    a {
                        font-size: 16px;
                    }
                }
            }
        }

        .main-navigation,
        .secondary-navigation {
            height: auto;
            line-height: inherit;

            .navigation-container {
                margin-bottom: 36px;
            }

            ul {
                width: 100%;
                margin: 0;
                list-style: none;

                &.level-1 {
                    padding: 0 22px;
                }

                li {
                    float: none;

                    &.active {
                        background: transparent;
                        @include darkMode {
                            background-color: transparent;
                        }
                    }

                    ul {
                        display: none;
                        margin: 0;
                        border-radius: 0 0 10px 10px;
                        background-color: #FFFFFF33;
                        @include darkMode {
                            background-color: #283135;
                        }
                        overflow: hidden;
                    }

                    &.selected.active ul,
                    &.is-open {
                        ul {
                            display: block;
                        }
                    }

                    &,
                    a {
                        display: block;
                        width: 100%;
                    }

                    a {
                        padding: 12px 20px 12px 30px;
                        border-radius: 10px;
                        line-height: 24px;
                        font-weight: 700;
                        color: #ffffff;

                        &:hover {
                            @include darkMode {
                                background-color: #283135;

                            }
                        }

                        &.selected.active {
                            background-color: #FFFFFF33;
                            @include darkMode {
                                background-color: rgba(0, 0, 0, 0.16) !important;
                            }
                        }


                        &.is-open {
                            &.active:not(.selected) {
                                @include darkMode {
                                    background-color: #283135;

                                }
                            }

                            &:not(.selected.active) {
                                &:not(:hover) {
                                    background-color: #FFFFFF33;
                                    @include darkMode {
                                        background-color: #283135;
                                    }
                                }


                                + ul li a {
                                    background: transparent;
                                    @include darkMode {
                                        background-color: #283135;
                                        &:hover {
                                            background-color: rgba(0, 0, 0, 0.16) !important;
                                        }
                                    }
                                }
                            }
                        }

                        &.has-submenu {
                            display: flex;

                            .icon {
                                flex: 0 0 auto;
                                display: inline-flex;
                                margin-left: auto;
                                transform: rotate(90deg);
                                pointer-events: none;
                            }

                            &.is-open {
                                border-radius: 10px 10px 0 0;

                                .icon {
                                    transform: rotate(-90deg);
                                }
                            }
                        }

                        &.is-actual {
                            display: none;
                        }
                    }

                    ul {
                        grid-area: 2 / 1 / 3 / 3;

                        li {
                            a {
                                padding: 10px 40px;
                                border-radius: 0;
                                font-weight: 400;
                            }
                        }
                    }

                    ul:not(.level-1) {

                        li {
                            a {
                                padding: 10px 20px 10px 50px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }
        }
    }

    .portlet-topper {
        a {
            color: inherit;

            &:hover {
                color: $secondary-color;
            }
        }
    }
}

body.signed-in.kundenportal,
body.signed-in.sitepage-vertrieb {
    #wrapper .page-navigation {
        max-height: 100%;
        bottom: 0;
        top: auto;
        padding-bottom: 72px;
        margin-bottom: 0;

        .main-navigation-container .main-navigation,
        .main-navigation-container .main-navigation .navigation-container {
            margin-bottom: 0;
        }
    }

    .sign-out-container {
        position: absolute;
        left: 0;
        right: 12px;
        bottom: 0;
        padding: 10px;
        list-style: none;


        height: auto !important;
        line-height: inherit !important;

        .navigation-container {
            margin-bottom: 36px;
        }

        ul {
            width: 100%;
            margin: 0;
            list-style: none;

            &.level-1 {
                padding: 0 30px;
            }

            li {
                float: none !important;

                &.active {
                    background: transparent;
                    @include darkMode {
                        background-color: transparent;
                    }
                }

                ul {
                    display: none;
                    margin: 0;
                    border-radius: 0 0 10px 10px;
                    background-color: #FFFFFF33;
                    @include darkMode {
                        background-color: #283135;
                    }
                    overflow: hidden;
                }

                &.selected.active ul,
                &.is-open {
                    ul {
                        display: block;
                    }
                }

                &,
                a {
                    display: block;
                    width: 100%;
                }

                a {
                    padding: 12px 20px 12px 30px !important;
                    border-radius: 10px;
                    line-height: 24px;
                    font-weight: 700;
                    color: #ffffff;

                    &:hover {
                        @include darkMode {
                            background-color: #283135;

                        }
                    }

                    &.selected.active {
                        background-color: #FFFFFF33;
                        @include darkMode {
                            background-color: rgba(0, 0, 0, 0.16) !important;
                        }
                    }


                    &.is-open {
                        &.active:not(.selected) {
                            @include darkMode {
                                background-color: #283135;

                            }
                        }

                        &:not(.selected.active) {
                            &:not(:hover) {
                                background-color: #FFFFFF33;
                                @include darkMode {
                                    background-color: #283135;
                                }
                            }


                            + ul li a {
                                background: transparent;
                                @include darkMode {
                                    background-color: #283135;
                                    &:hover {
                                        background-color: rgba(0, 0, 0, 0.16) !important;
                                    }
                                }
                            }
                        }
                    }

                    &.has-submenu {
                        display: flex;

                        .icon {
                            flex: 0 0 auto;
                            display: inline-flex;
                            margin-left: auto;
                            transform: rotate(90deg);
                            pointer-events: none;
                        }

                        &.is-open {
                            border-radius: 10px 10px 0 0;

                            .icon {
                                transform: rotate(-90deg);
                            }
                        }
                    }

                    &.is-actual {
                        display: none;
                    }
                }

                ul {
                    grid-area: 2 / 1 / 3 / 3;

                    li {
                        a {
                            padding: 10px 40px;
                            border-radius: 0;
                            font-weight: 400;
                        }
                    }
                }

                ul:not(.level-1) {

                    li {
                        a {
                            padding: 10px 20px 10px 50px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }

    }

    .page-navigation .main-navigation-container {
        ul {
            li {
                a {
                    display: flex !important;
                    align-items: center !important;
                    font-size: 16px !important;
                    font-weight: normal !important;
                }

                .nav-item-label {
                    flex: 1 1 auto;
                }
            }
        }
    }


    .main-navigation-container .sign-out-container,
    .navigation-container.kundenportal {
        ul {
            li {
                a {
                    display: flex;
                    font-size: $size-5;
                    font-weight: $weight-normal;
                    color: $white;
                }

                ul {
                    li {
                        a {
                            color: $grey-light;
                        }
                    }
                }
            }

            .nav-item-label {
                flex: 1 1 auto;
            }
        }
    }
}

.is-navigation-visible {
    #wrapper {
        overflow: hidden;

        .page-navigation {
            transform: translateX(0);

            &-backdrop {
                display: block;
            }
        }
    }
}

.page-content {
    position: relative;
    z-index: 2;
    margin: 0 auto;
    padding: 0 0 100px;
    max-width: 100%;
    min-height: 420px;
    overflow: hidden;
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}

#wrapper .page-content .container {
    margin-right: auto;
    margin-left: auto;
    padding: 0 10%;
    width: 100%;
    max-width: calc(1200px + 20%);

    @include mobile {
        padding: 0 2rem;
    }
}

.merkur-happy {
    &-container {
        position: relative;
        width: 100%;
        height: 0;
        display: none;

        @include desktop {
            display: block;
        }
    }

    .happy {
        vertical-align: unset;
        width: 400px;
    }

    position: absolute;
    z-index: auto;
    right: -78px;
    bottom: 0;
}

#wrapper .page-footer {
    position: relative;
    z-index: 2;
    width: calc(100vw - 20%);
    max-width: 1200px;
    margin: 60px auto 0;
    padding: 40px 0;
    background: transparent;

    .alert {
        display: none;
    }

    .portlet-title-text {
        display: none;
    }

    .footer-top,
    .footer-bottom {
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-column-gap: 40px;

        @include mobile {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, auto);
        }

        &.hide-follow-us {
            grid-template-columns: 1fr;
        }
    }

    .footer-top {
        @include mobile {
            display: block;
        }
    }

    .footer-bottom {
        padding-top: 40px;
    }

    .footer-bottom {
        border-top: 1px solid $white;
    }

    &-sitemaps {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
        grid-gap: 40px;
        padding: 0 0 60px;
        font-size: 14px;
        line-height: 32px;

        @include tablet {
            grid-area: 1 / 1 / 2 / 2;
        }

        @include tablet-only {
            grid-template-columns: repeat(2, 1fr);
        }

        @include mobile {
            grid-template-columns: repeat(2, 50%);
            grid-gap: 10px;
        }

        ul {
            li {
                a {
                    display: block;
                    font-weight: 700;
                    color: $white;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                + ul {
                    margin-top: 34px;
                }

                ul {
                    margin-bottom: 32px;

                    li {
                        a {
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    &-social {
        display: flex;
        flex-direction: column;
        padding: 0 0 60px;

        @include tablet {
            grid-area: 1 / 2 / 2 / 3;
        }

        > a {
            margin-bottom: 10px;
        }

        h5,
        h5.title,
        h5.title.is-5 {
            margin: 32px 0 12px;
            font-weight: 700;
            font-size: 14px;
        }

        &-links {
            display: flex;
            flex-direction: row;
            align-items: center;

            a {
                display: inline-flex;
                color: $white;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }

        .has-store-icons {
            a {
                display: block;
            }

            &.has-quality-seal-icons {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        }

        .has-quality-seal-icons {

        }


    }

    &-corporate {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
            width: 100%;
        }

        .brand {
            @include mobile {
                display: none;
            }
        }

        .portlet {
            margin: 0;
        }

        .list-menu {
            max-width: 100vw;
            overflow-x: hidden;

            ul {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-column-gap: 40px;
                grid-row-gap: 20px;
                margin: 0;

                @include mobile {
                    grid-template-columns: repeat(2, 1fr);
                    grid-column-gap: 0;
                }

                @include tablet-only {
                    grid-template-columns: repeat(2, 1fr);
                }

                li {
                    display: inline-block;

                    @include mobile {
                        display: block;
                    }

                    &:not(:last-child) {
                        margin-right: 40px;
                    }

                    a {
                        color: $white;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .location-dropdown {
        position: relative;

        @include mobile {
            padding-top: 20px;
        }

        .location-dropdown-menu {
            display: inline-block;
            position: absolute;
            bottom: 20px;
            left: 0;
            margin: 0;
            padding: 16px;
            border-radius: 10px;
            background-color: $white;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
            list-style: none;
            transform: translateY(2em);
            opacity: 0;
            pointer-events: none;
            transition: all 0.2s ease-in-out;
        }

        &:hover .location-dropdown-menu,
        .location-dropdown-menu:hover,
        &-toggle:hover + .location-dropdown-menu,
        &-toggle:focus + .location-dropdown-menu {
            transform: translateY(0);
            opacity: 1;
            pointer-events: auto;
        }

        &-toggle,
        &-item a {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        &-item {
            &:not(:last-child) {
                margin-bottom: 16px;
            }

            a {
                width: 100%;
                color: $bg-light-primary;
            }
        }

        &-toggle {
            color: $white;

            .icon {
                flex: 0 0 auto;
            }
        }
    }
}

#wrapper .page-content nav {
    height: auto;
    line-height: inherit;
}
