@use "sass:math";

#wrapper .portlet form:not([class]) {
    margin-right: auto;
    margin-left: auto;
    padding: 0 10%;
    width: 100%;
    max-width: calc(1200px + 20%);

    @include mobile {
        padding: 0 2rem;
    }

    h1, h2, h3, h4, h5, h6 {
        @extend .title;

        margin-top: 0;

        &.size-e {
            @extend .is-1;
        }
    }

    .content {
        h2 {
            @extend .is-2;
        }
    }

    .form-group {
        max-width: 540px;
        padding: 60px;
        border-radius: 10px;
        background-color: $white;
        box-shadow: 0 6px 10px 0 rgba($box-shadow-color, 0.12), 0 12px 18px 0 rgba($box-shadow-color, 0.2), 0 20px 40px -1px rgba($box-shadow-color, 0.12);
        font-size: 16px;

        h1, h2, h3, h4, h5, h6, & {
            color: $primary-color;
        }

        > div > p {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 12px 16px;
            min-height: 64px;
            border-radius: 6px;
            background-color: $bg-grey;

            &,
            label {
                color: $primary-color;
            }

            input[type="text"],
            input[type="email"],
            input[type="number"],
            input[type="file"],
            input[type="phone"],
            textarea {
                height: 2.6rem;
                margin: 0;
                border: 0 none !important;
                font-size: 16px;
                color: $primary-color;

                &:focus {
                    border: 0 none;
                    box-shadow: none;
                    color: $secondary-color;
                }
            }
        }
    }

    p.mb-b {
        max-width: 540px;

        button {
            @extend .button;
            border-color: $secondary-color;
            background-color: $secondary-color;
            color: $white;
        }
    }

    .check-a [type="checkbox"]:checked,
    .check-a [type="checkbox"]:not(:checked) {
        position: static;
        opacity: 1;
        pointer-events: auto;
    }
}

$switch-width-number: 2.75;
$switch-width: $switch-width-number * 1em;
$switch-padding: 0.1em;

$switch-active-background-color: $secondary-color !default;

#wrapper .merkur-switch {
    display: inline-flex !important; // stylelint-disable-line declaration-no-important
    align-items: center;
    cursor: pointer;

    & + .switch {
        margin-left: 0.5em;
    }

    input[type="checkbox"] {
        position: absolute;
        z-index: -1;
        left: 0;
        outline: none;
        opacity: 0;

        + .check {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            width: $switch-width;
            height: #{$switch-width * 0.5 + $switch-padding};
            padding: $switch-padding;
            transition: background 0.14s ease-in;
            border-radius: 1em;
            background: $bg-grey;

            &:before {
                content: "";
                display: block;
                width: #{($switch-width - $switch-padding * 2) * 0.5};
                height: #{($switch-width - $switch-padding * 2) * 0.5};
                transition: transform 0.14s ease-in, width 0.14s ease-in;
                border-radius: 1em;
                background: $white;
                box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.05);
                will-change: transform;
            }

            &.is-elastic:before {
                width: 1.75em;
            }
        }

        &:checked + .check {
            background: $switch-active-background-color;

            &:before {
                transform: translate3d(100%, 0, 0);
            }

            &.is-elastic:before {
                // Might be a little offset if base font is not 16px
                transform: translate3d(percentage(math.div(16, $switch-width-number * 16)), 0, 0);
            }
        }
    }

    .control-label {
        padding-left: 0.5em;
    }

    &:hover {
        input[type="checkbox"] + .check {
            background: rgba($bg-grey, 0.9);
        }

        input[type="checkbox"]:checked + .check {
            background: rgba($switch-active-background-color, 0.9);
        }
    }

    &:focus {
        outline: none;

        input[type="checkbox"] + .check {
            box-shadow: 0 0 0.5em rgba($text-dark-primary, 0.6);
        }

        input[type="checkbox"]:checked + .check {
            box-shadow: 0 0 0.5em rgba($switch-active-background-color, 0.8);
        }
    }

    &[disabled] {
        opacity: 0.5;
        color: $text-dark-primary;
        cursor: not-allowed;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}


code {
    background: none;
    color: inherit;
}
