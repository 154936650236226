#wrapper {
    .big-portrait-cards-container {
        color: $white;

        &.primarygrey {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }
        }

        &.secondarygrey {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }
        }

        .big-portrait-cards {
            margin-right: auto;
            margin-left: auto;
            padding: 16px 10%;
            width: 100%;
            max-width: calc(1200px + 20%);
            z-index: 5;
            position: relative;

            .productLink {

                .icon {
                    display: inline-block;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                    background-color: $secondary-color;
                    color: $white;
                    text-align: center;
                    vertical-align: baseline;

                    svg {
                        vertical-align: text-top;
                        margin-left: 2px;
                        margin-top: 7px;
                    }
                }
            }

            .ratio-box {
                display: grid;
                grid-gap: 3rem 3rem;
                grid-auto-rows: 1fr;
                width: calc(0.8 * 100vw);
                max-width: 1200px;
                padding-top: 30px;

                &.gridBig {
                    grid-template-columns: repeat(2, 1fr);
                }

                &.gridSmall {
                    grid-template-columns: repeat(3, 1fr);
                }

                @include mobile {
                    grid-template-columns: repeat(1, 1fr) !important;
                }

                @include tablet-only {
                    grid-template-columns: repeat(2, 1fr) !important;
                }

                .icons {
                    align-self: flex-end;
                    position: absolute;
                    top: 30px;
                    height: 18.5px;
                    z-index: 20;
                    right: 14%;

                    .help {
                        display: inline-block;
                        position: relative;

                        &:hover {
                            .help-tooltip {
                                display: inline-block;
                            }
                        }

                        .helpIcon {
                            display: inline-block;
                            border-radius: 50%;
                            width: 30px;
                            height: 30px;
                            text-align: center;
                            cursor: pointer;
                        }

                        .help-tooltip {
                            display: none;
                            width: 250px;
                            background: $border-dark-color;
                            border-radius: 10px;
                            color: $grey-dark;
                            padding: 15px;
                            position: absolute;
                            margin-left: 0;
                            z-index: 50;
                            cursor: pointer;
                            overflow: hidden;
                            top: 100%;
                            transform: translate(0, 0);
                            left: auto;
                            right: -20px;

                            @include desktop {
                                left: 0;
                                top: 100%;
                                transform: translate(-50%, 0);
                            }

                            .help-text {
                                display: block;
                                font-weight: 600;
                                padding-bottom: 10px;
                            }
                        }

                        .helpIcon:hover + .help-tooltip {
                            display: inline-block;
                        }
                    }

                    .link {
                        display: inline-block;
                        border-radius: 50%;
                        width: 30px;
                        height: 30px;
                        background-color: $secondary-color;
                        color: $white;
                        text-align: center;
                        vertical-align: top;
                        cursor: pointer;

                        .icon-chevron-right {
                            margin-left: 3px;
                            text-align: center;
                            vertical-align: text-top;
                            margin-top: 9px;
                        }

                        &.has-min-width {
                            min-width: 30px !important;
                        }
                    }
                }

                .card {
                    color: $secondary-color;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    padding: 50px 10% 0 10%;
                    width: 100%;
                    max-width: calc(400px + 20%);
                    height: 100%;

                    @include darkMode {
                        background-color: $bg-dark-cards;
                        color: $white;
                    }

                    .card-image {
                        display: flex;
                        color: currentColor;
                    }

                    .card-content {
                        max-width: 100%;
                        padding: 16px;
                        overflow: hidden;

                        .card-main-title {
                            font-weight: $weight-bold;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            max-height: 100%;
                            overflow: hidden;
                            line-break: normal !important;
                            text-align: center;
                            text-overflow: unset !important;
                            word-break: break-word;
                            white-space: normal !important;
                            -webkit-hyphens: auto;
                            -ms-hyphens: auto;
                            hyphens: auto;
                            color: $secondary-color;
                            margin-bottom: 16px;

                            &.standard {
                                font-size: 20px;
                            }

                            @include darkMode {
                                color: $white;
                            }
                        }

                        .card-sub-title {
                            font-family: $family-sans-serif;
                            font-weight: $weight-bold;
                            line-height: 110%;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            max-height: 100%;
                            overflow: hidden;
                            line-break: normal !important;
                            text-align: center;
                            text-overflow: unset !important;
                            word-break: break-word;
                            white-space: normal !important;
                            -webkit-hyphens: auto;
                            -ms-hyphens: auto;
                            hyphens: auto;
                            color: $secondary-color;
                            margin-bottom: 16px;

                            @include darkMode {
                                color: $white;
                            }
                        }

                        .card-text {
                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;
                            max-height: 100%;
                            overflow: hidden;
                            line-break: normal !important;
                            text-align: center;
                            text-overflow: unset !important;
                            word-break: break-word;
                            white-space: normal !important;
                            -webkit-hyphens: auto;
                            -ms-hyphens: auto;
                            hyphens: auto;

                            @include darkMode {
                                color: $white;
                            }
                        }
                    }
                }

                .ratio-box-2x3 {
                    .card {
                        .card-content {
                            .card-sub-title {
                                -webkit-line-clamp: 3;
                            }

                            .card-main-title {
                                -webkit-line-clamp: 4;
                            }

                            .card-text {
                                -webkit-line-clamp: 5;
                            }
                        }
                    }
                }
            }
        }
    }
}
