#wrapper {
    .vertriebsinfo {
        span {
            &.badge:not(.red) {
                background-color: $primary-color !important;
            }

            &.badge {
                color: #fff;
            }
        }

        h3.title {
            color: $primary-color !important;

            @include darkMode {
                color: $white !important;
            }
        }

        .articlecontent {
            height: auto;
            margin: auto;
            width: auto;
            max-width: 1920px;
            opacity: 1;
            padding: 0 10px;
            transform: none;
            transition: opacity 500ms cubic-bezier(0.4, 0, 1, 1) 0s;
            will-change: transform;
            position: relative;
        }

        .collection-item {
            border: none !important;
            border-bottom: 1px solid rgb(224, 224, 224) !important;

            &:last-child {
                border-bottom: none !important;
            }

            p {
                @include darkMode {
                    color: $white;
                }
            }

            @include darkMode {
                color: $white;
                background-color: $bg-dark-secondary;
            }
        }

        .collection {
            border: none !important;
            border-radius: 10px;

            a,
            a:hover,
            a:visited,
            a:focus {
                &.collection-item {
                    padding-bottom: 20px;
                    color: rgba(0, 0, 0, 0.87);
                    text-decoration: none;
                    background-color: $white;

                    @include darkMode {
                        background-color: $bg-dark-secondary !important;
                    }
                }

                h3 {
                    color: rgba(0, 0, 0, 0.87);
                }

                span {
                    &.badge {
                        &.grey {
                            &.darken-1 {
                                color: $white;
                            }
                        }
                    }
                }

                p {
                    font-size: $size-6 !important;
                }
            }
        }

        @include desktop {
            .articlecontent {
                max-width: 100%;
            }
        }

        @include tablet {
            .articlecontent {
                max-width: 100%;
            }
        }

        @include mobile {
            .articlecontent {
                max-width: 100%;

                .row {
                    .col {
                        padding: 0;
                    }
                }
            }
        }
    }

    /* ---- small screens ---- */
    @media screen and (max-width: 600px) {
        .articlecontent {
            max-width: 100%;
            padding: 0 10px;
        }

        .articlecontent .row .col {
            padding: 0;
        }
    }

}
