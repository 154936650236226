#wrapper {
    .editor-mode {
        padding: 3rem 0;
        max-width: 100%;
        display: block;
        color: $grey-light;
    }

    .contest {
        &-teaser,
        &-details {
            &.is-loading {
                color: transparent !important; // stylelint-disable-line declaration-no-important
                cursor: default;
                pointer-events: none;

                body.signed-in & {
                    height: calc(100vh - 65px - 5rem - 95px);
                    height: calc(var(--vh, 1vh) * 100 - 65px - 5rem - 95px);
                }

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: calc(50% - 2.5em);
                    left: calc(50% - 2.5em);
                    width: 5em;
                    height: 5em;
                    animation: spinAround 500ms infinite linear;
                    border: 2px solid rgba($white, 0.5);
                    border-top-color: transparent;
                    border-right-color: transparent;
                    border-radius: 100%;
                }
            }
        }

        &-teaser {
            margin: 5rem 0;

            &.whole-clickable {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                flex-direction: column;
                overflow: hidden;

                &:not(.is-loading) {
                    border-radius: 10px;
                    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
                }

                .contest-teaser-text-container {
                    padding: 1rem;
                    background: $white;
                }

                .contest-teaser-link {
                    color: $primary-color;

                    .contest-teaser-text {
                        color: inherit;
                    }

                    &:hover .title {
                        color: var(--theme-accent, map-get($theme-colors, "accent"));
                    }
                }
            }

            &.button-clickable {
                @include mobile {

                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    flex-direction: column;
                    overflow: hidden;
                    color: $primary-color;

                    &:not(.is-loading) {
                        border-radius: 10px;
                        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
                    }
                }

                .contest-teaser-link {
                    display: grid;
                    grid-template-columns: 2fr 3fr;
                    grid-template-rows: 1fr;
                    grid-gap: 100px;
                    pointer-events: none;
                    color: $white;

                    @include mobile {
                        display: block;
                        color: inherit;
                        pointer-events: auto;
                        &:hover .title {
                            color: var(--theme-accent, map-get($theme-colors, "accent"));
                        }
                    }
                }

                .title {
                    color: inherit;
                }

                img {
                    border-radius: 10px;
                    @include mobile {
                        border-radius: 0;
                        display: block;
                        width: 100%;
                        height: auto;
                        object-fit: contain;
                    }
                }

                button {
                    pointer-events: auto;
                    @include mobile {
                        display: none;
                    }
                }

                .contest-teaser-text-container {

                    @include mobile {
                        padding: 1rem;
                        background: $white;
                        display: block;
                    }
                }
            }

            img {
                display: block;
                width: 100%;
                height: auto;
                object-fit: contain;
            }

            .title {
                transition: all 0.14s ease-in-out;
                color: $primary-color;
            }

        }

        &-details {
            margin-top: 5rem;
            display: flex;
            gap: 2rem;
            flex-direction: column;


            .top-bar {
                display: flex;
                gap: 1rem;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;
                align-items: center;
                font-weight: 700;
                font-size: 1.6rem;

                .icon-back {
                    cursor: pointer;
                    transition: all 0.14s ease-in-out;

                    &:hover {
                        color: $secondary-color;
                    }
                }
            }

            &-container {
                display: flex;
                gap: 1rem;
                flex-direction: column;
                flex-wrap: wrap;
                margin-bottom: 2rem;
                @include mobile {
                    margin-bottom: 4rem;
                }

                .contest-image {
                    width: 100%;
                    max-width: calc(1200px + 2rem);
                    margin: 0 auto;
                }

                .contest-title {
                    font-weight: 600;
                    font-size: 2rem;
                    line-height: 36px;
                    transition: all 0.14s ease-in-out;

                    @include mobile {
                        font-size: 1.8rem;
                    }
                }
            }

            .contest-form {
                display: flex;
                flex-direction: column;
                gap: 2rem;

                .form {
                    display: flex;
                    gap: .5rem;
                    flex-direction: column;

                    /*&-title {
                      font-weight: 700;
                      font-size: 1.6rem;
                    }*/

                    .merkur-form-fields {
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                        margin-bottom: 3rem;

                        .merkur-checkbox {

                            display: flex;
                            flex-direction: column;
                            gap: 0.5rem;

                            [type=checkbox] {
                                + span {
                                    color: $white;
                                    font-size: 20px;
                                    font-weight: 400;

                                    &:before {
                                        opacity: 0;
                                        width: 7px;
                                        height: 15px;
                                        transform: rotate(45deg);
                                        border-right-color: white;
                                        border-bottom-color: white;
                                        left: 2px;
                                        top: 0;
                                        z-index: 1;
                                        transform-origin: 100% 100%;
                                    }

                                    &:after {
                                        width: 24px;
                                        height: 24px;
                                        border-radius: 100%;
                                        margin: 0;
                                        transform: scale(1);
                                        border: 1px solid $link-color;
                                    }
                                }

                                &:hover {
                                    + span {
                                        &:before {
                                            opacity: 1;
                                        }
                                    }

                                }

                                &:checked {
                                    + span {
                                        &:before {
                                            opacity: 1;
                                        }

                                        &:after {
                                            background: $link-color;
                                        }
                                    }
                                }
                            }

                            .dccs-help {
                                padding-left: 35px;
                                font-weight: 400;
                                font-size: 1.4rem;

                                &.is-danger {
                                    color: $error-color;
                                }
                            }
                        }

                        .error-message {
                            color: $error-color;
                        }
                    }
                }

                a {
                    text-decoration: underline;
                }
            }

            .success {
                &-container {

                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    max-width: 260px;
                    margin: 0 auto;

                }

                &-title {
                    font-weight: 700;
                    font-size: 1.5rem;
                }

                &-image {
                    margin: 0 auto;
                }
            }

            .bottom-bar {
                text-align: center;

                .button {
                    margin: 0;
                }
            }
        }
    }
}
