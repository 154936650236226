#wrapper {
    .maphq {
        padding-top: 140px;

        #map {
            margin: auto;
            max-width: 90%;
            max-height: 500px;
            height: 500px;
        }
    }
}
