#wrapper {
    .case-studies {

        &.primarygrey {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }
        }

        &.secondarygrey {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }
        }

        .container {
            padding: 16px 10%;

            > .title {
                text-align: center;
            }

            .absatz-text {
                text-align: center;
            }

            .bild-video-container {
                height: 200px;
                display: grid;
                position: relative;

                &.video-visible {
                    .case-study-video {
                        display: block !important;
                    }

                    .platzhalter-bild {
                        display: none !important;
                    }

                    .video-icon {
                        display: none !important;
                    }
                }

                .platzhalter-bild {
                    max-height: 200px;
                    grid-column: 1;
                    grid-row: 1;
                    margin: auto;
                    width: 100%;
                    object-fit: cover;

                    &.original-size {
                        width: auto;
                        height: auto;
                        margin: auto 0;
                    }
                }

                .video-icon {
                    border: 2px solid $white;
                    border-radius: 50%;
                    width: 100px;
                    height: 100px;
                    grid-column: 1;
                    grid-row: 1;
                    margin-top: 50px;
                    margin-right: auto;
                    margin-left: auto;
                    cursor: pointer;
                    position: absolute;
                    left: 40%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @include mobile {
                        width: 50px;
                        height: 50px;
                        left: 45%;

                    }

                    svg {
                        color: $white;
                        width: 50px;
                        height: 50px;

                        @include mobile {
                            width: 25px;
                            height: 25px;
                        }
                    }
                }

                .case-study-video {

                    &.has-picture {
                        display: none;
                    }

                    iframe {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                    }
                }
            }

            .case-study-link {
                .icon {
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    height: 20px;
                    width: 20px;
                    border-radius: 20px;
                    background-color: $secondary-color;
                    color: $white;
                }
            }
        }

        &:not(.is-fragment) {
            .case-study-container {
                display: flex;
                align-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                flex-direction: row;

                @include mobile {
                    display: block;
                }

                @include tablet-only {
                    display: block;
                }

                .case-study {
                    width: 33.33333%;
                    min-width: 33.33333%;
                    max-width: 33.33333%;
                    color: $white;
                    margin: 60px 0 0 0;
                    float: none;

                    @include mobile {
                        width: 100%;
                        min-width: 100%;
                        max-width: 100%;
                        display: block;
                        margin-right: 0;
                    }

                    @include tablet-only {
                        width: 50%;
                        min-width: 50%;
                        max-width: 50%;
                        display: block;
                    }
                }
            }
        }

        &.is-fragment {
            .case-study-container {
                > div {

                    display: flex;
                    align-content: flex-start;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    flex-direction: row;

                    @include mobile {
                        display: block;
                    }

                    @include tablet-only {
                        display: block;
                    }

                    > div {
                        width: 33.33333%;
                        min-width: 33.33333%;
                        max-width: 33.33333%;
                        color: $white;
                        margin: 60px 0 0 0;
                        float: none;

                        @include mobile {
                            width: 100%;
                            min-width: 100%;
                            max-width: 100%;
                            display: block;
                            margin-right: 0;
                        }

                        @include tablet-only {
                            width: 50%;
                            min-width: 50%;
                            max-width: 50%;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
