.gallery-slider {
    &.has-edit-mode {
        &.initialize {
            .image {
                display: none;
            }
        }

        .swiper {
            box-sizing: border-box;
            overflow: hidden;
            width: 100%;
            height: 500px;

            @include mobile {
                height: auto;
            }

            lfr-drop-zone {
                &,
                > div,
                > div > div,
                > div > div > div {
                    display: block;
                    width: 100%;

                    &,
                    * {
                        height: 100%;

                    }
                }
            }

            #page-editor {
                .page-editor__root {
                    display: grid;
                    position: relative;


                    > .page-editor__topper {
                        grid-column: 1;
                        grid-row: 1;

                        div {
                            .image {
                                picture,
                                img {
                                    aspect-ratio: 16/9;
                                    object-fit: cover;
                                    object-position: top left;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                lfr-drop-zone {
                    position: absolute;
                    display: block;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            width: 4rem;
            height: 100%;
        }

        .swiper-button-next {
            right: 0;
        }

        .swiper-button-prev {
            left: 0;
            transform: rotate(180deg);
        }
    }
}
