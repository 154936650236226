#wrapper {
    .search-news {
        .teaser-image {
            width: 100%;
            height: auto;
            min-height: 300px;

            .img {
                display: block;
                position: relative;
                width: 100%;
                height: 0;
                padding-bottom: 40%;
                background-size: 100%;
            }
        }

        .icon {
            display: none;
        }

        .date {
            color: $text;
        }

        .text-container {
            .teaser-title {
                font-size: 3rem;
                color: $text;
            }

            .text {
                margin-top: 2rem;
                font-size: 2rem;
                color: $text;
            }
        }

        .card-categories {
            margin-top: 3rem;
        }

    }

    .news-list {
        margin-right: auto;
        margin-left: auto;
        max-width: 1034px;

        &-header {
            color: $white;
            font-size: 36px;
            font-weight: $weight-bold;
            letter-spacing: 0;
            line-height: 37.8px;
            text-align: center;
            margin-bottom: 60px;
        }

        &-categories {
            margin-bottom: 60px;
            display: flex;
            justify-content: flex-start;
            width: fit-content;
            flex-wrap: wrap;

            @include mobile {
                width: auto;
                align-items: center;
                justify-content: center;
            }

            button {
                width: auto;
                height: 56px;
                line-height: 22.4px;
                font-family: $family-sans-serif;
                margin-top: 10px !important;

                @include mobile {
                    height: 29px;
                    margin-right: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    box-shadow: none;
                    padding-right: 11px;
                    padding-left: 11px;

                    span {
                        letter-spacing: 0;
                        line-height: 22px;
                        color: $white;
                        font-size: 16px;
                        font-weight: $weight-semibold;
                    }
                }

                &:not(:last-child) {
                    margin-right: 10px !important;
                }
            }
        }

        &-cards {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 16px;
            grid-column-gap: 16px;
            column-gap: 16px;
            max-width: 1034px;

            @include tablet-only {
                grid-template-columns: repeat(2, 1fr);
            }

            @include mobile {
                grid-template-columns: 1fr;
            }

            .one-card {
                margin: 0 auto;
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                transition: all 0.14s ease-in-out;
                overflow: hidden;
                border-style: none;
                border-radius: 10px;
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
                background-color: $white;
                position: relative;

                @include darkMode {
                    background-color: $bg-dark-cards !important;
                    color: $white;
                }


                &,
                a {
                    color: $bg-light-primary !important;

                    @include darkMode {
                        color: $white !important;
                    }
                }

                @include mobile {
                    width: 100%;
                    max-width: 336px;
                }

                .journal-content-article {
                    height: 100%;
                    width: 100%;
                }

                &:hover {
                    color: $accent;
                }

                .teaser-image {
                    flex: 0 0 auto;
                    height: 200px;
                    position: relative;

                    .img {
                        background-position: center center;
                        background-size: cover;
                        height: 100%;
                    }
                }

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 180px;
                    right: 30px;
                    height: 40px;
                    width: 40px;
                    border-radius: 20px;
                    background-color: $info-color;
                    color: $white;

                    svg {
                        margin-left: 3px;
                    }
                }

                .content {
                    padding: 35px 30px 30px 35px !important;
                }

                .date {
                    font-size: 16px;
                    font-weight: $weight-bold;
                    letter-spacing: 0;
                    line-height: 22px;
                    padding-bottom: 16px;

                    p {
                        margin: 0;
                    }
                }

                .text-container {
                    height: 166px;
                    margin-bottom: 10px;
                }

                .teaser-title {
                    max-height: 72px;
                    margin-bottom: 10px;
                    font-size: 20px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 22px;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                .text {
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 22px;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;

                    p {
                        overflow: hidden;
                    }
                }

                .card-categories {
                    display: flex;
                    height: 26px;

                    .button.card-category {
                        height: 29px;
                        border-radius: 15px;
                        margin-right: 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        box-shadow: none;
                        padding-right: 11px;
                        padding-left: 11px;
                        font-family: $family-sans-serif;

                        .card-category-text {
                            letter-spacing: 0;
                            line-height: 22px;
                            color: $white;
                            font-size: 16px;
                            font-weight: $weight-semibold;
                        }
                    }
                }

            }

            .additional-card,
            .hidden-card {
                display: none;
            }
        }

        .load-more {
            margin-top: 80px;
            text-align: center;

            button {
                height: 56px;
                width: 200px;
                font-family: $family-sans-serif;


                span {
                    color: $white;
                    font-size: 20px;
                    letter-spacing: 0;
                    line-height: 21px;
                    text-align: center;
                }
            }
        }
    }
}
