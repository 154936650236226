.error-404-bg-wrapper {
    max-width: 75em;
    min-height: 50em;
    padding: 1em;
    margin: auto;
    border-radius: 5px;
    text-align: center;
}

.is-lightcolor {
    background-color: $bg-light-primary;
}

.is-darkcolor {
    background-color: $bg-dark-primary;
}

.error-404-bg-img {

    max-width: 100%;
}

.error-404-bg-main-text {


    &-content {

    }
}
