#wrapper {
    .akkordeon {
        color: $white;

        &.is-primary {
            background-color: $bg-light-primary;
            color: $white;

            @include darkMode {
                background-color: $bg-dark-primary;
            }
        }

        &.is-secondary {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }
        }

        &-inner {
            margin: 36px 0;
            color: $text-primary-color;

            .material-icons,
            .akkordeon-icon {
                color: $accent;
                font-size: 3rem !important; // stylelint-disable-line
                width: 30px;
                text-align: center;
            }

            .collapsible {
                overflow: hidden;
                border: none;
                border-radius: 10px;

                li {
                    &.active {
                        .collapsible-header {
                            .material-icons {
                                &.closed {
                                    display: none !important; // stylelint-disable-line
                                }

                                &.opened {
                                    display: inline-block !important; // stylelint-disable-line
                                }
                            }
                        }
                    }

                    .collapsible-header {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        padding: 10px 20px;
                        font-size: 20px;
                        color: $primary-color;

                        @include mobile {
                            padding: 20px;
                        }

                        @include darkMode {
                            background-color: $bg-dark-cards;
                            color: $white;
                            border-color: $bg-dark-secondary;
                        }

                        span {
                            margin: 0;
                        }

                        .material-icons {
                            &.opened {
                                display: none !important; // stylelint-disable-line
                            }
                        }

                        svg {
                            width: 20px !important; // stylelint-disable-line
                            max-height: 20px !important; // stylelint-disable-line
                        }
                    }

                    .collapsible-body {
                        padding: 50px;
                        font-size: 20px;
                        color: $primary-color;
                        background-color: #eeefef;

                        @include mobile {
                            padding: 30px;
                        }

                        @include darkMode {
                            background-color: $bg-dark-secondary;
                            color: $white;
                            border-color: $bg-dark-secondary;
                        }
                    }

                    a {
                        &,
                        &:visited,
                        &:hover,
                        &:focus,
                        &:active {
                            color: $accent;
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        .container {
            padding: 16px 10%;
        }
    }
}

