#wrapper {

    .content-separator {
        width: 100%;
        max-width: 1200px;
        border: 0 none;
        border-top: 1px solid $white;
        background: transparent;
        margin: 0 auto;
    }

    .filter-card-grid {

        &.is-primary {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }
        }

        &.is-secondary {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }
        }

        .container {
            padding: 16px 10%;

            .header {
                margin-bottom: 40px;
                text-align: left;
                color: $white;

                .second-title {
                    margin-bottom: 10px;
                }

                &-actions {
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 22.4px;
                    justify-content: flex-start;

                    @include mobile {
                        justify-content: center;
                    }

                    .icon {
                        min-width: 24px;
                        width: 24px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 16px;
                        border-radius: 20px;
                        background-color: $info-color;

                        svg {
                            margin-left: 2px;
                            color: $white;
                        }
                    }
                }

                .filter-list {
                    margin-top: 40px;
                    display: flex;
                    justify-content: flex-start;
                    width: fit-content;
                    flex-wrap: wrap;

                    @include mobile {
                        width: auto;
                        align-items: center;
                        justify-content: center;
                    }

                    button {
                        width: auto;
                        height: 56px;
                        line-height: 22.4px;
                        font-family: $family-sans-serif;
                        margin-top: 10px !important;

                        @include mobile {
                            height: 29px;
                            margin-right: 10px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            box-shadow: none;
                            padding-right: 11px;
                            padding-left: 11px;

                            span {
                                letter-spacing: 0;
                                line-height: 22px;
                                font-size: 16px;
                                font-weight: $weight-semibold;
                            }
                        }

                        &:not(:last-child) {
                            margin-right: 10px !important;
                        }
                    }
                }
            }

            .grid-elements {
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                grid-gap: 16px;
                grid-column-gap: 16px;
                column-gap: 16px;
                z-index: 5;
                position: relative;

                @include mobile {
                    grid-template-columns: repeat(1, 1fr);
                }

                .hidden-card {
                    display: none;
                }

                .element {
                    padding: 47.5px;
                    height: 317px;
                    border-radius: 10px;
                    background-color: $white;
                    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
                    color: $primary-color;
                    position: relative;

                    @include darkMode {
                        background-color: $bg-dark-cards !important;
                        color: $white;
                    }

                    @include tablet-only {
                        padding: 30px 20px 30px 30px;
                    }

                    &-icons {
                        display: grid;
                        grid-template-columns: 1fr 76px;
                        grid-gap: 16px;
                        grid-column-gap: 16px;
                        column-gap: 16px;
                        padding-bottom: 25px;

                        .element-icon {

                            svg {
                                color: $primary-color;
                                fill: $primary-color;
                                width: 60px;
                                height: 60px;

                                @include darkMode {
                                    color: $white;
                                    fill: $white;
                                }
                            }
                        }

                        .right-icons {
                            height: 50px;

                            .element-question-icon {
                                width: 28px;
                                height: 28px;
                                position: absolute;
                                top: 55px;
                                right: 47.5px;
                                margin-right: 0;

                                @include tablet-only {
                                    margin-right: 10px;
                                }

                                svg {
                                    color: $info-color;
                                }

                                &:hover {
                                    #help-text {
                                        display: flex;
                                        flex-direction: column;
                                    }
                                }
                            }
                        }
                    }

                    .title {
                        color: inherit;
                    }

                    .element-text {
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        color: $primary-color;
                        padding-top: 10px;
                        line-height: 22px;

                        @include darkMode {
                            color: $white;
                        }
                    }

                    .element-title {
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        color: $primary-color;

                        @include darkMode {
                            color: $white;
                        }
                    }

                    .help-text {
                        display: none;
                        position: absolute;
                        z-index: 500;
                        width: 314px;
                        border-radius: 10px;
                        background-color: $card-bg-color-grey;
                        box-shadow: 0 5px 10px 0 rgba(14, 31, 53, 0.08), 0 10px 20px 0 rgba(14, 31, 53, 0.16), 0 24px 32px -1px rgba(14, 31, 53, 0.16), 0 32px 64px -2px rgba(14, 31, 53, 0.08);
                        padding: 30px;
                        color: $primary-color;

                        @include darkMode {
                            background-color: $bg-dark-secondary !important;
                            color: $white;
                        }

                        &-title {
                            line-height: 22px;
                            font-size: 20px;
                            font-weight: 700;
                            margin-bottom: 10px;
                        }
                    }
                }

            }

            .small-grid {
                .element {
                    &-icons {
                        .element-icon {
                            svg {
                                width: 50px;
                                height: 50px;
                            }
                        }
                    }
                }

                .help-text {
                    top: 35px;
                    left: -80px;

                    @include mobile {
                        left: -200px;
                    }

                    @include tablet-only {
                        left: -290px;
                    }
                }
            }

            .big-grid {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));

                @include mobile {
                    grid-template-columns: repeat(1, 1fr);
                }

                .element {

                    &-icons {
                        grid-template-columns: 1fr 96px;

                        .element-icon {

                            svg {
                                width: 60px;
                                height: 60px;
                            }
                        }

                        .right-icons {
                            .element-arrow-icon {
                                width: 40px;
                                height: 40px;


                                svg {
                                    width: 15px;
                                    height: 15px;
                                }
                            }

                            .element-question-icon {
                                width: 40px;
                                height: 40px;


                            }
                        }
                    }

                    .help-text {
                        top: 35px;
                        left: -150px;

                        @include mobile {
                            left: -200px;
                        }

                        @include tablet-only {
                            left: -290px;
                        }
                    }
                }
            }
        }
    }
}
