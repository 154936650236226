.card-pages {
    &-container {
        display: flex;
        align-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: row;

        @include mobile {
            display: block;
        }

        @include tablet-only {
            display: block;
        }


    }

    .col {
        flex-basis: 0;
        flex-grow: 1;
        width: 33.33333%;
        min-width: 33.33333%;
        max-width: 33.33333%;

        @include mobile {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            display: block;
            margin-right: 0;
        }

        @include tablet-only {
            width: 50%;
            min-width: 50%;
            max-width: 50%;
            display: block;
        }
    }

    .card {
        overflow: hidden !important;
        border: none !important;
        float: none;
    }

    .card-pages-bg {
        background-color: $bg-light-secondary;
        @include darkMode {
            background-color: $bg-dark-secondary;
        }
    }

    .headerdiv {
        @include darkMode {
            background-color: $bg-dark-cards;
        }

        .flow-text {
            font-size: $size-2 !important;
            text-align: center;
            color: $secondary-color;
        }
    }
}
