#wrapper {
    .downloads-broschuere {

        &.primarygrey {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }
        }

        &.secondarygrey {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }
        }

        .container {
            padding: 16px 10%;

            &::before {
                content: none;
            }

            &::after {
                content: none;
            }
        }

        &-container {
            display: flex;
            gap: 2rem;

            @include mobile {
                flex-wrap: wrap;
                gap: 30px;
            }

            &.is-reversed {
                flex-direction: row-reverse;
            }

            .downloads-broschuere-image {
                width: 100%;
                max-width: 41.6666666667%;

                img {
                    box-shadow: 0 6px 10px 0 rgba(14, 31, 53, 0.12), 0 12px 18px 0 rgba(14, 31, 53, 0.2), 0 20px 40px -1px rgba(14, 31, 53, 0.12);
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                    max-width: 275px;

                    @include mobile {
                        max-width: 100%;
                    }
                }

                @include mobile {
                    max-width: 100%;
                }
            }

            .downloads-broschuere-body-container {
                width: 100%;
                max-width: 58.3333333333%;

                @include mobile {
                    max-width: 100%;
                }

                .downloads-broschuere-body {
                    color: $white;

                    .downloads-broschuere-button {
                        background-color: $info-color;
                        border: 1px solid $info-color;
                        border-radius: 10px;
                        padding: 10px;
                        margin-top: 20px;
                        margin-bottom: 50px;

                        @include mobile {
                            margin-bottom: 30px;
                        }

                        @include tablet-only {
                            margin-bottom: 40px;
                        }

                        .icon {
                            margin-right: 10px;
                            display: inline-block;
                            vertical-align: middle;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                min-width: auto !important;
                                min-height: auto !important;
                            }
                        }

                        .button-text {
                            display: inline-block;
                            vertical-align: middle;
                            font-family: $family-sans-serif;
                        }
                    }

                    .weitereURL {
                        display: block;
                        font-weight: $weight-bold;
                        line-height: 110%;
                        margin-bottom: 10px;

                        .icon {
                            align-items: center;
                            justify-content: center;
                            height: 20px;
                            width: 20px;
                            border-radius: 20px;
                            background-color: $info-color;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}
