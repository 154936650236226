$widescreen-enabled: true !default;

@mixin clearfix {
    &:after {
        content: " ";
        display: table;
        clear: both;
    }
}

@mixin placeholder {
    $placeholders: ":-moz" ":-webkit-input" "-moz" "-ms-input";
    @each $placeholder in $placeholders {
        &:#{$placeholder}-placeholder {
            @content;
        }
    }
}

@mixin prefersReducedMotion {
    @media screen and (prefers-reduced-motion: reduce) {
        @content;
    }
}

// Responsiveness
@mixin from($device) {
    @media screen and (min-width: $device) {
        @content;
    }
}

@mixin until($device) {
    @media screen and (max-width: $device - 0.0625em) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: $tablet - 0.0625em) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: $tablet), print {
        @content;
    }
}

@mixin tablet-only-landscape {
    @media only screen and (min-device-width: $tablet) and (orientation: landscape) and (max-device-width: $tablet-new),
    screen and (min-device-width: $tablet) and (orientation: landscape) and (max-device-width: $tablet-pro-landscape) and (min-resolution: 2dppx) {
        @content;
    }
}


@mixin tablet-only {
    @media screen and (min-width: $tablet) and (max-width: $desktop - 0.0625em),
    screen and (min-width: $tablet) and (max-width: $tablet-new) and (min-resolution: 2dppx) {
        @content;
    }
}

@mixin touch {
    @media screen and (max-width: $desktop - 0.0625em) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop) {
        @content;
    }
}

@mixin smaller-screen {
    @media all and (max-height: $desktop-small-screen) {
        @content;
    }
}

@mixin desktop-only {
    @if $widescreen-enabled {
        @media screen and (min-width: $desktop) and (max-width: $widescreen - 0.0625em) {
            @content;
        }
    }
}

@mixin until-widescreen {
    @if $widescreen-enabled {
        @media screen and (max-width: $widescreen - 0.0625em) {
            @content;
        }
    }
}

@mixin widescreen {
    @if $widescreen-enabled {
        @media screen and (min-width: $widescreen) {
            @content;
        }
    }
}

@mixin widescreen-only {
    @if $widescreen-enabled and $fullhd-enabled {
        @media screen and (min-width: $widescreen) and (max-width: $fullhd - 0.0625em) {
            @content;
        }
    }
}

@mixin until-fullhd {
    @if $fullhd-enabled {
        @media screen and (max-width: $fullhd - 0.0625em) {
            @content;
        }
    }
}

@mixin fullhd {
    @if $fullhd-enabled {
        @media screen and (min-width: $fullhd) {
            @content;
        }
    }
}

@mixin retina {
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        @content;
    }
}

@mixin prefersDarkMode {
    body:not(.force-lightmode) & {
        @media (prefers-color-scheme: dark) {
            @content;
        }
    }
}

@mixin darkMode {
    body.force-darkmode &,
    [data-force-darkmode="true"] & {
        @content;
    }

    @include prefersDarkMode {
        @content;
    }
}

// Placeholders

@mixin unselectable {
    -webkit-touch-callout: none; // stylelint-disable-line property-no-vendor-prefix
    -webkit-user-select: none; // stylelint-disable-line property-no-vendor-prefix
    -moz-user-select: none; // stylelint-disable-line property-no-vendor-prefix
    -ms-user-select: none; // stylelint-disable-line property-no-vendor-prefix
    user-select: none;
}

%unselectable {
    @include unselectable;
}

@mixin arrow($color: transparent) {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    width: 0.625em;
    height: 0.625em;
    margin-top: -0.4375em;
    transform: rotate(-45deg);
    transform-origin: center;
    border: 3px solid $color;
    border-top: 0;
    border-right: 0;
    border-radius: 2px;
    pointer-events: none;
}

%arrow {
    @include arrow;
}

@mixin loader {
    content: "";
    display: block;
    position: relative;
    width: 1em;
    height: 1em;
    animation: spinAround 500ms infinite linear;
    border: 2px solid map-get($theme-background, "light");
    border-radius: $radius-rounded;
    border-top-color: transparent;
    border-right-color: transparent;
}

%loader {
    @include loader;
}


@mixin screen-sm {
    @media screen and (max-width: 600px) {
        @content;
    }
}

@mixin screen-md {
    @media screen and (min-width: 601px) and (max-width: 992px) {
        @content;
    }
}

@mixin screen-lg {
    @media screen and (min-width: 993px) and (max-width: 1199px) {
        @content;
    }
}

@mixin screen-xl {
    @media screen and (min-width: 1200px) and (max-width: 1919px) {
        @content;
    }
}

@mixin screen-xxl {
    @media screen and (min-width: 1920px) {
        @content;
    }
}

