#wrapper {
    .standorts-container {
        background-color: $bg-light-primary;

        @include darkMode {
            background-color: $bg-dark-primary;
        }

        button {
            background: none;
            border: none;
            -webkit-appearance: none;
            padding: 0;
            margin: 0;
            box-shadow: none;
        }
    }

    .container.standort-container {

        padding-top: 120px;
        padding-bottom: 120px;
        @include mobile {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        @include tablet-only {
            padding-top: 25px;
            padding-bottom: 25px;
        }
    }

    .standortsuche-gross {
        text-align: center;
        line-height: 1.5em;
        font-size: 3em;
        font-weight: 700;
        @include mobile {
            font-size: 2em;
        }
    }

    .standortsuche-klein {
        text-align: center;
        font-size: 1.7em;
        font-weight: 400;
        line-height: 1.5em;
        @include mobile {
            font-size: 1.2em;
        }
    }

    .vue-map {
        height: 800px;
        position: relative;
        @include mobile {
            display: none !important;
        }

        .cluster > div {
            background-color: $secondary-color;
            border-radius: 100%;
            width: 26px !important;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .standortsuche {

        * {
            box-sizing: border-box !important;
        }

        .input-spinner:before {
            content: '';
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            margin-top: -10px;
            margin-left: -10px;
            border-radius: 50%;
            border-top: 2px solid $secondary-color;
            border-right: 2px solid transparent;
            animation: spinner .8s linear infinite;
        }

        #container {
            position: relative;
        }

        &.has-map {
            @include tablet {
                padding: 0 2rem;
            }

            .quick-search-container {
                position: absolute;
                z-index: 50;
                width: 336px;
                top: 80px;
                left: 10px;
            }

            .results-container {
                max-width: 1440px;
                max-height: 380px;

                @include mobile {
                    max-height: 100%;
                }
            }
        }

        &.is-mobile {
            .quick-search-container {
                padding: 0;
            }

            #map {
                display: none !important;
            }
        }

        .quick-search-container {

            @include mobile {
                position: static !important;
                width: 100% !important;
                padding-right: 0 !important;
                padding-left: 0 !important;
                height: auto !important;
                margin-bottom: 30px;
            }
            @include tablet {
                padding-right: 0 !important;
                padding-left: 0 !important;
            }

            .search-container {
                border-radius: 10px;
                background-color: #FFFFFF;
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
                //height: 140px;
                margin-bottom: 16px;
                padding: 24px;

                @include mobile {
                    height: auto;
                    padding-bottom: 0;
                }

                @keyframes spinner {
                    to {
                        transform: rotate(360deg);
                    }
                }


                .search-input-field {
                    margin-bottom: 10px;
                    position: relative;

                    @include mobile {
                        margin-bottom: 0;
                    }

                    &[disabled="disabled"] {
                        cursor: wait;
                    }


                    input[type="search"]::-webkit-search-cancel-button,
                    input[type="search"]::-webkit-search-decoration {
                        -webkit-appearance: none;
                    }

                    input[type="search"],
                    input[type="search"]::-webkit-search-cancel-button,
                    input[type="search"]::-webkit-search-decoration {
                        -webkit-appearance: none;
                    }

                    input {
                        -webkit-appearance: none;
                        margin-bottom: 0;
                        height: 64px;
                        width: 100%;
                        border-radius: 6px;
                        background-color: #EEEFEF;
                        border: none;
                        padding: 24px 70px 0 16px;
                        color: #566367;
                        font-family: Boston;
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 22px;

                        white-space: nowrap;
                        word-break: keep-all;
                        text-overflow: ellipsis;


                        &::placeholder {
                            font-size: 14px;
                        }

                        @include mobile {
                            width: 100%;
                        }

                        &.is-danger, &.is-danger:focus {
                            box-shadow: 0 1px 0 0 $error-color;

                            + label.merkur-label {
                                color: $error-color;
                            }
                        }
                    }

                    label {
                        position: absolute;
                        color: #566367;
                        font-family: Boston;
                        font-size: 12px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 28px;
                        top: 8px;
                        left: 16px;
                    }


                    .input-icons {
                        position: absolute;
                        right: 5px;
                        z-index: 10;
                        display: grid;
                        grid-template-columns: 24px 13px 24px;
                        top: calc(50% - 12px);
                        margin: auto;
                        align-items: center;
                        height: 24px;

                        button {
                            display: block;
                            width: 100%;
                            height: 100%;
                            text-align: center;
                            margin: 0 auto;
                            overflow: hidden;
                            padding: 0;
                            border: none;

                            svg {
                                height: 100%;
                            }
                        }

                        .icons-separator {
                            height: 24px;
                            text-align: center;
                            font-size: 24px;
                            display: block;
                            background: $bg-light-secondary;
                            width: 2px;
                            text-indent: -999em;
                            margin: 0 auto;
                        }

                        .search-icon {
                            cursor: pointer;
                            color: $bg-light-secondary;
                            transition: opacity 0.12s ease-in-out;
                            margin: 0 auto;

                            &[disabled="disabled"] {
                                cursor: wait;
                            }

                            &:hover {
                                color: $secondary-color;
                            }
                        }

                        .clear-icon-wrapper {
                            height: 24px;
                            cursor: pointer;
                            position: relative;

                            &[disabled="disabled"] {
                                cursor: wait;
                            }

                            .clear-icon {
                                color: $bg-light-secondary;
                                transition: opacity 0.12s ease-in-out;

                                &:hover {
                                    color: $secondary-color;
                                }
                            }

                            .clear-icon {
                                display: block;
                            }

                        }

                        &.is-loading .clear-icon-wrapper {
                            .clear-icon {
                                display: none;
                            }

                            .input-spinner {
                                display: block;
                            }
                        }
                    }
                }

                .search-nearby {
                    color: $secondary-color;
                    font-family: Boston;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 22.4px;
                    display: block;
                    width: 100%;
                    margin-bottom: 0;
                    cursor: pointer;
                    -webkit-appearance: none;
                    border: none;
                    background: none;
                    text-align: unset;

                    &[disabled="disabled"] {
                        cursor: wait;
                    }

                    @include mobile {
                        padding: 14px 10px 14px 0;
                    }
                }

                .consultants-search-nearby {
                    color: $secondary-color;
                    font-family: Boston;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 22.4px;
                    cursor: pointer;

                    @include mobile {
                        padding: 16px;
                    }
                }
            }

            .results-container {
                overflow-x: hidden;
                overflow-y: auto;
                scrollbar-color: $secondary-color $white;
                background-color: $white;
                display: flex;
                flex-direction: column;
                color: $primary-color;
                border-radius: 10px;
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

                scroll-behavior: smooth;

                .empty-info {
                    text-align: center;
                    padding: 15px;
                }

                .result {
                    cursor: pointer;
                    background: $white;
                    transition: opacity 0.12s ease-in-out;
                    border-bottom: 1px solid lighten($grey-light, 20%);

                    &.active {
                        background: lighten($grey-light, 20%);
                    }
                }
            }

            .result {
                cursor: default;

                .text-container .title {
                    font-size: 18px;
                }
            }
        }

        .gm-style .gm-style-iw-t::after {
            content: none;
        }

        .gm-style-iw-c {
            //top: 130px;
            //min-height: 96px;

            @include mobile {
                //top: 150px;
            }
            max-width: 260px !important;
            padding: 0;

            .gm-style-iw-d {
                padding: 0;
                overflow: hidden !important;

                + button {
                    display: none !important;
                }
            }
        }

        .result {
            height: auto;
            padding: 8px 10px 6px 24px;
            display: grid;
            grid-template-columns: 1fr 24px;
            color: $primary-color;
            align-items: center;


            @include mobile {
                padding: 8px 10px 8px 14px;
            }

            .text-container {
                overflow: hidden;

                .title {
                    font-family: Boston;
                    color: $primary-color;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 1.208em;

                }

                .address {
                    color: $primary-color;
                    font-family: Boston;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 1.3em;
                }

            }

            .informations {
                display: none;
                font-family: Boston;

                ul {
                    margin-bottom: 0;
                }

                div:first-child {
                    margin-top: 5px;
                }

                div:not(:last-child) {
                    margin-bottom: 5px;
                }
            }

            &.informations-visible {
                .informations {
                    display: block;
                }

                .show-info .icon-container .icon {
                    transform: rotate(270deg);
                }
            }

            .show-info {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: opacity 0.12s ease-in-out;

                &:hover {
                    color: $secondary-color;
                }

                .text {
                    display: none;
                }

                .icon-container {

                    .icon {
                        align-items: center;
                        transform: rotate(90deg);
                        height: 12px;
                    }
                }
            }

            .buttons {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-content: space-around;
                align-items: center;
                height: 100%;
                padding-bottom: 3px;

                .closeInfoWindow {
                    cursor: pointer;
                    transition: opacity 0.12s ease-in-out;

                    &:hover {
                        color: $error-color;
                    }
                }

                .show-info {
                    height: auto;
                }
            }
        }
    }
}
