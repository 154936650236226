#wrapper {

    .liste {
        width: 100%;
        height: 100%;

        &.primarygrey {
            background-color: $bg-light-primary;
        }

        &.secondarygrey {
            background-color: $bg-light-secondary;
        }
    }

    .liste-wrapper {
        max-width: 1200px;
        margin: auto;
        background-color: $bg-light-primary;

        @include darkMode {
            background-color: #2c373b
        }


    }

    .liste-heading {
        padding: 0 6rem;

        @include mobile {
            padding: 0 4rem;
        }
    }

    .collapsible-header {
        .aufzaehlung-content {
            > .cust-flex-container {
                justify-content: space-between;
            }

        }
    }

    .listenelement-image-links {
        max-width: 200px;
        max-height: 200px;
        width: 100%;
        border-radius: 15px;

        @include mobile {
            max-width: 100%;
        }
    }

    .liste-small-flex {
        width: 70%;

        @include mobile {
            width: 100%;
        }
    }

    .liste-font-500 {
        font-weight: 500;
    }

    .title-is {

        &-h1 {
            font-size: 2em;
            margin-left: 2em;
            font-weight: 1000;
            height: 50px;
            line-height: 2em;
        }

        &-h2 {
            font-size: 1.5em;
            margin-left: 3em;
            font-weight: 1000;
            height: 50px;
            line-height: 2em;
        }
    }

    .content-header-image-liste {
        max-height: 400px;
        border-radius: 15px;


        body.has-edit-mode-menu & {
            max-width: 50%;
            width: 100%;
        }

        @include mobile {
            max-height: 100%;
        }
    }

    .cust-flex-container {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        color: white;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        padding: 25px 20px;

        @include mobile {
            padding: 0;
            flex-direction: column;
            justify-content: center;
        }
    }

    .mw-100 {
        max-width: 100%;
    }

    .col-body {
        position: relative;

        &-text {
            max-width: 50%;
        }

        &-img {
            padding: 20px;
        }
    }

    .img-is {
        -webkit-box-ordinal-group: 1;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 1;
        order: 1;

        &-right {
            -webkit-box-ordinal-group: 2;
            -moz-box-ordinal-group: 2;
            -ms-flex-order: 2;
            order: 2;
        }
    }

    .text-is {
        -webkit-box-ordinal-group: 1;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 1;
        order: 1;

        &-right {
            -webkit-box-ordinal-group: 2;
            -moz-box-ordinal-group: 2;
            -ms-flex-order: 2;
            order: 2;
        }
    }

    .link-colored {
        color: $secondary-color;
    }

    .cust-header-style {
        width: 100%;
        justify-content: center;
        background-color: $bg-light-primary;
        border: 0;
        @include darkMode {
            background-color: $bg-dark-primary
        }
    }

    .header-height {
        background-color: $bg-light-primary;
        border-bottom: 1px solid white;
        @include darkMode {
            background-color: $bg-dark-primary;
            border-bottom: 1px solid white !important;
        }
    }

    .list-body-color {
        background-color: #647277 !important;
        border: 0;
    }

    .bg-is-initial {
        background-color: initial;
    }
}
