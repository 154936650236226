.g-edit-button {
    display: block;
    position: absolute;
    z-index: 9999 !important;
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    transform: translateY(-8px);
    transition-duration: 0.25s;
    opacity: 0;
    will-change: transform, opacity;

    &:hover {
        opacity: 1;
    }

    .visible-interaction {
        display: block;
        width: 40px;
        height: 40px;

        .taglib-icon {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 40px;
            height: 40px;
            padding: 4px;
            transition-duration: 0.25s;
            border: 1px solid transparent;
            border-radius: 100%;

            .info-box & {
                border-color: $primary-color;
                // background-color: $info-box-header-bg-color;
                animation: pulse-shadow--info-box 3s infinite;

                &:hover {
                    animation: pulse-shadow--info-box 2s infinite;
                }
            }

            .card {
                border-color: rgba(#ffffff, 0.54);
                background-color: $primary-color;
                animation: pulse-shadow--card 3s infinite;

                &:hover {
                    animation: pulse-shadow--card 2s infinite;
                }
            }

            > span {
                display: block;
                width: 32px;
                height: 32px;
            }

            .lexicon-icon {
                position: relative;
                top: -1px;
                left: -1px;

                .lexicon-icon-outline {
                    .info-box & {
                        fill: $primary-color;
                    }

                    .card & {
                        fill: #ffffff;
                    }
                }
            }

            &:hover {
                .info-box & {
                    border-color: $primary-color;
                }

                .card & {
                    border-color: #ffffff;
                }
            }
        }
    }
}
