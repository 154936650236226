#wrapper {
    .cust-akkordeon {
        &-display {
            display: grid;
        }

        &-zindex {
            z-index: 2;
        }
    }

    .bg-none {
        background: none;
    }

    .merkur-happy-holder-bg {
        z-index: -1;
    }

    .akkordeon {
        .akkordeon-inner {
            margin: 0;

            .collapsible {
                margin: 0;
            }
        }
    }

    .akkordeon .container {
        .akkordeon-inner {
            .collapsible {
                li {
                    a {
                        &,
                        &:visited,
                        &:hover,
                        &:focus,
                        &:active {
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}
