@keyframes spinAround {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(100px);
    }
}

@keyframes load {
    from {
        left: -150px;
    }

    to {
        left: 100%;
    }
}
