.link-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;

    .video-icon {
        position: absolute;
        width: 100px;
        height: 100px;
        border: 2px solid $white;
        border-radius: 50%;

        &:hover {
            cursor: pointer;
        }

        .icon-play {
            color: $white;
            margin-top: 25px;
            margin-left: 25px;
        }
    }
}