@mixin card-size-mixin {
    width: calc(16.6666666667% - 10px);

    @include mobile {
        width: calc(50% - 12px);
    }

    @include tablet-only {
        width: calc(50% - 12px);
    }
}

#wrapper {
    .link-liste {
        &.is-fragment {
            &.has-edit-mode {
                [class*='lfr-layout-structure-item-topper'] {
                    width: 100%;
                }

                lfr-drop-zone {
                    width: 100%;
                }

                .page-editor__root {
                    width: 100%;
                    padding-top: 60px;
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 10px;

                    .page-editor__topper {
                        @include card-size-mixin;
                    }

                    .page-editor__fragment-content {
                        width: 100% !important;
                    }

                    .card-container {
                        width: 100% !important;
                    }
                }
            }

            .grid-container {
                > div {
                    width: 100%;
                    padding-top: 60px;
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 10px;

                    > div {
                        @include card-size-mixin;
                    }
                }

                .card-container {
                    width: 100% !important;
                }
            }
        }

        &.primarygrey {
            background-color: $bg-light-primary;

            .card {
                &-main-title,
                &-text,
                svg {
                    color: $bg-light-primary;
                }
            }

            @include darkMode {
                background-color: $bg-dark-primary;

                .card {
                    &-main-title,
                    &-text,
                    svg {
                        color: $white;
                    }
                }
            }
        }

        &.secondarygrey {
            background-color: $bg-light-secondary;

            .card {
                &-main-title,
                &-text,
                svg {
                    color: $bg-light-secondary;
                }
            }

            @include darkMode {
                background-color: $bg-dark-secondary;

                .card {
                    &-main-title,
                    &-text,
                    svg {
                        color: $bg-dark-secondary;
                    }
                }
            }
        }

        .container {
            z-index: 50;
            position: relative;
            padding: 16px 10%;

            .ueberschrift {
                text-align: center;
            }

            .zweite-ueberschrift {
                text-align: center;
            }

            .absatz-text {
                text-align: center;
            }

            .grid-container {
                padding-top: 60px;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 10px;

                .card-container {
                    .card {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        margin-right: auto;
                        margin-left: auto;

                        @include darkMode {
                            background-color: $bg-dark-cards;
                        }

                        &-image {
                            text-align: center;

                            @include darkMode {
                                color: $white;
                            }
                        }

                        &-text {
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            max-height: 100%;
                            overflow: hidden;
                            line-break: normal !important;
                            text-align: center;
                            text-overflow: unset !important;
                            word-break: break-word;
                            white-space: normal !important;
                            -webkit-hyphens: auto;
                            -ms-hyphens: auto;
                            hyphens: auto;

                            @include darkMode {
                                color: $white;
                            }
                        }

                        &-content {
                            text-align: center;
                            padding-top: 10px;

                            .card-main-title {
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                line-break: normal !important;
                                text-align: center;
                                text-overflow: unset !important;
                                word-break: break-word;
                                white-space: normal !important;
                                -webkit-hyphens: auto;
                                -ms-hyphens: auto;
                                hyphens: auto;
                                line-height: 24px;

                                @include darkMode {
                                    color: $white;
                                }

                                &.is-2 {
                                    margin-top: -0.8rem;
                                }

                                &.is-2 ~ .card-text {
                                    -webkit-line-clamp: 1 !important;
                                }

                                &.is-3 ~ .card-text {
                                    -webkit-line-clamp: 1 !important;
                                }
                            }
                        }
                    }
                }

                &.grid-small {
                    .card-container {
                        @include card-size-mixin;

                        .card {
                            width: 100%;
                            height: 100%;
                            margin: 0;

                            .card-image {
                                flex: 0;
                                padding: 2.75rem 0.5rem 2rem 0.5rem;
                                color: $primary-color;

                                @include darkMode {
                                    color: $white;
                                }

                                @include mobile {
                                    height: calc(11vw + 40px);
                                }

                                @include tablet-only {
                                    height: calc(20vw + 40px);
                                }

                                svg {
                                    width: 3.5vw;
                                    height: 3.5vw;

                                    @include tablet-only {
                                        width: 20vw;
                                        height: 20vw;
                                    }

                                    @include mobile {
                                        width: 11vw;
                                        height: 11vw;
                                    }
                                }

                                .image-placeholder {
                                    width: 4vw;
                                    height: 4vw;

                                    @include tablet-only {
                                        width: 20vw;
                                        height: 20vw;
                                    }

                                    @include mobile {
                                        width: 11vw;
                                        height: 11vw;
                                    }
                                }
                            }

                            .card-content {
                                display: flex;
                                flex-direction: column;
                                text-align: center;
                                height: 78px;
                                padding: 0 !important;
                            }
                        }
                    }
                }

                &.grid-normal {

                    .card-container {
                        height: calc(calc(0.25 * 80vw) - 12px);
                        width: calc(25% - 8px);

                        @include mobile {
                            width: 100%;
                            height: 80vw;
                        }

                        @include tablet-only {
                            width: 100%;
                            height: 80vw;
                        }

                        .card {
                            .card-image {
                                padding-top: 60px;
                                height: calc(7vw + 60px);

                                @include darkMode {
                                    color: $white;
                                }

                                @include mobile {
                                    height: calc(40vw + 60px);
                                }

                                @include tablet-only {
                                    height: calc(50vw + 60px);
                                }

                                svg {
                                    width: 7vw;
                                    height: 7vw;

                                    @include mobile {
                                        width: 40vw;
                                        height: 40vw;
                                    }

                                    @include tablet-only {
                                        width: 50vw;
                                        height: 50vw;
                                    }
                                }

                                .image-placeholder {
                                    width: 7vw;
                                    height: 7vw;

                                    @include mobile {
                                        width: 40vw;
                                        height: 40vw;
                                    }

                                    @include tablet-only {
                                        width: 50vw;
                                        height: 50vw;
                                    }
                                }
                            }

                            .card-content {
                                .card-main-title {
                                    height: 100px;
                                    line-height: 24px !important;

                                    @include mobile {
                                        height: 65px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
