#wrapper {

    .steps-slider {
        &.is-primary {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }
        }

        &.is-secondary {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }
        }

        * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            border: none;
        }

        &.has-edit-mode {
            .container {
                .slider-content {
                    overflow-x: scroll;
                    padding-bottom: 2rem;
                }

                .slider-buttons {
                    display: none;
                }
            }
        }

        .container {
            padding: 16px 10% !important;
            @extend %unselectable;

            .header {
                margin-bottom: 100px;
                text-align: center;
            }

            .slider-content {
                display: flex;
                justify-content: flex-start;
                overflow: hidden;
                overflow-y: auto;
                scroll-behavior: smooth;

                .slider-step {
                    flex: calc(20% - 2px) 1 0;
                    width: 0;
                    padding: 0 24px 0 0;
                    margin: 0 1px;
                    @include tablet-only-landscape {
                        flex: calc(25% - 2px) 1 0;
                    }
                    @include tablet-only {
                        flex: calc(33.33% - 2px) 1 0;
                    }
                    @include mobile {
                        flex: calc(50% - 2px) 1 0;
                    }


                    &-icons {
                        display: grid;
                        grid-template-columns: 47px 1fr;
                        grid-gap: 23px;
                        margin-bottom: 22px;
                        overflow: hidden;

                        .slider-step-number {
                            font-size: 22.5px;
                            line-height: 30px;
                            font-weight: bold;
                            border-radius: 50%;
                            width: 50px;
                            height: 50px;
                            padding: 8px;
                            border: 2px solid $white;
                            color: $white;
                            text-align: center;
                            display: flex;
                            align-content: center;
                            justify-content: center;
                            background-color: $bg-light-primary;
                            z-index: 20;
                            box-shadow: 0 0 0 13px $bg-light-primary;
                            margin: 0;

                            &.is-primary {
                                background-color: $bg-light-primary;
                                box-shadow: 0 0 0 13px $bg-light-primary;

                                @include darkMode {
                                    background-color: $bg-dark-primary;
                                    box-shadow: 0 0 0 13px $bg-dark-primary;
                                }
                            }

                            &.is-secondary {
                                background-color: $bg-light-secondary;
                                box-shadow: 0 0 0 13px $bg-light-secondary;

                                @include darkMode {
                                    background-color: $bg-dark-secondary;
                                    box-shadow: 0 0 0 13px $bg-dark-secondary;
                                }
                            }
                        }

                        .slider-step-arrow {
                            display: flex;
                            align-items: center;
                            position: relative;

                            svg {
                                position: absolute;
                                right: 0;
                                z-index: 5;
                            }
                        }
                    }

                    .step-text {
                        padding-top: 10px;
                        font-size: 16px;
                        line-height: 24px;
                        margin-right: 20px;
                        font-weight: bold;

                        @include mobile {
                            margin-right: 10px;
                        }
                    }
                }


            }

            .slider-buttons {
                display: flex;
                margin-top: 20px;
                height: 40px;
                width: 100px;
                cursor: pointer;

                button:disabled,
                button[disabled] {
                    svg {
                        color: $white;
                    }
                }

                .slide-left,
                .slide-right {
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    background-color: $white;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                .slide-left {
                    border-right: 1px solid $bg-grey-dark;
                    -ms-transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
                }

                .slide-right {
                    border-left: 1px solid $bg-grey-dark;
                }

                svg {
                    width: 25px;
                    height: 25px;
                    color: $primary-color;
                }
            }
        }

        .content-separator {
            width: 100%;
            max-width: 1200px;
            border: 0 none;
            border-top: 1px solid $white;
            background: transparent;
            margin: 0 auto;
        }
    }
}
