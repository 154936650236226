#wrapper {

    .content-separator {
        width: 100%;
        max-width: 1200px;
        border: 0 none;
        border-top: 1px solid $white;
        background: transparent;
        margin: 0 auto;
    }

    .card-grid {

        &.is-fragment {
            .grid-elements {
                display: flex !important;
                width: 100%;

                > div {
                    display: grid;
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                    grid-gap: 16px;
                    grid-column-gap: 16px;
                    column-gap: 16px;
                    z-index: 5;
                    position: relative;
                    width: 100%;

                    @include tablet-only {
                        grid-template-columns: repeat(2, minmax(0, 1fr));
                    }

                    @include mobile {
                        grid-template-columns: 1fr;

                    }
                }

                &.big-grid {
                    > div {
                        width: 100%;
                        grid-template-columns: repeat(2, minmax(0, 1fr));

                        @include mobile {
                            grid-template-columns: 1fr;

                        }
                    }
                }

                &.has-edit-mode {

                    > lfr-drop-zone {
                        width: 100%;

                        > div > div > div {
                            display: grid;
                            grid-template-columns: repeat(3, minmax(0, 1fr));
                            grid-gap: 16px;
                            grid-column-gap: 16px;
                            column-gap: 16px;
                            z-index: 5;
                            position: relative;
                        }
                    }

                    &.big-grid {
                        > lfr-drop-zone > div > div > div {
                            width: 100%;
                            grid-template-columns: repeat(2, minmax(0, 1fr));
                        }
                    }
                }
            }
        }

        &.is-primary {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }
        }

        &.is-secondary {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }
        }

        .container {
            padding: 16px 10%;

            .header {
                margin-bottom: 40px;
                text-align: left;
                color: $white;

                .second-title {
                    margin-bottom: 10px;
                }

                &-actions {
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 22.4px;
                    justify-content: flex-start;

                    @include mobile {
                        justify-content: center;
                    }

                    .icon {
                        width: 24px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 16px;
                        border-radius: 20px;
                        background-color: $info-color;

                        svg {
                            margin-left: 2px;
                            width: 10px;
                            height: 10px;
                            color: $white;
                        }
                    }
                }
            }

            .grid-elements {
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                grid-gap: 16px;
                grid-column-gap: 16px;
                column-gap: 16px;
                z-index: 5;
                position: relative;

                @include mobile {
                    grid-template-columns: repeat(1, 1fr);
                }


                .element {
                    padding: 30px 25px 40px 35px;
                    height: 100%;
                    border-radius: 10px;
                    background-color: $white;
                    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
                    color: $primary-color;
                    position: relative;

                    @include mobile {
                        height: min-content;
                    }

                    @include darkMode {
                        background-color: $bg-dark-cards !important;
                        color: $white;
                    }

                    @include tablet-only {
                        padding: 30px 20px 30px 30px;
                    }

                    &-icons {
                        display: grid;
                        grid-template-columns: 1fr 76px;
                        grid-gap: 16px;
                        grid-column-gap: 16px;
                        column-gap: 16px;
                        padding-bottom: 16px;

                        .element-icon {

                            svg {
                                color: $primary-color;
                                width: 60px;
                                height: 60px;

                                @include darkMode {
                                    color: $white;
                                }
                            }
                        }

                        .right-icons {
                            height: 50px;
                            display: flex;
                            justify-content: flex-end;

                            @include mobile {
                                height: 100%;
                            }

                            .element-arrow-icon {
                                width: 28px;
                                height: 28px;
                                display: inline-flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 20px;
                                background-color: $info-color;
                                position: relative;

                                svg {
                                    width: 18px;
                                    height: 18px;
                                    color: $white;
                                    margin-left: 2px;
                                }
                            }

                            .element-question-icon {
                                width: 28px;
                                height: 28px;
                                position: relative;

                                @include tablet-only {
                                    margin-right: 10px;
                                }

                                svg {
                                    color: $info-color;
                                }

                                &:hover {
                                    #help-text {
                                        display: flex;
                                        flex-direction: column;
                                    }
                                }
                            }
                        }
                    }

                    .element-text {
                        hyphens: auto;
                        color: $primary-color;
                        padding-top: 5px;
                        line-height: 22px;

                        @include darkMode {
                            color: $white;
                        }
                    }

                    .element-title {
                        hyphens: auto;
                        color: $primary-color;

                        @include darkMode {
                            color: $white;
                        }
                    }

                    .help-text {
                        display: none;
                        position: absolute;
                        z-index: 500;
                        width: 314px;
                        border-radius: 10px;
                        background-color: $card-bg-color-grey;
                        box-shadow: 0 5px 10px 0 rgba(14, 31, 53, 0.08), 0 10px 20px 0 rgba(14, 31, 53, 0.16), 0 24px 32px -1px rgba(14, 31, 53, 0.16), 0 32px 64px -2px rgba(14, 31, 53, 0.08);
                        padding: 30px;
                        color: $primary-color;

                        @include darkMode {
                            background-color: $bg-dark-secondary !important;
                            color: $white;
                        }

                        &-title {
                            line-height: 22px;
                            font-size: 20px;
                            font-weight: 700;
                            margin-bottom: 10px;
                        }
                    }
                }

            }

            .small-grid {

                @include tablet-only {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }

                .element {
                    &-icons {
                        .element-icon {
                            svg {
                                width: 50px;
                                height: 50px;
                            }
                        }

                        .right-icons {

                            .element-arrow-icon,
                            .element-question-icon {
                                position: relative;
                            }

                            .element-arrow-icon {
                                svg {
                                    width: 10px;
                                    height: 10px;
                                }
                            }
                        }
                    }
                }

                .help-text {
                    transform: translate(0, 0);
                    left: auto;
                    right: -20px;

                    @include desktop {
                        left: 0;
                        top: 100%;
                        transform: translate(-50%, 0);
                    }
                }
            }

            .big-grid {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));

                @include mobile {
                    grid-template-columns: repeat(1, 1fr);
                }

                .element {

                    &-icons {
                        grid-template-columns: 1fr 96px;
                        align-items: center;

                        .element-icon {

                            svg {
                                width: 60px;
                                height: 60px;
                            }
                        }

                        .right-icons {
                            display: flex;

                            .element-arrow-icon {
                                width: 40px;
                                height: 40px;
                                position: relative;

                                svg {
                                    width: 15px;
                                    height: 15px;
                                }
                            }

                            .element-question-icon {
                                width: 40px;
                                height: 40px;
                                position: relative;

                            }

                            @include mobile {
                                .element-arrow-icon {
                                    width: 28px;
                                    height: 28px;

                                    svg {
                                        width: 10px;
                                        height: 10px;
                                    }
                                }

                                .element-question-icon {
                                    width: 28px;
                                    height: 28px;

                                    svg {
                                        width: 28px;
                                        height: 28px;
                                    }
                                }
                            }
                        }
                    }

                    .help-text {
                        transform: translate(0, 0);
                        left: auto;
                        right: -20px;

                        @include desktop {
                            left: 0;
                            top: 100%;
                            transform: translate(-50%, 0);
                        }
                    }
                }
            }
        }
    }
}
