#wrapper {
    .card {
        border-radius: 10px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

        @include darkMode {
            background-color: $bg-dark-cards;
        }
    }

    .card-navigation {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;

        @include tablet-only {
            grid-template-columns: 1fr;
        }

        @include mobile {
            grid-template-columns: 1fr;
        }

        .cards {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto;
            grid-gap: 16px;

            @include tablet-only {
                grid-template-columns: repeat(2, 1fr);
            }

            @include mobile {
                grid-template-columns: 1fr;
            }

            .card {
                margin: 0;
                padding: 48px 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: var(--theme-primary, map-get($theme-colors, "bg-light-primary"));
                transition: all 0.14s ease-in-out;
                gap: 1rem;
                text-align: center;

                @include darkMode {
                    color: #ffffff;
                }

                &:hover {
                    color: var(--theme-accent, map-get($theme-colors, "accent"));
                }

                &-image {
                    flex: 0 0 auto;

                    .mailbox {
                        position: absolute;
                        right: -13px;
                        top: -13px;
                        border-radius: 100%;
                        background: red;
                        width: 26px;
                        height: 26px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        text-align: center;
                        line-height: 26px;
                        color: #fff;
                        font-weight: 700;
                        font-size: 12px;
                        z-index: 1;
                    }

                    svg {
                        width: 42px;
                        height: auto;
                        max-height: 42px;
                        position: relative;
                        z-index: 2;
                    }
                }

                &-content {
                    padding: 0;
                }

                &-title {
                    margin: 0;
                    font-size: 2rem;
                    font-weight: 700;
                    line-height: 1.5;
                    white-space: normal
                }
            }
        }

        .consultant-container {
            min-width: 100%;

            .card {
                margin: 0;
                min-height: 100%;
            }

            .consultant {
                display: grid;
                grid-template-columns: 2fr 1fr;
                grid-template-rows: repeat(2, auto);
                grid-column-gap: 16px;
                grid-row-gap: 64px;
                margin: 0;
                padding: 52px;
                height: 100%;
                color: var(--theme-primary, map-get($theme-colors, "bg-light-primary"));

                @include darkMode {
                    color: #ffffff;
                }

                @include mobile {
                    grid-template-columns: 1fr;
                    grid-template-rows: auto;
                    padding: 2rem;
                    grid-row-gap: 1rem;
                }

                &-name {
                    margin: 0;
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 38px;
                    word-break: break-word;

                    @include tablet {
                        grid-area: 1 / 1 / 2 / 2;
                    }

                    @include mobile {
                        font-size: 24px;
                        font-weight: 600;
                    }
                }

                &-intro {
                    display: block;
                    font-size: 20px;
                }

                &-photo {
                    width: 100%;
                    border-radius: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    @include tablet {
                        grid-area: 1 / 2 / 2 / 3;
                    }

                    @include mobile {
                        width: 60%;
                        margin: 0 auto;
                    }
                }

                &-info {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    @include tablet {
                        grid-area: 2 / 1 / 3 / 3;
                    }

                    a {
                        display: flex;
                        flex-direction: row;
                        color: var(--theme-primary, map-get($theme-colors, "bg-light-primary"));
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 24px;

                        @include darkMode {
                            color: #ffffff;
                        }

                        @include mobile {
                            font-size: 18px;
                            font-weight: 700;
                        }

                        &:hover {
                            color: var(--theme-accent, map-get($theme-colors, "accent"));
                        }

                        .icon {
                            flex: 0 0 auto;
                            padding-right: 20px;
                        }
                    }
                }

                &.is-loading {
                    .consultant-name,
                    .consultant-photo,
                    .consultant-info a {
                        position: relative;
                        color: rgba($white, 0);
                        background: rgba($white, 0.1) none !important;
                        overflow: hidden;

                        &:before {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 0;
                            left: -100%;
                            width: 100%;
                            height: 100%;
                            animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
                            background: linear-gradient(to right, transparent 0%, rgba($white, 0.2) 50%, transparent 100%);
                        }
                    }
                }
            }
        }
    }
}
