#wrapper {
    .versicherungsleistungen-container {

        &.primarygrey {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }
        }

        &.secondarygrey {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }
        }

        &.is-fragment:not(.has-edit-mode) .card-container > div > div {
            &,
            > div {
                height: 100%;
            }
        }

        &.is-fragment.has-edit-mode {
            .card-container {
                .page-editor__root {
                    > div,
                    > div > div,
                    > div > div > div,
                    > div > div > div > div,
                    > div > div > div > div > div {
                        height: 100%;
                    }
                }
            }
        }

        &.has-edit-mode .card-container .page-editor__root,
        &.is-fragment:not(.has-edit-mode) .card-container > div,
        &:not(.has-edit-menu):not(.is-fragment) .card-container {
            color: $grey-dark;
            display: grid;
            grid-auto-rows: 1fr;
            grid-gap: 2rem 3rem;
            grid-template-columns: repeat(2, 1fr);
            width: 80vw;
            max-width: 1200px;
            padding-top: 20px;

            @include mobile {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .versicherungsleistungen {
            color: $white;
            margin-right: auto;
            margin-left: auto;
            padding: 16px 10%;
            width: 100%;
            max-width: calc(1200px + 20%);
            z-index: 5;
            position: relative;

            .action-link {
                font-weight: $weight-bold;

                .icon {
                    background-color: $secondary-color;
                    border: 1px solid $secondary-color;
                    border-radius: 50%;
                    color: $white;
                    width: 20px;
                    height: 20px;
                    padding-left: 5px;

                    .icon-chevron-right {
                        margin-top: 4px;
                    }
                }
            }

            .card {
                height: 100%;

                .icon-line {
                    padding: 24px 24px 0 24px;
                    border-radius: 0 0 2px 2px;
                    display: flex;
                    justify-content: space-between;
                    font-size: 16px;

                    .not-inkl {
                        background-color: $error-color;
                        color: $white;
                        display: inline-block;
                        font-weight: $weight-bold;

                        .icon {
                            vertical-align: text-bottom;
                        }
                    }

                    .inkl {
                        color: $secondary-color;
                        display: inline-block;
                        font-weight: $weight-bold;

                        .icon {
                            vertical-align: inherit;
                        }
                    }

                    .icons {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: .5rem;

                        .help {
                            display: inline-block;

                            .help-icon {
                                border-radius: 50%;
                                width: 21px;
                                height: 21px;
                                font-weight: $weight-bold;
                                text-align: center;
                                line-height: 18.5px;
                                background-color: $white;

                                @include darkMode {
                                    background-color: $bg-dark-cards;
                                }
                            }

                            .help-tooltip {
                                display: none;
                                width: 250px;
                                background: #ced4d3;
                                border-radius: 10px;
                                color: $grey-dark;
                                padding: 15px;
                                position: absolute;
                                margin-left: -200px;
                                margin-top: 25px;
                                z-index: 50;

                                .help-text {
                                    display: block;
                                    font-weight: $weight-semibold;
                                    padding-bottom: 10px;
                                }
                            }

                            .help-icon:hover + .help-tooltip {
                                display: inline-block;
                            }
                        }

                        .link {
                            background-color: $secondary-color;
                            color: $white;
                            border: 1px solid $secondary-color;
                            border-radius: 50%;
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            text-align: center;
                            vertical-align: top;

                            .icon {
                                vertical-align: top;
                                margin-top: 4px;
                                margin-left: 2px;
                            }
                        }
                    }
                }

                .card-content {
                    padding-top: 0;

                    .card-title {
                        font-family: $family-sans-serif;
                        font-weight: $weight-bold;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        max-height: 100%;
                        overflow: hidden;
                        line-break: normal !important;
                        text-overflow: unset !important;
                        word-break: break-word;
                        white-space: normal !important;
                        hyphens: auto;
                        color: $grey-dark;
                        margin-top: 8px;

                        @include darkMode {
                            color: $white;
                        }
                    }

                    .card-text {
                        color: $grey-dark;
                        line-height: 24px;

                        @include darkMode {
                            color: $white;
                        }
                    }
                }

                &.inkl {
                    background-color: $white;

                    @include darkMode {
                        background-color: $bg-dark-cards;
                    }
                }

                &.not-inkl {
                    background-color: $card-bg-color-dark;
                    color: $white;

                    @include mobile {
                        order: 2;
                    }

                    .icon-line {
                        display: flex;
                        justify-content: space-between;

                        .not-inkl-element {
                            display: inline-block;

                            .left-element {
                                width: 18px;
                                height: 23px;
                                background-color: $error-color;
                                border: 1px solid $error-color;
                                border-radius: 50%;
                                display: inline-block;
                            }

                            .not-inkl {
                                vertical-align: top;
                                margin-left: -10px;
                                z-index: 50;
                                position: relative;
                            }

                            .right-element {
                                width: 18px;
                                height: 23px;
                                background-color: $error-color;
                                border: 1px solid $error-color;
                                border-radius: 50%;
                                display: inline-block;
                                margin-left: -10px;
                            }
                        }

                        .icons {
                            .help {
                                .help-icon {
                                    color: $white;
                                    line-height: 18.5px;
                                }
                            }

                            .link {
                                background-color: $white;
                                color: $secondary-color;
                                border: 1px solid white;
                            }
                        }
                    }

                    .card-content {

                        .card-title {
                            color: $white;
                        }

                        .card-text {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

body.has-edit-mode-menu {
    #wrapper {
        .versicherungsleistungen-container {

            &.has-edit-mode .card-container .page-editor__root,
            &.is-fragment:not(.has-edit-mode) .card-container > div,
            &:not(.has-edit-menu):not(.is-fragment) .card-container {
                max-width: 100%;
                width: 100%;
            }

            .versicherungsleistungen {
                max-width: 100%;
                padding: 0;
            }
        }
    }
}
