#wrapper {
    .explanation {
        color: $white;

        @include mobile {
            padding: 40px 0;
        }

        .container {
            padding: 16px 10%;
            padding-top: 0 !important;
        }

        .explanation-container {
            background-color: transparent;
        }

        &:not(.is-secondary) {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }

            &:after {
                background-color: $bg-light-primary;

                @include darkMode {
                    background-color: $bg-dark-primary;
                }
            }
        }

        &.is-secondary {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }

            &:after {
                background-color: $bg-light-secondary;

                @include darkMode {
                    background-color: $bg-dark-secondary;
                }
            }
        }

        &.has-image,
        &.has-video {
            &.play {
                .explanation-images {
                    .explanation-video {
                        display: block;

                        .embedded-video {
                            min-height: 300px;
                        }
                    }

                    .explanation-image {
                        display: none;
                    }
                }
            }

            .explanation-video {
                display: none;
            }

            .explanation-container {
                display: grid;
                grid-template-columns: 2fr 3fr;
                grid-template-rows: 1fr;
                grid-gap: 100px;

                @include mobile {
                    grid-template-columns: 1fr;
                    grid-template-rows: auto;
                    grid-gap: 40px;
                    margin-bottom: 0;
                }

                @include tablet-only {
                    grid-template-columns: 1fr;
                    grid-template-rows: auto;
                    grid-gap: 40px;
                    margin-bottom: 0;
                }

                &:before,
                &:after {
                    display: none !important;
                }

                .explanation-video {

                    iframe {
                        width: 100%;
                        height: 100%;
                        min-height: 300px;
                    }
                }
            }

            &.has-image-right,
            &.has-video-right {
                .explanation-container {
                    padding-right: 0;
                    grid-template-columns: 3fr 2fr;

                    @include mobile {
                        grid-template-columns: 1fr;
                    }

                    @include tablet-only {
                        grid-template-columns: 1fr;
                    }
                }

                .explanation-images {
                    order: 2;
                }

                .explanation-disclaimer {
                    text-align: right;

                    @include mobile {
                        text-align: left;
                    }

                    @include tablet-only {
                        text-align: left;
                    }
                }
            }
        }

        &-disclaimer {
            margin-top: 10px;
        }

        &-image {
            position: relative;
            overflow: hidden;

            a {
                color: $white;
            }

            img {
                border-radius: 10px;
                width: 100%;
            }

            .video-play-icon {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 100px;
                left: 50%;
                top: 50%;
                margin-top: -50px;
                margin-left: -50px;
                border: 2px solid $white;
                border-radius: 50%;
            }
        }

        &-images {
            display: grid;
            position: relative;
            z-index: 2;
            grid-template-columns: repeat(1, 1fr);
            grid-auto-rows: minmax(min-content, max-content);
            margin-top: 1.52rem;
            @include mobile {
                order: 2;
            }

            @include tablet-only {
                order: 2;
            }

            &.is-1-2,
            &.is-2-2 {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, auto) 1fr;
                grid-gap: 20px;

                .content-header-disclaimer,
                .explanation-disclaimer {
                    grid-column: 1 / span 2;
                    margin-top: -10px;
                }
            }

            &.is-1-2 {
                .content-header-image,
                .explanation-image {
                    &:first-child {
                        grid-column: 1 / span 2;
                    }
                }
            }
        }

        &-body {
            @include mobile {
                order: 1;
            }
        }

        &.has-text-centered:not(.has-image) .explanation-body,
        &.has-text-centered:not(.has-video) .explanation-body {
            margin-right: auto;
            margin-left: auto;
            max-width: 864px;
        }

        .title.is-3 {
            margin: 60px 0 0;

            @include mobile {
                margin: 0;
            }
        }

        .title.is-2 {
            margin: 10px 0 30px;
        }

        &-paragraph,
        &-actions {
            position: relative;
            z-index: 2;
        }

        &-paragraph {
            margin-bottom: 20px;
            hyphens: auto;
        }

        &-actions {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            margin-top: 40px;
            font-weight: 700;

            .icon {
                width: 24px;
                height: 24px;
                flex-shrink: 0;
            }

            a:not(.button) {
                display: flex;
                font-size: 20px;
                align-items: center;
                gap: 10px;

                .icon {
                    margin: 0;
                }
            }
        }

        &.has-text-centered .explanation-actions {
            justify-content: center;
        }

        .explanation-separator {
            margin: 20px auto;
            width: 100%;
            max-width: 1200px;
            border: 0 none;
            border-top: 1px solid #ffffff;
            background: transparent;
        }
    }
}


body.has-edit-mode-menu {
    #wrapper {
        .explanation {
            .explanation-video {
                margin-top: 2rem;
                display: block !important;
            }
        }
    }
}
