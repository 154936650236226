.icon {
    &.link-indicator {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-right: 16px;
        margin-left: 8px;
        border-radius: 20px;
        background-color: $info-color;

        .link-indicator-helper {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        svg {
            margin-left: 2px;
            width: 10px !important;
            height: 10px !important;
            color: $white;
        }
    }
}
