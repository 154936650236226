
.custom-google-map {
    width: 100%;
    height: 800px;

    .marker-position {
        transform: translate(26px, -25px);
        width: 20px;
        height: 20px;
        line-height: 20px;
        background: white;
        border-radius: 100%;
    }
}
