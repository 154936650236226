@media all and (min-width: 768px) {
    .controls-visible {
        .cookie-portlet {
            .portlet {
                &:hover {
                    > .portlet-topper {
                        transform: none;
                        z-index: 9991;
                    }
                }
            }
        }
    }
}

.cookie-notification {
    position: fixed;
    top: 52.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    z-index: 999;
    max-height: 90vh;
    margin: 0;
    overflow-y: auto;
    background-color: rgba(60, 65, 60, 0.96);
    box-shadow: 0 8px 24px 8px rgba(0, 0, 0, 0.2);
    color: $white;
    text-align: center;

    @include mobile {
        width: 100%;
    }

    @include tablet-only {
        width: 80%;
    }

    .cookie-container {
        max-height: 90vh;
        margin-right: auto;
        margin-left: auto;
        padding: 0;
    }

    .merkur-logo {
        height: 64px;
        margin-top: 40px;
        margin-bottom: 20px;

        svg {
            height: 64px;
        }
    }

    h1 {
        margin-top: 16px;
        margin-bottom: 20px;
        padding: 0;
        color: $white;
        font-weight: 300;
        text-align: center;
    }

    p {
        color: $white;
        font-weight: $weight-normal;
        line-height: 1.33;
        text-align: center;
        margin: 2.5%;
    }

    a {
        color: $secondary-color;
        text-decoration: underline;
    }

    .cookieSettingsHeadline {
        h2 {
            margin-top: 40px;
            margin-bottom: 20px;
            color: $white;
            text-align: center;
        }

        @include mobile {
            h2 {
                margin-right: 16px;
                margin-left: 16px;
                text-align: left;
            }
        }
    }

    .settingsHeadline {
        display: flex;
        align-items: center;
        height: 48px;
        margin-top: 10px;
        background-color: rgba(165, 215, 130, 0.2);
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
        cursor: pointer;

        svg {
            flex: 0 0 16px;
            margin-left: 16px;
            transform: rotate(-90deg);
        }

        h4 {
            flex: 1 1 auto;
            padding: 16px;
            color: $white;
            text-align: left;
        }

        .cookiesActiveLabel {
            margin-right: 16px;
            color: $secondary-color;
            text-align: right;
        }
    }

    .settingsBody {
        height: auto;
        margin: auto;
        padding: 16px;
        background-color: rgba(165, 215, 130, 0.2);
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);

        p {
            margin-bottom: 10px;
            color: $white;
            text-align: left;
        }

        .cookieName {
            flex: 0 0 20%;
            font-weight: 500;
            text-align: left;
        }

        .cookieCheck {
            display: flex;
            align-items: center;

            .cookieDescription {
                flex: 1 1 auto;
                text-align: left;
            }
        }

        .cookieAdditionalInformation {
            color: $secondary-color;
            text-align: left;
            text-decoration: underline;
        }

        @include mobile {
            .cookieName {
                display: block;
            }

            .cookieDescription {
                display: block;
            }

            .cookieAdditionalInformation {
                display: block;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: grid;
                grid-template-columns: 30% 70%;
                column-gap: 16px;
                align-items: center;
                width: 100%;
                padding: 8px 0;
                border-bottom: 2px solid;
                border-color: rgba(165, 215, 130, 0.2);
                overflow-wrap: break-word;
                text-align: left;
            }

            @include mobile {
                li {
                    display: block;
                }
            }

            li:last-child {
                border-bottom: 0;
            }
        }

        .checkbox {
            margin: 0;
            padding: 8px;

            label {
                display: block;
                position: relative;
                width: 36px;
                height: 20px;
                border-radius: 100px;
                background: $grey-light;
                text-indent: -9999px;
                cursor: pointer;

                &:after {
                    content: "";
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    width: 16px;
                    height: 16px;
                    transition: 0.3s;
                    border-radius: 90px;
                    background: $white;
                }

                &:active {
                    &:after {
                        width: 36px;
                    }
                }
            }

            input[type="checkbox"] {
                visibility: hidden;
                width: 0;
                height: 0;

                &:checked {
                    + label {
                        background-color: $secondary-color;

                        &:after {
                            left: calc(100% - 2px);
                            transform: translateX(-100%);
                        }
                    }
                }
            }
        }
    }

    @include mobile {
        .checkbox {
            display: flex;
            justify-content: center;
        }
    }

    .buttonRow {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        margin: 20px 5%;
        text-align: center;

        button {
            border-radius: 8px;
            z-index: 2;
            padding: 16px !important;
        }
    }

    @include mobile {
        .buttonRow {
            display: block;
            margin-right: 16px;
            margin-left: 16px;

            .acceptAllButton {
                margin: 16px 16px;
            }
        }
    }
}
