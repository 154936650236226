#wrapper {
    .quote {
        &.primarygrey {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }
        }

        &.secondarygrey {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }
        }

        .container {
            padding: 16px 10%;
            padding-top: 0 !important;
        }

        .quote-copyright {
            margin-top: 10px;
        }

        figure {
            margin: 0;
        }

        .row {
            display: grid;
            justify-content: center;
            align-content: center;
            grid-gap: 2rem;

            &.has-picture {
                &.slide-left {
                    grid-template-columns: 33% 66%;

                    @include mobile {
                        grid-template-columns: 100%;
                    }

                    @include tablet-only {
                        grid-template-columns: 100%;
                    }

                    .quote-image {
                        grid-column: 1;
                    }

                    .quote-body-container {
                        grid-column: 2;

                        @include mobile {
                            grid-column: 1;
                            grid-row: 2 !important;
                            margin-top: 20px;
                        }

                        @include tablet-only {
                            grid-column: 1;
                            grid-row: 2 !important;
                            margin-top: 20px;
                        }
                    }
                }

                &.slide-right {
                    grid-template-columns: 66% 33%;

                    @include mobile {
                        grid-template-columns: 100%;
                    }

                    @include tablet-only {
                        grid-template-columns: 100%;
                    }

                    .quote-image {
                        grid-column: 2;

                        @include mobile {
                            grid-column: 1;
                        }

                        @include tablet-only {
                            grid-column: 1;
                        }
                    }

                    .quote-body-container {
                        grid-column: 1;
                        padding-right: 20px;

                        @include mobile {
                            grid-row: 2 !important;
                            margin-top: 20px;
                        }

                        @include tablet-only {
                            grid-row: 2 !important;
                            margin-top: 20px;
                        }
                    }
                }
            }

            &.video-visible {
                .quote-video {
                    display: block !important;
                }
            }

            &.audio-visible {
                .quote-audio {
                    display: block !important;
                    width: 100%;
                }

                figure {
                    display: none;
                }

                .audio-icon {
                    display: none;
                }
            }

            &.has-audio {
                display: none;
            }

            .quote-image {
                position: relative;
                overflow: hidden;
                padding: 0;
                grid-row: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &.has-video {
                    align-items: normal;

                    .videos {
                        display: none !important;
                    }

                    &.video-visible {
                        figure {
                            display: none;
                        }

                        .link-icon {
                            display: none;
                        }

                        .videos {
                            display: block !important;
                        }
                    }
                }

                @include mobile {
                    width: 100% !important;
                    left: 0;
                }

                @include tablet-only {
                    width: 100% !important;
                    left: 0;
                }

                img {
                    border: 1px solid transparent;
                    border-radius: 10px;
                    width: 100%;
                    height: auto;
                }

                .quote-audio {
                    display: block;

                    audio {
                        width: 100%;
                    }

                    &.has-picture {
                        display: none;
                    }
                }

                .audio-icon {
                    display: block;
                    border: 1px solid $secondary-color;
                    border-radius: 50%;
                    background-color: $secondary-color;
                    width: 50px;
                    height: 50px;
                    position: absolute;

                    &:hover {
                        cursor: pointer;
                    }

                    .icon-play {
                        margin-top: 10px;
                        margin-left: 10px;
                        color: $white;
                    }
                }
            }

            .quote-body-container {
                padding: 0;
                grid-row: 1;

                @include mobile {
                    width: 100% !important;
                    margin-left: 0 !important;
                    right: auto !important;
                    left: auto !important;
                }

                @include tablet-only {
                    width: 100% !important;
                    margin-left: 0 !important;
                    right: auto !important;
                    left: auto !important;
                }

                &.has-picture {
                    overflow: hidden;

                    &.has-text-right {
                        margin-left: 100px !important;

                        @include mobile {
                            margin-left: 0 !important;
                        }

                        @include tablet-only {
                            margin-left: 0 !important;
                        }
                    }
                }

                .quote-body {
                    .title {
                        &.is-3 {
                            font-size: 26px !important;
                            margin-bottom: 16px;
                        }
                    }

                    .quote-icon {
                        display: inline-block;
                        position: relative;
                        width: 100%;
                        margin-top: -10px;
                        color: $white;
                        font-weight: 300;
                        letter-spacing: -10px;
                        height: 50px;

                        &:before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            font-size: 140px;
                            content: ',,';
                            margin-top: -120px;
                        }

                    }

                    .ueberschrift {
                        margin-top: 0;
                    }

                    .quote-paragraph {
                        margin-bottom: 20px;
                    }

                    .quote-from {
                        font-weight: $weight-semibold;
                    }

                    .quote-video {
                        margin-top: 10px;

                        &.has-picture {
                            display: none;
                        }

                        iframe {
                            width: 100%;
                            height: 50vh;

                            @include mobile {
                                height: 25vh;
                            }

                            @include tablet-only {
                                height: 40vh;
                            }
                        }
                    }

                    .action-link {
                        display: block;
                        font-size: 20px;
                        font-weight: $weight-bold;
                        line-height: 110%;
                        margin-bottom: 10px;
                        margin-top: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        p {
                            margin: 0;
                            font-size: 20px;
                            font-weight: $weight-bold;
                        }

                        .icon {
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            -webkit-box-pack: center;
                            -ms-flex-pack: center;
                            justify-content: center;
                            height: 30px;
                            width: 30px;
                            border-radius: 20px;
                            background-color: $secondary-color;
                            color: $white;
                            margin-left: 16px;
                        }
                    }

                    .action-button {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        p {
                            margin: 0;
                        }

                        .icon {
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .link-button-container {
                        margin-top: 20px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-end;
                        gap: 1rem;
                    }
                }
            }
        }
    }
}
