#wrapper {
    .merkur-popup-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden !important;

        .overlay {
            z-index: 99;
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #2c373b;
            opacity: 0.5;
        }

        &.staging-visible {
            position: static;
            margin-bottom: 100px !important;
            padding-top: 37px;

            .overlay {
                display: none !important;
            }

            &.hide {
                display: block !important;
            }

            &.force {
                position: relative !important;
            }

            .merkur-popup {
                z-index: 1;
            }
        }

        .merkur-popup {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #596265;
            min-width: 70vw;
            min-height: 90vh;

            .title {
                text-align: center;
                color: #596265 !important;
            }

            .merkur-popup-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                top: 100px;
                box-sizing: content-box !important;
                background-color: #fff;
                width: 55vw;
                height: auto;
                min-height: 40vh;
                margin-top: -150px;
                z-index: 999;
                padding: 50px;
                border-radius: 1rem;

                @include mobile {
                    width: 90vw;
                    min-height: 70vh;
                    padding: 10px;
                }

                @include tablet {
                    width: 70vw;
                    min-height: 60vh;
                    padding: 10px;
                }

                .popup-header {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 2rem;

                    .is-title {
                        @include mobile {
                            font-size: 2rem;
                        }
                    }
                }

                .popup-body {
                    margin-bottom: 2rem;

                    p {
                        font-size: 2rem;
                        text-align: center;

                        @include mobile {
                            font-size: 1.5rem;
                        }
                    }
                }

                .popup-footer {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 2rem;

                    .button {
                        min-width: 200px !important;
                        margin: 0 2rem;

                        @include mobile {
                            min-width: 100px !important;
                            margin: 0 1rem;
                        }

                        @include tablet {
                            min-width: 120px !important;
                            margin: 0 1.4rem;
                        }

                        &.close {
                            opacity: 1 !important;
                        }

                        &.is-primary {
                            background-color: #596265 !important;
                        }

                        &.is-secondary {
                            background-color: #79af43 !important;
                            color: $white !important;
                        }
                    }
                }
            }
        }
    }
}
