#wrapper {
    .three-columns {

        &:not(.is-secondary) {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }

            &:after {
                background-color: $bg-light-secondary;

                @include darkMode {
                    background-color: $bg-dark-secondary;
                }
            }
        }

        &.is-secondary {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }

            &:after {
                background-color: $bg-light-primary;

                @include darkMode {
                    background-color: $bg-dark-primary;
                }
            }
        }

        .container {
            padding: 16px 10%;

            .header {
                margin-bottom: 60px;

                .title {
                    text-align: center;
                }


                &-actions {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 20px;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 22.4px;
                    justify-content: center;

                    .icon {
                        width: 24px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 12px;
                        margin-right: 0;
                        border-radius: 20px;
                        background-color: $info-color;
                        color: $white;

                        svg {
                            margin-left: 2px;
                        }
                    }

                    a:not(.button) {
                        display: inline-flex;
                        align-items: center;

                        @include mobile {
                            display: flex;
                            flex-direction: column;
                            text-align: center;
                            padding-bottom: 10px;
                        }

                        .icon {
                            margin-left: 12px;

                            @include mobile {
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }

            .three-columns-list {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                text-align: center;
                position: relative;
                z-index: 5;


                @include mobile {
                    grid-template-columns: 1fr;
                }

                &-element {
                    height: 315px;
                    color: $white;
                    border: none;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin: auto;

                    &-title {
                        margin-top: 32px;
                        line-height: 26.67px;
                        font-weight: 700;
                    }

                    &-text {
                        display: inline-flex;
                        margin-top: 10px;
                        line-height: 26px;
                        height: 103px;
                        max-width: 300px;
                        margin-bottom: 24px;
                    }

                    &-icon {
                        margin-top: 40px;
                        width: 80px;
                        height: 80px;

                        svg {
                            width: 80px;
                            height: 80px;
                        }
                    }
                }
            }

            .absaetze-text {
                color: $white;
                margin-bottom: 60px;
                display: flex;
                justify-content: center;
            }

            .three-columns-image {
                display: flex;
                justify-content: center;
                margin-bottom: 60px;
                position: relative;
                z-index: 5;

                picture {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    width: 100%
                }

                img {
                    max-width: 100%;

                    &[src*='data:image/png;base64,iVBOR'] {
                        width: 100% !important;
                    }
                }
            }
        }

        .content-header-separator {
            width: 100%;
            max-width: 1200px;
            border: 0 none;
            border-top: 1px solid $white;
            background: transparent;
        }
    }
}
