#app-install-btn {
    transition: all 1.2s ease-in-out;
    overflow: hidden;
    position: fixed;
    top: 58px;
    width: 100vw;
    height: auto;
    max-height: 0 !important;
    display: flex;
    z-index: 9999;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: #ffffff;
    color: #000000;

    &.collapse {
        max-height: 0;
        padding: 0.3rem 1rem;
        @include mobile() {
            max-height: 200px !important;
        }
    }

    > div {
        padding: 1rem;
    }

    #app-install-btn-img {
        flex-grow: 0;
        background-color: rgba(#2c373b, 0.9);
        border-radius: 1rem;

        img {
            width: 32px;
        }
    }

    #app-install-btn-title {
        flex-grow: 1;
    }

    #app-install-btn-close {
        flex-grow: 0;
        cursor: pointer;
    }

    .badge {
        background-color: #2196F3 !important;
        color: #ffffff;
    }
}
