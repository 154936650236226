.has-sub-navigation {
    .page-header-subnav {
        ul {
            li {
                a {
                    display: block;
                    padding: 14px 15px;
                    line-height: 20px;
                    font-size: 14px;
                    text-decoration: none;
                    border-bottom: 2px solid rgb(255, 255, 255);
                }
            }
        }
    }
}
