.welcome-message {
    margin: 80px 0 100px;

    @include mobile {
        margin: 40px 0 60px;
    }

    h2.title.is-1 {
        font-weight: 700;

        @include mobile {
            font-size: $size-1 * 0.875;
        }
    }
}
