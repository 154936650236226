$button-min-width: 9.5rem !default;
$button-padding-y: 30px !default;
$button-padding-x: 16px !default;
$button-small-padding-y: 18px !default;
$button-small-padding-x: 10px !default;
$button-medium-padding-y: 14px !default;
$button-medium-padding-x: 8px !default;
$button-box-shadow-size: 0 6px 12px 0 !default;
$button-box-shadow-hover-size: $button-box-shadow-size !default;
$button-radius: 10px !default;

/**
 * Button colors
 * @example "name": (background-color, text-color, hover-background-color, hover-text-color)
 * @result <button class="button is-name" />
 */
$button-colors: (
    "primary": (map-get($theme-colors, "accent"), $white, map-get($theme-colors, "accent-lighter"), $white),
    "white": ($white, map-get($theme-colors, "accent"), $white, map-get($theme-colors, "accent")),
    "jaffa": ($theme-jaffa, $white, lighten($theme-jaffa, 10%), $white),
    "cream": ($theme-cream, $white, lighten($theme-cream, 10%), $white),
    "olive": ($theme-olive, $white, lighten($theme-olive, 10%), $white),
    "viridian": ($theme-viridian, $white, lighten($theme-viridian, 10%), $white),
    "cello": ($theme-cello, $white, lighten($theme-cello, 10%), $white),
    "waikawa": ($theme-waikawa, $white, lighten($theme-waikawa, 10%), $white),
    "fuzzy": ($theme-fuzzy, $white, lighten($theme-fuzzy, 10%), $white),
    "tan": ($theme-tan, $white, lighten($theme-tan, 10%), $white),
    "gray": ($grey-light, $white, lighten($grey-light, 10%), $white)
) !default;

.button {
    @extend %control;
    @extend %unselectable;
    justify-content: center;
    min-width: $button-min-width;
    height: auto;
    margin-bottom: 20px;
    padding: $button-padding-x $button-padding-y;
    border: 1px solid;
    border-radius: $button-radius;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.17;
    text-align: center;
    cursor: pointer;

    @include mobile {
        margin-bottom: 10px;
    }

    @include tablet {
        margin-bottom: 10px;
    }

    &,
    &:after {
        transition: all 0.2s ease-out;
        will-change: color, background-color, border-color, transform;
    }

    &.is-edgy {
        border-radius: 0;
    }

    @each $name, $pair in $button-colors {
        $color: nth($pair, 1);
        $color-invert: nth($pair, 2);
        $color-hover: nth($pair, 3);
        $color-invert-hover: nth($pair, 4);

        &.is-#{$name} {
            border-color: transparent;
            background-color: $color;
            box-shadow: $button-box-shadow-size rgba(29, 35, 24, 0.2);
            color: $color-invert;

            &.is-shadowless {
                box-shadow: none;
            }

            .icon {
                width: 24px;
                height: 24px;
                margin-right: 10px;
                color: $color-invert;

                &.is-right {
                    margin-right: 0;
                    margin-left: 10px;
                }

                svg {
                    width: 24px;
                    height: 24px;
                }
            }

            &:hover,
            &.is-hovered {
                border-color: transparent;
                background-color: $color-hover;
                color: $color-invert-hover;

                .icon {
                    color: $color-invert-hover;
                }

                &.has-chevron-right:not(.is-loading) {
                    &:after {
                        color: $color-invert-hover;
                    }
                }
            }

            &:focus,
            &.is-focused {
                border-color: transparent;
                outline: none;
                color: $color-invert;
            }

            &:active,
            &.is-active {
                border-color: transparent;
                background-color: $color-hover;
                color: $color-invert-hover;
            }

            &:hover,
            &.is-hovered,
            &:focus,
            &.is-focused,
            &:active,
            &.is-active {
                text-decoration: none;

                &.has-shadow:not([disabled]):not(.is-disabled) {
                    box-shadow: $button-box-shadow-hover-size rgba(29, 35, 24, 0.5);
                }
            }

            &.is-disabled,
            &[disabled],
            fieldset[disabled] & {
                border-color: transparent;
                background-color: map-get($theme-button-disabled, "light");
                box-shadow: none;
                color: rgba($white, 0.6);

                @include darkMode {
                    background-color: map-get($theme-button-disabled, "dark")
                }

                &.has-chevron-right:not(.is-loading) {
                    &:after {
                        color: rgba($white, 0.6);
                    }
                }
            }

            &.is-loading {
                position: relative;
                box-shadow: none;

                &:after {
                    border-radius: 1em;
                    border-color: transparent transparent $color-invert $color-invert !important; // stylelint-disable-line declaration-no-important
                }
            }

            &.is-outlined {
                border-color: $color;
                background-color: transparent;
                color: $color;

                &:hover,
                &.is-hovered,
                &:focus,
                &.is-focused {
                    border-color: $color;
                    background-color: $color;
                    color: $color-invert;
                }

                &.is-loading {
                    &:after {
                        border-color: transparent transparent $color $color !important; // stylelint-disable-line declaration-no-important
                    }
                }

                &.is-disabled,
                &[disabled],
                fieldset[disabled] & {
                    border-color: $color;
                    background-color: transparent;
                    box-shadow: none;
                    color: $color;
                }
            }
        }
    }

    &.is-disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: not-allowed;
    }

    &.is-small {
        min-width: auto;
        padding: $button-small-padding-x $button-small-padding-y;
        border-radius: $radius-small;
        font-size: 0.875rem;
    }

    &.result-button {
        min-width: auto;
        padding: $button-medium-padding-x $button-medium-padding-y;
        border-radius: $radius;
        font-size: 1.75rem;
    }

    &.is-fullwidth {
        display: flex;
        width: 100%;
    }

    &.is-loading {
        color: transparent !important; // stylelint-disable-line declaration-no-important
        cursor: default;
        pointer-events: none;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: calc(50% - .5em);
            left: calc(50% - .5em);
            width: 1em;
            height: 1em;
            animation: spinAround 500ms infinite linear;
            border: 2px solid rgba($white, 0.5);
            border-radius: $radius;
            border-top-color: transparent;
            border-right-color: transparent;
        }
    }
}
