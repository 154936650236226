#partner_hotels {
    margin-right: auto;
    margin-left: auto;
    padding: 0 10%;
    width: 100%;
    max-width: calc(1200px + 20%);

    @include mobile {
        padding: 0 2rem;
    }

    header {
        display: none;
    }

    .list-gallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
        grid-gap: 20px;

        @include tablet-only {
            grid-template-columns: repeat(3, 1fr);
        }

        @include mobile {
            grid-template-columns: 1fr;
        }

        li {
            @extend .card;
            position: relative;
            overflow: hidden;

            figure {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                margin: 0;

                img {
                    width: 100%;
                    height: auto !important;
                }
            }

            h3 {
                margin: 0;
                padding: 0;
                font-size: 20px;

                a {
                    position: relative;
                    z-index: 2;
                    display: block;
                    padding: 110px 20px 20px;

                    @include darkMode {
                        color: $white;
                    }

                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }
}

#merkurpartner_detail_container {
    > * {
        margin-right: auto;
        margin-left: auto;
        padding: 0 10% !important;
        width: 100%;
        max-width: calc(1200px + 20%);

        @include mobile {
            padding: 0 2rem !important;
        }
    }

    .link-back {
        margin-bottom: 24px;
    }

    .link-back,
    .link-center {
        text-align: center;
    }

    .slider-a {
        li,
        img {
            width: 100%;
            height: auto !important;
        }
    }

    .article-a {
        font-size: 20px;

        ul {
            padding-left: 20px;

            li {
                list-style-type: disc;
            }
        }

        + p {
            margin-top: 2rem;
            text-align: center;
            font-size: 20px;
        }
    }
}
