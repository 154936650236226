#wrapper {

    .wichtige-information {

        &.primarygrey {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }
        }

        &.secondarygrey {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }
        }

        .container {
            padding: 16px 10%;
        }


        .wichtige-information-container {

            &.wichtige-information-border {
                border: 2px solid $secondary-color;
                border-radius: 10px;
                padding: 5vw;

                @include mobile {
                    padding: 40px;
                }
            }

            &.text-center {
                text-align: center;

                .action-link {
                    justify-content: center;
                }
            }

            .title {
                margin-top: 0;
            }

            .action-link {
                font-weight: $weight-bold;
                margin-top: 10px;
                display: flex;
                justify-content: unset;
                align-items: center;
                gap: 1rem;

                .icon {
                    background-color: $secondary-color;
                    border: 1px solid $secondary-color;
                    border-radius: 50%;
                    color: $white;
                    width: 20px;
                    height: 20px;

                    .icon-chevron-right {
                        margin-left: 5px;
                        margin-top: 4px;
                    }
                }
            }

            .button {
                margin-top: 10px;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }

            .html-container {

                ul:not(.browser-default) > li {
                    list-style-type: disc;
                }

                ul:not(.browser-default) > li > ul > li {
                    list-style-type: circle !important;
                }
            }

            lfr-drop-zone {
                .page-editor__root {
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                }
            }

            &.text-center {

                lfr-drop-zone {
                    .page-editor__root {
                        justify-content: center;
                    }
                }
            }
        }

        &.is-fragment {
            .wichtige-information-container {
                > div:last-child:not(.html-container) {
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                }

                &.text-center {
                    > div:last-child:not(.html-container) {
                        justify-content: center;
                    }
                }
            }
        }
    }
}
