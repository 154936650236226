body.has-edit-mode-menu {
    #wrapper {
        .collapsible {
            .page-editor {
                .collapsible-body {
                    display: block;
                }
            }
        }
    }

    .page-editor__sidebar {
        .page-editor__sidebar__buttons {
            button {
                font-size: 16px !important;
            }
        }
    }
}

html:not(#__):not(#___) .cadmin.page-editor__topper__bar {
    z-index: 5;
}
