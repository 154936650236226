html:not(#__):not(#___) .portlet {
    &:hover {
        > .cadmin.portlet-topper {
            opacity: 1;
        }
    }

    .cadmin.portlet-topper button {
        line-height: 24px !important;
        color: #000;
    }
}

body.has-control-menu .dropdown-menu,
html:not(#__):not(#___) .cadmin .dropdown-menu,
html:not(#__):not(#___) .cadmin.dropdown-menu {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 14px;
    min-width: 240px;
    max-width: 240px;
    line-height: 1.5;
}

body.has-control-menu .dropdown-menu .dropdown-item,
html:not(#__):not(#___) .cadmin .dropdown-menu .dropdown-item,
html:not(#__):not(#___) .cadmin.dropdown-menu .dropdown-item {
    padding-top: 8px;
    padding-bottom: 8px;
}

body {
    &.staging {
        .yui3-widget-mask[style] {
            display: none !important;
        }
    }

    .staging-bar {
        .control-menu-level-2 {
            height: 100%;
            margin-right: 270px;
            margin-left: 100px;
            overflow: hidden;
        }
    }

    .portlet-title-text {
        display: none;
    }

    .portlet {
        margin-bottom: 0 !important;
    }

    #wrapper {
        margin: 0 !important; // stylelint-disable-line declaration-no-important
        padding: 0 !important; // stylelint-disable-line declaration-no-important

        .portlet-content {
            padding: 0;
            border: 0 none;
            background: transparent;
        }

        .portlet-dropzone {
            > div:first-child {
                .portlet-topper {
                    transform: translateY(0);
                }
            }
        }

        .portlet-topper {
            align-items: center;
        }

        .portlet-topper-toolbar {

            &:last-child {
                margin-right: 15px;
            }
        }

        .portlet-title-text {
            display: none !important; // stylelint-disable-line declaration-no-important
            visibility: hidden !important; // stylelint-disable-line declaration-no-important
            pointer-events: none !important; // stylelint-disable-line declaration-no-important
            user-select: none !important; // stylelint-disable-line declaration-no-important
        }

        .portlet-decorate.portlet-asset-publisher {
            .h2 {
                .header-title,
                .header-back-to {
                    display: none !important; // stylelint-disable-line declaration-no-important
                    visibility: hidden !important; // stylelint-disable-line declaration-no-important
                    pointer-events: none !important; // stylelint-disable-line declaration-no-important
                    user-select: none !important; // stylelint-disable-line declaration-no-important
                }
            }
        }

        .portlet-column {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

#wrapper nav {
    margin: 0;
    background-color: transparent;
    box-shadow: none !important;
}

.portlet > .portlet-topper {
    z-index: 100;
}

body.has-edit-mode-menu {
    .page-editor__layout-viewport {
        background: none !important;
    }
}


body.has-edit-mode-menu,
body.signed-in.has-control-menu,
body.signed-in.has-staging-bar {
    .cadmin {
        &.control-menu-container {
            .control-menu-nav {
                .user-avatar-link {
                    position: relative;

                    a {
                        position: absolute;
                        display: inline-block;
                        top: -1rem;
                        left: -1rem
                    }
                }
            }
        }
    }
}
