#wrapper {
    .card-articles {
        a,
        p {
            color: $bg-light-primary;
            @include darkMode {
                color: $white;
            }
        }

        .col {
            justify-content: flex-start;
            padding: 10px !important;
            margin-left: unset !important;
            flex: 0 1 auto !important;
        }

        .card {
            overflow: hidden;
            border: none !important;

            a {
                display: flex !important;
                flex-direction: column;
                height: 100%;
            }

            @include darkMode {
                background-color: $bg-dark-secondary;
                color: $white !important;
            }

            &.small {
                height: 380px !important;
            }

            &.medium {
                height: 450px;
            }

            &.large {
                height: 520px;
            }

            .card-image {
                display: flex;
                flex: 0 0 auto;
                height: 200px;
                overflow: hidden !important;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;

                @include darkMode {
                    background-color: $bg-dark-secondary;
                }

                a {
                    display: block !important;
                    position: relative !important;
                    top: 0;
                    right: 0;
                    width: 2rem;
                    height: 2rem;
                }

                .card-title {
                    color: $white !important;
                }

                .lexicon-icon {
                    position: relative;
                    top: 0;
                    right: 0;
                }
                img {
                    width: 100%;
                    height: 100%;
                    overflow: hidden !important;
                    object-position: center;
                    object-fit: cover;
                }

                &.svg-image {
                    padding: 5rem 20px 2.5rem 20px;

                    svg {
                        display: block;
                        width: 140px;
                        max-height: 100%;
                    }
                }
            }

            .card-image + .card-content {
                max-height: 100% !important;
            }

            .card-image + .card-content {
                max-height: 100% !important;
            }

            .card-content {
                flex: 1 0 auto;
                background-color: $white;

                @include darkMode {
                    background-color: $bg-dark-secondary;
                    color: $white;
                }

                .card-content-text {
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }

            .card-title {
                line-height: 1.125 !important;
                font-weight: 700;
                color: $bg-light-primary;

                @include darkMode {
                    color: $white;
                }
            }

            .card-action {
                a {
                    color: $accent !important;
                }
            }
        }
    }
}
