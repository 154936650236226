.slider-container {

    &.is-primary {
        background-color: $bg-light-primary;

        @include darkMode {
            background-color: $bg-dark-primary;
        }
    }

    &.is-secondary {
        background-color: $bg-light-secondary;

        @include darkMode {
            background-color: $bg-dark-secondary;
        }
    }

    .container {
        padding: 0;
        margin: 0 auto;
    }

    .text {
        margin-bottom: 3rem;

        @include mobile {
            margin-bottom: 2rem;
        }
    }

    body.has-edit-mode-menu &,
    &.has-edit-mode {
        .swiper {
            opacity: 1;
        }
    }

    .swiper {
        position: relative;
        opacity: 0;

        &-initialized {
            opacity: 1;
        }

        &-wrapper {
            max-width: 100%;

            .swiper-slide {
                overflow: hidden;
                position: relative;
                height: 500px;
                @include mobile {
                    width: 100%;
                    height: auto;
                }

                img {
                    object-fit: cover;
                    object-position: top left;
                    aspect-ratio: 16 / 9;
                    width: 100%;
                }
            }
        }

        &-pagination {
            &-horizontal {
                margin-top: 1rem;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: stretch;
                flex-wrap: wrap;
                flex-direction: row;
                gap: 12px;
            }

            &-bullet {
                background: transparent;
                border: 0.75px solid $link-color;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                transition: all 0.14s ease-in-out;
                cursor: pointer;

                &-active {
                    background: $link-color;
                }

                &:not(&-active):hover {
                    background: $link-color;
                }
            }

            &-lock {
                display: none !important;
            }
        }

        &-button {

            &-lock {
                display: none !important;
            }

            &-prev,
            &-next {
                position: absolute;
                top: 0;
                width: 5rem;
                height: 100%;
                transition: all 0.14s ease-in-out;
                z-index: 1;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 1;
                visibility: visible;
                color: rgba(#fff, .8);

                svg {
                    width: 36px;
                    height: 36px;
                }

                &:hover {
                    color: rgba($link-color, 1);
                }

                &.swiper-button-disabled {
                    visibility: hidden;
                    opacity: 0;
                }
            }

            &-prev {
                align-self: flex-end;

                transform: rotate(-180deg);
                left: 0rem;
            }

            &-next {
                right: 0;
            }

        }
    }
}

.custom-slider {
    &.has-edit-mode {
        .swiper {
            > lfr-drop-zone {
                > div {
                    > .page-editor {
                        > .page-editor__root {
                            display: grid;
                            grid-gap: 1rem;
                            grid-template-columns: repeat(3, 1fr);
                            margin-bottom: 2rem;

                            .page-editor__topper__content {
                                width: 100%;
                                height: 100%;

                                & > div,
                                & > div > div,
                                & > div > div > div,
                                & > div > div > div > div {

                                    width: 100%;
                                    height: 100%;
                                }

                            }

                            img {
                                height: auto;
                                width: 100%;
                                aspect-ratio: 16 / 9;
                                object-fit: cover;
                                object-position: center;
                            }
                        }
                    }
                }
            }

            .swiper-button-prev,
            .swiper-button-next,
            .swiper-pagination {
                display: none;

            }
        }
    }
}
