.socials-container {
    .socials-icon {
        display: flex;
        flex-direction: row;
    }

    .socials-image {
        svg {
            width: 24px;
            height: 24px;
            color: #fff !important;
        }
    }

    .socials-icon-single {
        margin-right: 20px;
    }
}
