#wrapper {
    .wissenswertes {
        .image-container {
            .image img[src*='data:image/png;base64'] {
                min-width: 150px !important;
                min-height: 150px !important;
            }
        }

        &.primarygrey {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }
        }

        &.secondarygrey {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }
        }

        .container {
            padding: 16px 10%;

            .row {
                margin: 0;
                flex-direction: row;

                @include mobile {
                    flex-direction: column;
                    gap: 1rem;
                }

                &.is-reversed {
                    flex-direction: row-reverse;
                    @include mobile {
                        gap: 1rem;
                        flex-direction: column-reverse;
                    }
                }
            }

            .wissenswertes-image {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                flex: 38%;

                @include mobile {
                    display: block;
                    width: 100%;
                    left: auto;
                    right: auto;
                    padding: 0;
                    margin: 0;
                }

                .content-header-image {
                    width: 100%;
                }

                .content-header-disclaimer {
                    color: $white;
                }
            }

            .wissenswertes-body-container {
                display: grid;
                padding: 0;
                flex: 62%;
                height: 100%;

                @include mobile {
                    display: block;
                    width: 100%;
                    left: auto;
                    right: auto;
                }

                .wissenswertes-body {
                    color: $bg-light-primary;
                    border-radius: 20px;
                    padding: 3vw;
                    background-color: $card-bg-color-grey;
                    display: inline-block;
                    grid-row: 1;

                    @include darkMode {
                        color: $bg-dark-primary;
                        border: 1px solid $bg-grey;
                        background-color: $bg-grey;
                    }

                    @include mobile {
                        padding: 5vw;
                        width: 100%;
                    }

                    &.has-image-left {
                        grid-column: 2;
                        @include mobile {
                            margin-left: 0;
                        }
                    }

                    &.has-image-right {
                        grid-column: 1;
                        margin-right: 40px;

                        @include mobile {
                            margin-right: 0;
                        }
                    }

                    &.left {
                        text-align: left;
                    }

                    &.center {
                        text-align: center;
                    }

                    .title {
                        color: $bg-light-primary;
                        margin-top: 0;

                        @include darkMode {
                            color: $bg-dark-primary;
                        }
                    }

                    p {
                        word-break: break-word;
                    }

                    .action-link {
                        font-weight: $weight-bold;
                        display: block;

                        .icon {
                            background-color: $accent;
                            border: 1px solid $accent;
                            border-radius: 50%;
                            color: $white;
                            width: 20px;
                            height: 20px;

                            @include darkMode {
                                background-color: $accent;
                                border: 1px solid $accent;
                            }

                            .icon-chevron-right {
                                margin-left: 5px;
                                margin-top: 4px;
                            }
                        }
                    }

                    .button {
                        margin-bottom: 10px;
                    }
                }
            }

            .triangle-container {
                display: inline-block;
                justify-content: center;
                align-items: center;
                grid-row: 1;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: -1px;

                &.has-image-left {
                    transform: scaleX(-1);
                    grid-column: 1;
                    margin-right: -1px;
                }

                &.has-image-right {
                    margin-left: -55px;
                    grid-column: 2;
                }

                @include mobile {
                    display: none;
                }
            }
        }
    }
}
