#wrapper .dl-list {
    .card {
        overflow: hidden;

        @include darkMode {
            background-color: $bg-dark-cards;
        }

        .card-stacked {
            max-width: calc(100% - 100px);
            padding: 1rem 0;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .card-title {
            font-size: $size-4 !important;
            color: $primary-color;
            @include darkMode {
                color: $white !important;
            }

            h3 {
                font-size: inherit;
                margin: 0;
            }
        }

        .info {
            @include darkMode {
                color: $white !important;
            }
            margin: 0;
        }

        .card-image {
            width: 100px !important;
            flex: 0 0 auto;
            padding: 10px;

            img {
                margin: 0;
                max-width: 100%;
                height: auto;
            }
        }

        .card-content {
            padding: 0;

            .small {
                font-size: $size-6;
                color: $secondary-color !important;

                @include darkMode {
                    color: $white !important;
                }
            }
        }
    }
}
