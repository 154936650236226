#wrapper .general-inquiry {
    .container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto 1fr;
        grid-column-gap: 60px;
        grid-row-gap: 80px;
        padding: 16px 10%;

        @include mobile {
            grid-template-columns: 1fr;
            grid-gap: 30px;
        }

        @include tablet-only {
            grid-template-columns: 1fr;
            grid-gap: 60px;
        }

        &::before,
        &::after {
            display: none !important;
        }
    }

    &-header {
        grid-column: 1;
        grid-row: 1;

        @include mobile {
            grid-row: 1;
        }

        @include tablet-only {
            grid-row: 1;
        }
    }

    &-info {
        grid-column: 1;
        grid-row: 2;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, auto);
        grid-column-gap: 30px;
        grid-row-gap: 60px;

        @include mobile {
            grid-row: 3;
        }

        @include tablet-only {
            grid-row: 3;
        }

        .icon {
            width: 44px;
            height: 44px;
            padding: 10px;
            border-radius: 9999em;
            box-shadow: 0 6px 12px 0 rgba(29, 35, 24, 0.2);
            background-color: $secondary-color;
            color: $white;
        }
    }

    &-info-phone,
    &-info-fax,
    &-info-mail,
    &-info-emergency {
        display: grid;
        grid-template-columns: 44px 1fr;
        grid-template-rows: repeat(2, auto);
        grid-column-gap: 20px;
        font-size: 20px;
        color: $white !important;

        .icon {
            grid-area: 1 / 1 / 3 / 2;
        }

        strong {
            grid-area: 1 / 2 / 2 / 3;
            font-size: 14px;
        }
    }

    &-info-fax {
        grid-template-columns: 1fr;

        strong {
            grid-column: 1;
            grid-row: 1;
        }

        *:not(strong) {
            grid-row: 2;
        }
    }

    &-info-phone {
        grid-area: 1 / 1 / 2 / 2;
    }

    &-info-fax {
        grid-area: 1 / 2 / 2 / 3;
    }

    &-info-mail {
        grid-area: 2 / 1 / 3 / 3;
    }

    &-info-emergency {
        grid-area: 3 / 1 / 4 / 3;
    }

    &-info-social {
        grid-area: 4 / 1 / 5 / 3;
    }

    &-info-social {
        .icon {
            background-color: $white;
            color: $primary-color;
        }
    }

    &-social-links {
        display: flex;
        gap: 28px;
    }

    &-address {
        font-size: 20px;
    }

    &-form {
        grid-column: 2;
        grid-row: 1 / span 2;
        padding: 60px;
        border-radius: 10px;
        background-color: $white;
        box-shadow: 0 6px 10px 0 rgba($box-shadow-color, 0.12), 0 12px 18px 0 rgba($box-shadow-color, 0.2), 0 20px 40px -1px rgba($box-shadow-color, 0.12);
        color: $primary-color;

        @include mobile {
            grid-row: 2;
            grid-column: 1;
            padding: 25px;
        }

        @include tablet-only {
            grid-row: 2;
            grid-column: 1;
        }

        .title {
            color: $primary-color !important;
        }

        .merkur-form-fields {
            .row {
                display: flex;
                row-gap: 1rem;
                margin-right: -0.75rem;
                margin-left: -0.75rem;
                flex-direction: row;

                &::before,
                &::after {
                    display: none !important;
                }

                .col {

                    min-width: 100%;
                    width: 100%;
                    padding: 0;

                    @include from(601px) {

                        &.m1 {
                            min-width: 8.3333333333%;
                            max-width: 8.3333333333%;
                        }

                        &.m2 {
                            min-width: 16.6666666667%;
                            max-width: 16.6666666667%;
                        }

                        &.m3 {
                            min-width: 25%;
                            max-width: 25%;
                        }

                        &.m4 {
                            min-width: 33.3333333333%;
                            max-width: 33.3333333333%;
                        }

                        &.m5 {
                            min-width: 41.6666666667%;
                            max-width: 41.6666666667%;
                        }

                        &.m6 {
                            min-width: 50%;
                            max-width: 50%;
                        }

                        &.m7 {
                            min-width: 58.3333333333%;
                            max-width: 58.3333333333%;
                        }

                        &.m8 {
                            min-width: 66.6666666667%;
                            max-width: 66.6666666667%;
                        }

                        &.m9 {
                            min-width: 75%;
                            max-width: 75%;
                        }

                        &.m10 {
                            min-width: 83.3333333333%;
                            max-width: 83.3333333333%;
                        }

                        &.m11 {
                            min-width: 91.6666666667%;
                            max-width: 91.6666666667%;
                        }

                        &.m12 {
                            min-width: 100%;
                            max-width: 100%;
                        }
                    }

                    .title,
                    .description,
                    .element {
                        margin: 0 .75rem;
                    }

                    &.is-unset {
                        flex-basis: 0;
                        flex-grow: unset;
                        margin: 0;
                    }

                    label {
                        top: 11px;
                        left: 16px;
                        font-size: 12px;
                        font-weight: $weight-bold;
                        color: $primary-color;

                        &.active {
                            transform: none;
                        }

                        &.is-danger {
                            color: $error-color;
                        }
                    }

                    .select-wrapper {

                        + label {
                            transform: none;
                        }
                    }
                }
            }

            input[type='radio'] {
                pointer-events: auto;
            }

            .input-field {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 12px 16px;
                min-height: 64px;
                border-radius: 6px;
                background-color: $bg-grey;
                margin: 0;

                &.textarea-field {
                    padding: 16px;
                }
            }

            label.merkur-checkbox {
                font-weight: $weight-normal;
                color: $primary-color;

                span {
                    font-size: 14px;
                }

                a {
                    font-weight: $weight-bold;
                }
            }

            input:not([type]),
            input[type="text"]:not(.browser-default),
            input[type="password"]:not(.browser-default),
            input[type="email"]:not(.browser-default),
            input[type="url"]:not(.browser-default),
            input[type="time"]:not(.browser-default),
            input[type="date"]:not(.browser-default),
            input[type="datetime"]:not(.browser-default),
            input[type="datetime-local"]:not(.browser-default),
            input[type="tel"]:not(.browser-default),
            input[type="number"]:not(.browser-default),
            input[type="search"]:not(.browser-default),
            input[type="file"]:not(.browser-default),
            input[type="radio"]:not(.browser-default),
            textarea.materialize-textarea {
                height: 2.6rem;
                margin: 0;
                padding: 0;
                border: 0 none !important;
                font-size: 16px;
                color: $primary-color;

                &:focus {
                    border: 0 none;
                    box-shadow: none;
                    color: $secondary-color;
                }

                &.is-danger + label.merkur-label {
                    color: $error-color;
                }

                &.expand {
                    &.is-touched, &:focus {
                        margin-top: 1rem;
                        height: 8rem;
                        transition: 300ms ease;
                        overflow: auto;
                    }
                }
            }

            input[type="file"]:not(.browser-default) {
                height: auto;

                &,
                &:focus,
                &::selection {
                    color: transparent;
                }
            }


            textarea.materialize-textarea {
                &,
                &:focus {
                    margin-top: 13px !important;
                }
            }

            input[type="radio"]:not(.browser-default) {
                &.is-danger + span {
                    color: $error-color;
                }
            }

            .control-label {
                &.is-danger {
                    color: $error-color;
                }
            }

            .merkur-file-field {
                display: flex;
                gap: .5rem;
                flex-direction: column;

                .merkur-files {
                    .merkur-files-list {
                        margin: 0;
                        display: flex;
                        gap: .5rem;
                        flex-direction: column;

                        .merkur-file-list-entry {
                            display: flex;
                            gap: .5rem;
                        }
                    }
                }
            }
        }

        .merkur-form-actions {
            margin-top: 24px;
            text-align: center;

            .button {
                font-size: 16px;
            }
        }

        .form-message {
            position: relative;

            .hidden-element {
                height: 75px;
                position: absolute;
                top: -75px;
                pointer-events: none;
            }
        }
    }
}

body {
    .hiddendiv, .hiddendiv.common {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
        z-index: -9999 !important;
    }
}
