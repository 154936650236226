#wrapper {
    .employee {
        background-color: $bg-light-primary;

        @include darkMode {
            background-color: $bg-dark-primary;
        }

        .employee-map-holder {
            height: 400px;
            margin: 0;

            @include mobile {
                margin: auto;
            }
        }

        .news-list-header {
            margin-top: 2.3733333333rem !important;
        }

        &.flexbox-column {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            row-gap: 60px;

            .flexbox-row {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                &.banner {
                    .banner-div {
                        width: 100%;
                        text-align: left;
                        display: flex;
                        flex-direction: row;
                        margin-top: 40px;
                        justify-content: space-between;

                        .employee-profile-picture-wrapper {
                            margin: 24px 0;
                            overflow: hidden;
                            height: 400px;
                            box-shadow: 0 6px 10px 0 rgba(14, 31, 53, 0.12), 0 12px 18px 0 rgba(14, 31, 53, 0.2), 0 20px 40px -1px rgba(14, 31, 53, 0.12);
                            border-radius: 15px;

                            .employee-profile-picture {
                                height: 100%;
                                aspect-ratio: 3.5 / 4.5;
                                width: 100%;
                                object-fit: cover;
                            }
                        }

                        .banner-text {
                            color: $white;
                            margin: auto 0;
                            width: 70%;

                            @include mobile {
                                width: 100%;
                            }

                            a.contact-card,
                            .contact-card {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                background-color: $secondary-color;
                                width: 50px;
                                min-width: 50px;
                                height: 50px;
                                border-radius: 50%;
                                text-align: center;
                                box-shadow: 15px 16px 29px -14px rgba(0, 0, 0, 0.51);
                                -webkit-box-shadow: 15px 16px 29px -14px rgba(0, 0, 0, 0.51);
                                -moz-box-shadow: 15px 16px 29px -14px rgba(0, 0, 0, 0.51);
                            }

                            .contact-card .icon {
                                padding-top: 7px;
                            }

                            .contact-card .icon,
                            .contact-card strong {
                                color: $white;
                                width: 25px;
                                height: 25px;
                            }

                            .icon-name {
                                text-align: center;
                                font-weight: normal;
                                font-size: 15px;
                            }
                        }
                    }
                }

                p {
                    margin: 0;
                }

                .flex-content-small {
                    margin-left: 10%;
                    flex-basis: 0;
                    flex-grow: 2;
                    width: 20%;


                }

                .flex-content-big {
                    flex-basis: 0;
                    flex-grow: 3;
                    text-align: left;
                    width: 70%;

                    .card {
                        background-color: $white;
                        width: 80%;
                        box-shadow: 0 6px 10px 0 rgba(14, 31, 53, 0.12), 0 12px 18px 0 rgba(14, 31, 53, 0.2), 0 20px 40px -1px rgba(14, 31, 53, 0.12);
                        padding: 20px;
                    }

                    iframe {
                        min-height: 500px;
                    }
                }
            }

            .flexbox-icon-column {
                margin-top: 40px;
                margin-left: 12%;

                @include mobile {
                    margin: 50px 25px 0 25px;
                }
            }

            .contact-card-info {
                display: flex;
                align-items: center;
            }

            .contact-card-info-text {
                margin-left: 20px;
            }

            .employee-small-text-h5 {
                color: $white;
            }

            .employee-card-container {
                margin: 3rem 0;
            }

            .employee-card-container,
            .employee-absence-card-container {

                .employee-softfact {
                    font-size: 20px;
                }

                .employee-softfact:not(:last-child) {
                    margin-bottom: 3rem;
                }


                h3.employee-softfact-title {
                    margin: 0 0 6px !important;
                    font-weight: 700;
                    font-size: 22px;
                }

                .employee-data {
                    flex-grow: 4;
                    text-align: left;
                    min-width: 200px;
                    margin-top: 0;

                    &.bold {
                        font-weight: bold;
                    }
                }

                .employee-mail {
                    color: $secondary-color;
                    font-weight: bold;
                }

                .space-out {
                    margin-bottom: 10px;
                    align-items: center;

                    .icon {
                        display: inline-flex;
                        margin-right: 1rem;
                    }
                }
            }

            .employee-absence-card-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border: 1px solid $secondary-color;
                border-radius: 5px;
                padding: 5px 10px;
                margin: 3rem -10px;

                .employee-softfact,
                h4.employee-softfact-title {
                    margin: 0 !important;
                    padding-right: 7px;
                }

                @include mobile {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            .link-liste {
                .container {
                    .grid-container {
                        padding-top: 0;
                        position: relative;

                        .card-main-title {
                            color: $primary-color;
                        }

                        .share-dropdown {
                            display: none;
                            position: absolute;
                            width: calc(100% / 4 - 17px);
                            bottom: -205px;
                            right: 6px;
                            background-color: $white;
                            color: $primary-color !important;
                            border-radius: 5px;
                            flex-direction: column;

                            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
                            overflow: hidden;
                            @include darkMode {
                                background-color: #2c373b;
                            }

                            a {
                                color: $link-color !important;
                            }

                            .dropdown-item {
                                padding: 10px;
                                display: flex;
                                align-items: center;
                                color: $link-color;
                                cursor: pointer;

                                &:focus, &:active {
                                    color: #fff;
                                    background: $link-color;
                                }

                                svg {
                                    margin-right: 10px;
                                }
                            }

                            &.is-active {
                                display: flex;
                            }

                            @include mobile {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .contact-form {
                display: none;
                margin: 16px 0;

                &.is-active {
                    display: block !important;
                }
            }
        }

        h1.thin-h,
        h2.thin-h,
        h4.thin-h,
        h5.thin-h,
        .thin-h {
            color: $white;
            text-align: left;
            font-weight: 300;
            margin-top: 50px;
            margin-left: 10px;
        }

        h5.thin-h {
            color: $white;
            font-size: 24px;
        }

        .employee-profile-picture-wrapper-mobile {
            display: none;

            @include mobile {
                display: block;
                margin: 30px auto 0 auto;
                overflow: hidden;
                box-shadow: 0 6px 10px 0 rgba(14, 31, 53, 0.12), 0 12px 18px 0 rgba(14, 31, 53, 0.2), 0 20px 40px -1px rgba(14, 31, 53, 0.12);
                border-radius: 15px;
                width: 120px;

                .employee-profile-picture {
                    aspect-ratio: 3.5 / 4.5;
                    width: 100%;
                    object-fit: cover;
                    height: 100%;
                }
            }
        }

        .employee-name-div {
            margin-left: 12%;

            @include mobile {
                margin: 25px;
            }

            .employee-name {
                text-align: left;
                color: $white;
                width: 100%;
                font-weight: bold;
                white-space: normal;
                text-overflow: ellipsis;
                overflow: hidden;

                @include mobile {
                    white-space: initial;
                }
            }
        }
    }

    .text-icon {
        height: 25px !important;
        width: 25px !important;
        margin-right: 10px;
        color: $secondary-color;
    }

    .employee-card-svg-icon {
        height: 30px;
        margin-top: 20%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .employee-text {
        font-weight: bold;
        text-align: left;
        margin: 0;
        width: 200px;
    }

    .employee-overview-flexbox-content {
        line-height: 1;
        text-align: center;
        width: 3rem;
        padding: 2rem 1rem;
        border: 3px solid $black;
        margin: 0.375rem;
        flex-grow: 1;
    }

    @include mobile {
        #content {
            width: 100vw;
            max-width: 100%;
            overflow-x: hidden;
        }

        .employee-small-text {
            font-size: 15px;
        }

        .employee-small-text-h5 {
            font-size: 12px;
        }

        .contact-card {
            padding-right: 30px;
        }

        .banner-div {
            display: contents !important;
        }

        .contact-card .icon {
            margin-left: 30px;
        }

        .employee-profile-picture-wrapper {
            display: none;
        }

        .banner {
            height: auto !important;
        }

        .flex-content-small {
            width: 100%;
        }

        .employee-name-div {
            margin-top: 0 !important;
        }

        .flex-content-small h5.thin-h {
            margin-top: 20px;
        }

        .employee-name-div + .flexbox-row,
        .banner-div {
            flex-direction: column;
        }

        .flex-content-small {
            align-items: center;
        }

        .space-out {
            height: 100%;
        }

        .flex-content-big {
            margin-left: 10%;
            width: 100%;
        }
    }

    @media only screen and (width: 768px) {
        .employee-profile-picture-wrapper {
        }

        .employee-small-text {
            font-size: 18px;
        }

        .employee-small-text-h5 {
            font-size: 15px;
        }
    }
}
