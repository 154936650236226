#wrapper {
    .moglichkeiten {

        &.primarygrey {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }
        }

        &.secondarygrey {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }
        }

        .container {
            padding: 16px 10%;

            .header {
                margin-bottom: 60px;

                .title {
                    text-align: center;
                }


                &-actions {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 20px;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 22.4px;
                    justify-content: center;

                    .icon {
                        width: 24px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        margin-left: 12px;
                        margin-right: 0;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    a:not(.button) {
                        display: inline-flex;
                        align-items: center;

                        @include mobile {
                            display: flex;
                            flex-direction: column;
                            text-align: center;
                        }

                        .icon {
                            margin-left: 12px;

                            @include mobile {
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }

            .moglichkeiten-list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                text-align: center;

                @include mobile {
                    grid-template-columns: 1fr;
                }

                &-element {
                    color: $white;

                    border-bottom: 1px solid $white;

                    &:last-child {
                        border-bottom: none;
                    }

                    &:nth-last-child(2) {
                        border-bottom: none;
                    }

                    &:nth-child(odd) {
                        border-right: 1px solid $white;
                    }

                    &:nth-child(even):not(:last-child) {
                        border-bottom: 1px solid $white;
                    }


                    @include mobile {
                        border: none !important;
                        border-top: 1px solid $white !important;

                        &:last-child {
                            border-bottom: 1px solid $white !important;
                        }
                    }

                    &-content {
                        padding: 40px 60px;

                        @include mobile {
                            padding: 40px 20px;
                        }

                        @include tablet-only {
                            padding: 40px 30px;
                        }

                        &-title {
                            line-height: 26.67px;
                            font-weight: 700;
                        }

                        &-text {
                            display: inline-flex;
                            margin-top: 10px;
                            line-height: 26px;
                            max-width: 390px;
                            margin-bottom: 40px;
                        }

                        svg {
                            margin: 40px 0 32px 0;
                            display: inline;
                            width: 80px;
                            height: 80px;
                            fill: $white;
                        }

                        img {
                            margin: 40px 0 32px 0;
                            display: inline;
                            width: 80px;
                            height: 80px;
                            fill: $white;
                        }
                    }
                }
            }
        }

        .content-header-separator {
            width: 100%;
            max-width: 1200px;
            border: 0 none;
            border-top: 1px solid $white;
            background: transparent;
        }
    }
}
