.product-packages {
    font-size: 20px;
    font-weight: 400;

    hr {
        display: block !important;
        height: 2px;
    }

    .product-package {
        display: flex;
        flex-direction: column;
    }

    .product-packages-cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 2rem;
        grid-column-gap: 2rem;

        @include mobile {
            grid-template-columns: repeat(1, 1fr);
        }

        @include tablet-only {
            grid-template-columns: repeat(2, 1fr);
        }

        .product-package {
            padding: 2rem;
            border-radius: 2rem;
            color: #000000;
            background-color: rgba(#ffffff, 0.93);

            &:hover {
                background-color: rgba(#ffffff, 1);
            }

            .title {
                font-size: 2.8rem !important;
                color: $secondary-color !important;
            }

            .product-package-headline {
                display: flex;
                justify-content: center;
                align-items: flex-end;

                span:nth-child(1) {
                    flex: 0 0 auto
                }

                span:nth-child(2) {
                    flex: 1 0 auto;
                    text-align: center;
                    line-height: 1;
                    font-size: 5rem;
                    font-weight: bold;
                }

                span:nth-child(3) {
                    flex: 0 0 auto;
                }
            }

            .product-package-body {
                flex: 1 0 auto;
            }

            .product-package-body-row {
                display: flex;
                flex-direction: row;
                padding: 0.5rem 0;
                width: 100%;
                font-weight: lighter;
            }

            .product-package-body-column {
                display: flex;
                flex-direction: row;
                font-weight: 400;

                &:nth-child(1) {
                    flex: 0 0 auto;
                    width: 1rem;
                }

                &:nth-child(2) {
                    flex: 1 1 auto !important;
                    flex-wrap: wrap;
                    display: flex;
                    flex-direction: row !important;
                    padding: 0 1rem;

                    p {
                        line-height: 1.8;
                        margin: 0;
                    }
                }

                &:nth-child(3) {
                    flex: 0 0 auto;
                    min-width: 100px;
                    padding-top: 3px;
                    padding-left: 1rem;
                }
            }
        }

        .help {
            position: relative;
            top: -0.7rem;

            &::before {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                padding: 0 0.5rem 0 0.5rem;
                width: 1.5rem;
                height: 1.5rem;
                font-size: 1rem;
                border: 1px solid #444;
                border-radius: 50%;
                cursor: help;
                content: '?';
                margin-top: -1rem;
                margin-right: -1.5rem
            }
        }

        .product-package-footer {
            display: flex;
            justify-content: center;
            padding: 2rem;

            .btn {
                width: 100%;
                border-radius: 20px !important;
            }
        }
    }
}
