#wrapper {
    .artikel-mit-banner {
        padding: 50px 0;

        a {
            color: $primary-color
        }

        .topimage {
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
        }

        .row {
            position: relative;
        }

        .aui {
            .row {
                margin-bottom: 4em;
            }
        }

        .caption1,
        .caption2 {
            font-family: var(--fontFamily, "Roboto", sans-serif);
            position: absolute;
            left: 10%;
            border-radius: 0;
            border: none;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.35);
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            text-shadow: none;
            padding: 0.2em 0.5em;
            width: auto;
            height: auto;
            text-align: center;
            z-index: 3;
        }

        .caption1 {
            font-size: 3vw;
            line-height: 0.8em;
            margin-top: 3em;
        }

        .caption2 {
            font-size: 1.5vw;
            line-height: 1.8em;
            margin-top: 10em;
        }

        .parallelogram {
            width: 50vw;
            left: 0px;
            position: absolute;
            top: 0px;
            margin-left: -40%;
            -webkit-transform: skew(134deg);
            -moz-transform: skew(134deg);
            -o-transform: skew(134deg);
            transform: skew(134deg);
        }

        .articlecontent {
            height: auto;
            margin: auto;
            max-width: 98%;
            opacity: 1;
            padding: 0;
            transform: none;
            transition: opacity 500ms cubic-bezier(0.4, 0, 1, 1) 0s;
            width: auto;
            will-change: transform;
            position: relative;
        }

        #content {
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
        }

        .aui {
            div {
                &.journal-content-article > *:not(img) {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        @media screen and (max-width: 6840px) {
            .aui .journal-content-article {
                max-width: 1920px;
                margin-left: auto;
                margin-right: auto;
            }

            .parallelogram {
                visibility: hidden;
            }

            .caption1 {
                font-size: 1.6vw;
                left: 30%;
            }

            .caption2 {
                font-size: 0.8vw;
                left: 30%;
            }
        }

        @media screen and (max-width: 4170px) {
            .aui {
                .journal-content-article {
                    max-width: 1920px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .parallelogram {
                visibility: hidden;
            }

            .caption1 {
                font-size: 1.8vw;
                left: 28%;
            }

            .caption2 {
                font-size: 0.9vw;
                left: 28%;
            }
        }

        @media screen and (max-width: 3500px) {
            .aui {
                .journal-content-article {
                    max-width: 1920px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .parallelogram {
                visibility: hidden;
            }

            .caption1 {
                font-size: 2.0vw;
                left: 20%;
            }

            .caption2 {
                font-size: 1.0vw;
                left: 20%;
            }
        }

        @media screen and (max-width: 2730px) {
            .aui {
                .journal-content-article {
                    max-width: 1920px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .parallelogram {
                visibility: hidden;
            }

            .caption1 {
                font-size: 2.4vw;
                left: 15%;
            }

            .caption2 {
                font-size: 1.2vw;
                left: 15%;
            }
        }

        @media screen and (max-width: 1920px) {
            .aui {
                .journal-content-article {
                    max-width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            .caption1 {
                font-size: 3vw;
                left: 10%;
            }

            .caption2 {
                font-size: 1.5vw;
                left: 10%;
            }
        }

        @media screen and (max-width: 1200px) {
            .parallelogram {
                width: 40vw;
                margin-left: -18%;
            }

            .aui {
                .journal-content-article {
                    max-width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            .articlecontent {
                max-width: 1200px;
                padding: 0 10px;
            }

            .caption1 {
                font-size: 3vw;
                left: 10%;
            }

            .caption2 {
                font-size: 1.5vw;
                left: 10%;
            }
        }

        @media screen and (max-width: 1024px) {
            .caption1 {
                font-size: 2.6vw;
                left: 8%;
            }

            .caption2 {
                visibility: hidden;
            }

            .aui {
                .journal-content-article {
                    max-width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            .articlecontent {
                max-width: 1024px;
                padding: 0 10px;
            }
        }

        @media screen and (max-width: 640px) {
            .caption1, .caption2 {
                visibility: hidden;
            }

            .aui {
                .journal-content-article {
                    max-width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            .articlecontent {
                max-width: 640px;
                padding: 0 10px;
            }
        }

        @media screen and (max-width: 320px) {
            .caption1, .caption2 {
                visibility: hidden;
            }

            .aui {
                .journal-content-article {
                    max-width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            .articlecontent {
                max-width: 320px;
                padding: 0 10px;
            }
        }
    }
}
