.portlet {
    .portlet-topper {
        transform: translate(0, 0) !important;
        z-index: 100;
    }

    .portlet-topper-toolbar {
        display: flex !important;


        .portlet-options {
            a {
                color: $secondary-color !important;
            }
        }
    }

    .portlet-header {
        position: absolute;
        z-index: 101 !important;
    }
}

.main-navigation {
    .portlet {


        .portlet-topper {
            transform: translate(0, -97%) !important;

        }
    }
}
