.portlet-journal-content * {
    hr {
        display: none !important;
    }

    .autofit-row.user-tool-asset-addon-entries {
        width: 100%;
        max-width: calc(1200px + 20%);
        margin: 0 auto;
        padding: 16px 10%;
        display: flex;
        flex-direction: row;
    }
}
