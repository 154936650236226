#wrapper .merkur-form {
    a:not(.button) {
        color: $link-color !important;
    }

    .formcontainer {
        background-color: #566367;
        border-radius: 10px;
        padding: 10px 30px;
        max-width: 800px;

        @include mobile {
            grid-template-columns: 1fr;
            grid-gap: 5px;
        }

        &::before,
        &::after {
            display: none !important;
        }
    }


    .radio-margin {
        &.list-style {
            display: flex;
            flex-direction: column;
        }

        &.inline-style {
            display: grid;
            flex-direction: row;
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;

            &.radio-col-2 {
                grid-template-columns: repeat(2, 1fr);
            }

            &.radio-col-3 {
                grid-template-columns: repeat(3, 1fr);
            }

            &.radio-col-4 {
                grid-template-columns: repeat(4, 1fr);
            }

            &.radio-col-5 {
                grid-template-columns: repeat(5, 1fr);
            }

            &.radio-col-6 {
                grid-template-columns: repeat(6, 1fr);
            }

            &.radio-col-12 {
                grid-template-columns: repeat(12, 1fr);
            }

            @include mobile {
                display: flex !important;
                flex-direction: column;
            }
        }
    }

    .form-text-height {
        height: 0;
    }

    &-header {
        grid-column: 1;
        grid-row: 1;

        @include mobile {
            grid-row: 1;
        }
    }

    &-info {
        grid-column: 1;
        grid-row: 2;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, auto);
        grid-column-gap: 30px;
        grid-row-gap: 60px;

        @include mobile {
            grid-row: 3;
        }

        .icon {
            width: 44px;
            height: 44px;
            padding: 10px;
            border-radius: 9999em;
            box-shadow: 0 6px 12px 0 rgba(29, 35, 24, 0.2);
            background-color: $secondary-color;
            color: $white;
        }
    }

    &-info-phone,
    &-info-fax,
    &-info-mail,
    &-info-emergency {
        display: grid;
        grid-template-columns: 44px 1fr;
        grid-template-rows: repeat(2, auto);
        grid-column-gap: 20px;
        font-size: 20px;
        color: $white !important;

        .icon {
            grid-area: 1 / 1 / 3 / 2;
        }

        strong {
            grid-area: 1 / 2 / 2 / 3;
            font-size: 14px;
        }
    }

    &-info-fax {
        grid-template-columns: 1fr;

        strong {
            grid-column: 1;
            grid-row: 1;
        }

        *:not(strong) {
            grid-row: 2;
        }
    }

    &-info-phone {
        grid-area: 1 / 1 / 2 / 2;
    }

    &-info-fax {
        grid-area: 1 / 2 / 2 / 3;
    }

    &-info-mail {
        grid-area: 2 / 1 / 3 / 3;
    }

    &-info-emergency {
        grid-area: 3 / 1 / 4 / 3;
    }

    &-info-social {
        grid-area: 4 / 1 / 5 / 3;
    }

    &-info-social {
        .icon {
            background-color: $white;
            color: $primary-color;
        }
    }

    &-social-links {
        display: flex;
        gap: 28px;
    }

    &-address {
        font-size: 20px;
    }

    .merkur-form-captcha {
        padding-top: 15px;
    }

    &-form {
        grid-column: 2;
        grid-row: 1 / span 2;
        padding: 60px;
        max-width: 800px;
        border-radius: 10px;
        background-color: $white;
        box-shadow: 0 6px 10px 0 rgba($box-shadow-color, 0.12), 0 12px 18px 0 rgba($box-shadow-color, 0.2), 0 20px 40px -1px rgba($box-shadow-color, 0.12);
        color: $primary-color;

        @include mobile {
            grid-row: 2;
        }

        .title {
            margin-top: 0;
            color: $primary-color !important;
        }

        .merkur-form-fields {

            .input-field {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 12px 16px;
                min-height: 64px;
                border-radius: 6px;
                background-color: $bg-grey;
                margin: 0;

                &.textarea-field {
                    padding: 16px;
                }
            }

            label.merkur-checkbox {
                font-weight: $weight-normal;
                color: $primary-color;
                top: 15px;
                left: 15px;

                span {
                    font-size: 14px;
                }

                a {
                    font-weight: $weight-bold;
                }
            }

            input:not([type]),
            input[type="text"]:not(.browser-default),
            input[type="password"]:not(.browser-default),
            input[type="email"]:not(.browser-default),
            input[type="url"]:not(.browser-default),
            input[type="time"]:not(.browser-default),
            input[type="date"]:not(.browser-default),
            input[type="datetime"]:not(.browser-default),
            input[type="datetime-local"]:not(.browser-default),
            input[type="tel"]:not(.browser-default),
            input[type="number"]:not(.browser-default),
            input[type="search"]:not(.browser-default),
            textarea.form-textarea {
                -webkit-appearance: none;
                height: 2.6rem;
                margin: 0;
                border: 0 none !important;
                font-size: 16px;
                color: $primary-color;
                left: -50px;
                outline: none;

                &:focus, &:focus-visible {
                    border: 0 none;
                    box-shadow: none;
                    outline: none !important;
                    color: $secondary-color;
                }

                &.is-danger + label.merkur-label {
                    color: $error-color;
                }
            }

            textarea.materialize-textarea {
                &,
                &:focus {
                    margin-top: 13px !important;
                }
            }

            input[type="file"]:not(.browser-default) {
                height: auto;

                &,
                &:focus,
                &::selection {
                    color: transparent;
                }
            }

            input[type="radio"]:not(.browser-default) {
                &.is-danger + span {
                    color: $error-color;
                }
            }

            .control-label {
                &.is-danger {
                    color: $error-color;
                }
            }

            margin-bottom: 10px;

            .merkur-file-field {
                display: flex;
                gap: .5rem;
                flex-direction: column;

                .merkur-files {
                    .merkur-files-list {
                        margin: 0;
                        display: flex;
                        gap: .5rem;
                        flex-direction: column;

                        .merkur-file-list-entry {
                            display: flex;
                            gap: .5rem;
                        }
                    }
                }
            }
        }

        .merkur-form-actions {
            margin-top: 24px;
            text-align: center;

            .button {
                font-size: 16px;
            }
        }
    }


    .form-submit-message {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.12s ease-in-out;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(86, 99, 103, 0.8);
            backdrop-filter: blur(12px);
            z-index: 12;

            @include darkMode {
                background: rgba(19, 21, 22, 0.6);;
            }
        }

        .close-form-submit {
            position: absolute;
            z-index: 12;
            cursor: pointer;

            svg path {
                fill-opacity: 1 !important;
                fill: $secondary-color !important;
            }

            @include mobile {
                top: 54px;
                right: 20px;
                width: 24px;
                height: 24px;
            }
            @include tablet {
                top: 54px;
                right: 20px;
            }
            @include desktop {
                width: 36px;
                height: 36px;
                top: 60px;
                right: 60px;
            }
        }

        .form-submit-container {
            z-index: 13;
            position: absolute;
            max-height: 320px;
            max-width: 900px;
            overflow: hidden;
            overflow-y: auto;

            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            .message {
                display: block;
                color: #566367;
                min-width: 320px;
                font-size: 20px;
                line-height: 2.2rem;
                padding: 2rem;
                background: #fff;
                border-radius: 10px;
                border: 1px solid transparent;
                text-align: center;

                @include mobile {
                    font-size: 20px;
                }

                .merkur-happy {
                    position: static;

                    display: block;
                    color: $secondary-color;


                    margin-bottom: 3rem;
                    @include mobile {
                        margin-bottom: 2rem;
                    }

                    svg {
                        width: 100px;
                        height: auto;
                    }
                }

                p {
                    margin-bottom: 3rem;
                    @include mobile {
                        margin-bottom: 2rem;
                    }
                }

                .button {
                    margin-bottom: 0;
                }
            }
        }
    }

    .form-message {
        position: relative;

        .hidden-element {
            height: 75px;
            position: absolute;
            top: -75px;
            pointer-events: none;
        }
    }
}

body {
    .hiddendiv, .hiddendiv.common {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
        z-index: -9999 !important;
    }

    &.signed-in.has-control-menu.controls-visible.has-staging-bar {


        #wrapper {
            overflow-y: scroll;

            .form-submit-message {
                top: 222px;
            }
        }
    }

    &.active-form-submit {
        .page-content {
            z-index: 10;
        }

        #wrapper {
            overflow-y: scroll;

            .form-submit-message {
                z-index: 100;
                opacity: 1;
                visibility: visible;
            }
        }

    }
}
