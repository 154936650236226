
body.signed-in,
body.sitepage-login,
body.sitepage-beratungstermin-vereinbaren {
    .consultant-cookie-info {
        display: none;
    }
}

body.signed-in.show-dockbar {
    #wrapper {
        .consultant-cookie-info {
            top: 225px !important;
        }
    }
}

#wrapper {
    .consultant-cookie-info {
        display: block;
        position: fixed;
        z-index: 99;
        top: 85px;
        right: 40px;
        text-align: center;
        margin: 0;
        padding: 10px 20px;
        overflow: hidden;
        width: 300px;
        background: transparent;
        border: 2px solid transparent;
        border-radius: 10px;
        box-shadow: none;

        @include mobile {
            display: none;
            z-index: -99;
        }

        * {
            font-family: Boston, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
        }


        &-top {
            position: relative;
            margin: 0 auto;
            padding: 0;

            div:not(.image) {
                display: none;
            }

            div {
                font-size: 16px;
                color: $secondary-color;

                &:not(:last-child) {
                    /* margin-bottom: 3px; */
                }
            }

            &-full-name {
                font-weight: 700;
            }

            .image {
                position: relative;
                text-indent: -999em;
                width: 130px;
                height: auto;
                margin: 0 auto 10px auto !important;
                border-radius: 10px;
                background-repeat: no-repeat;
                background-size: cover;
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
                cursor: pointer;
                transform: scale(1);
                transition: transform 0.12s ease-in-out;

                &:hover {
                    transform: scale(1.05);
                }
            }

            &:after {
                content: '';
                height: 2px;
                width: calc(100% - 20px);
                bottom: 0;
                left: 10px;
                background: #ddd;
                position: absolute;
                visibility: hidden;
                opacity: 0;
            }
        }

        &-bottom {
            display: none;
            text-align: left;
            padding: 20px 0 0 0;
            margin-bottom: 20px;

            .information {
                display: grid;
                grid-template-columns: 40px 1fr;
                color: $grey-dark;

                @include darkMode {
                    color: #fff;
                }

                &:not(:last-child) {
                    margin-bottom: 7px;
                }

                .icon-container {
                    display: flex;
                    align-items: center;

                    svg {
                        width: 30px;
                        height: 36px;
                        color: $secondary-color;
                    }
                }

                .text-container {
                    span {
                        display: block;
                        line-height: 1.22857;

                        &.info-label {
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .button {
            display: none;
            padding: 6px 20px;
        }

        &.is-active,
        &.was-clicked {
            background: #fff;
            border-color: #fff;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

            @include darkMode {
                background-color: $bg-dark-cards;
            }

            .consultant-cookie-info-top {
                padding: 0 0 10px 0;

                div {
                    &.image {
                        box-shadow: none;

                        &:hover {
                            transform: none;
                        }
                    }
                }
            }

            .consultant-cookie-info-top:after {
                visibility: visible;
                opacity: 1;
            }

            .consultant-cookie-info-top div:not(.image),
            .consultant-cookie-info-bottom {
                display: block;
            }

            .button {
                display: inline-block;
            }
        }
    }
}
