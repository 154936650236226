#wrapper {
    .dashboard-cards {
        display: flex;
        flex-direction: row;
        background-color: $bg-light-primary;

        @include darkMode {
            background-color: $bg-dark-primary;
        }

        @include mobile {
            grid-template-columns: repeat(2, 1fr);
            width: 100%;
            padding: 50px 0;
        }

        @include tablet {
            grid-template-columns: repeat(3, 1fr);
        }

        @include desktop {
            grid-template-columns: repeat(3, 1fr);
        }

        .container {
            padding: 16px 10%;
        }

        .row {
            display: flex;
            flex-wrap: wrap;
            flex: 1 1 auto;
            max-width: 1200px;
            margin: 0 -10px;
            padding-right: 0;
            padding-left: 0;

            /* liferay drop-zone - edit mode */
            &.has-edit-mode {

                > lfr-drop-zone > div > div > div {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);

                    > div,
                    > div > div,
                    > div > div > div,
                    > div > div > div > div,
                    > div > div > div > div > div,
                    > div > div > div > div > div > div {
                        display: flex;
                        width: 100%;
                    }

                    @include mobile {
                        grid-template-columns: repeat(1, 1fr);
                    }
                }
            }

            .col {
                margin-right: auto;
                padding: 15px;
                flex-basis: unset;
                flex-grow: unset;

                @include mobile {
                    padding: 10px;
                }
            }
        }

        /* liferay drop-zone - view mode */
        &.is-fragment {
            .row {
                display: block;

                > div {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);

                    > div,
                    > div > div,
                    > div > div > div {
                        display: flex;
                        width: 100%;
                    }

                    @include mobile {
                        grid-template-columns: repeat(1, 1fr);
                    }
                }
            }
        }

        .card-link-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            .card {
                z-index: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 64px 0 64px;
                border-radius: 1rem;

                @include mobile {
                    height: 250px;
                    padding: 10px 0;
                }

                @include tablet {
                    padding: 40px 0 30px;
                }

                @include desktop {
                    padding: 64px 0 64px;
                }

                .card-image {
                    flex: 0 0 auto;
                    margin: 1rem 0 0;
                    width: 86px;
                    height: 86px;

                    @include mobile {
                        height: 100%;
                        max-height: 40%;
                    }

                    svg {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;

                        @include mobile {
                            max-height: 70px;
                        }

                        @include tablet {
                            max-height: 86px;
                        }

                        @include desktop {
                            max-height: 86px;
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .card-content {
                    padding: 16px 16px 0;
                    overflow: hidden;
                    line-height: 1rem;

                    @include mobile {
                        height: 100%;
                        padding: 0 14px;
                    }
                }

                .card-title {
                    display: -webkit-box; // stylelint-disable-line
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical; // stylelint-disable-line
                    max-height: 100%;
                    margin-bottom: 0;
                    overflow: hidden;
                    font-weight: 700;
                    color: $accent;
                    line-break: normal !important; // stylelint-disable-line
                    text-align: center;
                    text-overflow: unset !important; // stylelint-disable-line
                    word-break: break-word;
                    white-space: normal !important; // stylelint-disable-line
                    hyphens: auto;
                    line-height: 1.3em;

                    @include mobile {
                        -webkit-line-clamp: 3;
                    }
                }
            }
        }

        .merkur-happy-container {
            position: absolute;
            right: 0;
            bottom: 160px;
        }
    }
}
