.videos {
    .video,
    .iframe-video {
        display: block;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;
        width: 100%;
        padding-top: 75%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

