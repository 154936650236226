.articlecontent {
    &.flexlayout {
        height: auto;
        margin: auto;
        width: auto;
        max-width: 1920px;
        opacity: 1;
        padding: 0 10px;
        transform: none;
        transition: opacity 500ms cubic-bezier(0.4, 0, 1, 1) 0s;
        will-change: transform;
        position: relative;
        margin-bottom: 3em;

        @include screen-xl {
            padding: 0;
        }

        @include screen-xxl {
            padding: 0;
        }

        a,
        a:hover,
        a:focus {
            color: rgba(0, 0, 0, 0.87);
            text-decoration: none;
            width: 100%;
            height: 100%;
        }

        .waves-effect {
            display: inherit;
        }

        .card {
            overflow: hidden;
            width: 100%;
            height: 100% !important;
            margin: 10px;
            border: none;

            @include darkMode {
                background-color: $bg-dark-secondary !important;
            }

            .card-title {
                font-weight: bold !important;
                color: $primary-color;

                @include darkMode {
                    color: $white !important;
                }
            }

            p {
                font-size: $size-5;
                color: $primary-color !important;

                @include darkMode {
                    color: $white !important;
                }
            }

            .card-reveal {
                width: auto !important;
                margin-top: 1em;
            }

            .card-content {
                overflow-y: hidden;
                max-height: 45%;
                @include screen-sm {
                    max-height: 50%;
                }

                @include darkMode {
                    background-color: $bg-dark-secondary;
                    color: $white;
                }
            }
        }

        .header2 {
            color: rgba(0, 0, 0, 0.54);
        }

        .grid-item {
            width: 100%;
            height: 120px;
            margin-bottom: 20px;
        }

        .grid-item--gigante {
            width: 320px;
            height: 360px;
        }

        .grid-item--heightSmall {
            height: 320px;
        }

        .grid-item--heightMedium {
            height: 420px;
        }

        .grid-item--heightLarge {
            height: 520px;
        }
    }
}

