#wrapper {
    [id*='fragment'] {
        .text-two-columns {
            .text-two-columns-content {
                column-count: 1 !important;
                column-gap: 0 !important;
            }
        }
    }

    .text-two-columns {
        &:not(.is-secondary) {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }

            &:after {
                background-color: $bg-light-secondary;

                @include darkMode {
                    background-color: $bg-dark-secondary;
                }
            }
        }

        &.is-secondary {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }

            &:after {
                background-color: $bg-light-primary;

                @include darkMode {
                    background-color: $bg-dark-primary;
                }
            }
        }

        .container {
            padding: 16px 10%;

            .text-two-columns-title {
                color: $white;
                font-size: 36px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 37.8px;
                margin-bottom: 60px;
            }

            .text-two-columns-subtitle {
                color: $white;
                font-size: 20px;
                letter-spacing: 0;
                line-height: 28px;
                margin-top: 10px;
            }

            .text-two-columns-content {
                color: $white;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 22px;
                column-count: 2;
                column-gap: 40px;
                -moz-column-count: 2;
                -moz-column-gap: 40px;
                -webkit-column-count: 2;
                -webkit-column-gap: 40px;

                @include mobile {
                    column-count: 1;
                    -moz-column-count: 1;
                    -webkit-column-count: 1;
                }

                @include tablet-only {
                    column-count: 1;
                    -moz-column-count: 1;
                    -webkit-column-count: 1;
                }
            }
        }

        .separator-solid {
            width: 100%;
            max-width: 1200px;
            border: 0 none;
            border-top: 1px solid $white;
            background: transparent;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
