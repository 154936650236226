#wrapper {

    .consultants-search {
        @include mobile {
            padding: 0 !important;
        }

        .map-container {
            position: relative;

            .vue-map-container {
                .vue-map {
                    height: 800px;

                    @include mobile {
                        height: 50%;
                    }

                    .cluster > div {
                        background-color: $secondary-color;
                        border-radius: 50%;
                        width: 26px !important;
                        height: 26px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: inset 0 0 5px 2px $theme-olive;
                    }
                }
            }


            .consultants-search-container {
                position: absolute;
                z-index: 50;
                width: 336px;
                top: 80px;
                left: 10px;

                @include mobile {
                    position: inherit;
                    width: 100%;
                    padding-right: 10%;
                    padding-left: 10%;
                }

                .consultants-search {
                    border-radius: 10px;
                    background-color: #FFFFFF;
                    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
                    height: 140px;
                    margin-bottom: 16px;
                    padding: 24px;

                    @include mobile {
                        height: auto;
                        padding: 16px 16px 0 16px !important;
                    }

                    @keyframes spinner {
                        to {
                            transform: rotate(360deg);
                        }
                    }


                    .search-input-field {
                        margin-bottom: 10px;
                        position: relative;

                        @include mobile {
                            margin: 0;
                        }

                        .btn-search-cancel {
                            display: none;
                        }

                        input {
                            margin-bottom: 0;
                            height: 64px;
                            border-radius: 6px;
                            background-color: #EEEFEF;
                            border: none;
                            padding: 24px 70px 0 16px;
                            color: #566367;
                            font-family: Boston;
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 22px;

                            white-space: nowrap;
                            word-break: keep-all;
                            text-overflow: ellipsis;
                            box-sizing: border-box;

                            &:focus + label {
                                color: $secondary-color;
                            }


                            &.is-danger, &.is-danger:focus {
                                box-shadow: 0 1px 0 0 $error-color;

                                + label {
                                    color: $error-color;
                                }
                            }
                        }

                        label {
                            position: absolute;
                            color: #566367;
                            font-family: Boston;
                            font-size: 12px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 28px;
                            top: 8px;
                            left: 16px;
                        }

                        .input-icons {
                            position: absolute;
                            right: 10px;
                            display: grid;
                            grid-template-columns: 26px 13px 24px;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            align-items: center;

                            .icons-separator {
                                height: 24px;
                                text-align: center;
                                font-size: 24px;
                                display: table;
                                color: $bg-light-secondary;
                            }

                            .search-icon {
                                cursor: pointer;
                                color: $secondary-color;
                            }

                            .clear-icon-wrapper {
                                height: 24px;
                                cursor: pointer;

                                .clear-icon {
                                    color: $bg-light-secondary;
                                }

                                .input-spinner:before {
                                    content: '';
                                    box-sizing: border-box;
                                    position: absolute;
                                    top: 50%;
                                    left: 85%;
                                    width: 20px;
                                    height: 20px;
                                    margin-top: -10px;
                                    margin-left: -10px;
                                    border-radius: 50%;
                                    border-top: 2px solid $secondary-color;
                                    border-right: 2px solid transparent;
                                    animation: spinner .8s linear infinite;
                                }
                            }
                        }
                    }

                    .consultants-search-nearby {
                        color: $secondary-color;
                        font-family: Boston;
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 22.4px;
                        cursor: pointer;

                        @include mobile {
                            padding: 16px;
                        }
                    }
                }

                .consultants-cards {
                    max-width: 1440px;
                    max-height: 380px;
                    overflow-y: scroll;
                    scrollbar-color: $secondary-color $white;
                    overflow-x: hidden;
                    background-color: $white;
                    display: flex;
                    flex-direction: column;
                    color: $primary-color;
                    border-radius: 10px;
                    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

                    .info-msg {
                        text-align: center;
                        padding: 15px;
                    }

                    .consultant-card-wrapper {
                        border-bottom: 1px solid #CCD0D1;

                        .consultant-email-phone {
                            overflow: hidden;

                            .consultant-email,
                            .consultant-phone {
                                color: $secondary-color;
                                font-family: Boston;
                                font-size: 16px;
                                font-weight: bold;
                                letter-spacing: 0;
                                line-height: 22px;
                                word-wrap: break-word;
                            }
                        }

                        .consultant-card,
                        .consultant-card-additional {
                            height: auto;
                            padding: 8px 15px 0 24px;
                            display: grid;
                            grid-template-columns: 80px 1fr 24px;
                            color: $primary-color;
                            align-items: center;

                            @include mobile {
                                padding: 8px 10px 8px 14px;
                            }

                            .consultant-information {
                                overflow: hidden;

                                .consultant-name {
                                    font-family: Boston;
                                    font-size: 20px;
                                    font-weight: bold;
                                    letter-spacing: 0;
                                    line-height: 22px;
                                }

                                .consultant-location {
                                    font-family: Boston;
                                    font-size: 14px;
                                    letter-spacing: 0;
                                    line-height: 18.2px;
                                }
                            }

                            img {
                                border-radius: 35px;
                                width: 64px;
                                height: 64px;
                                margin-right: 16px;
                                object-fit: cover;
                            }

                            .icon-container {
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;

                                .icon {
                                    align-items: center;
                                    transform: rotate(90deg);
                                }

                                .rotated {
                                    transform: rotate(270deg);
                                }
                            }

                        }

                        .consultant-card-additional {
                            padding: 0 15px 15px 24px;
                        }

                        .active {
                            height: auto;
                        }
                    }
                }
            }

        }
    }
}
