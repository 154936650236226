#portlet_com_liferay_login_web_portlet_LoginPortlet {
    margin: 0 auto;
    width: 100%;
    max-width: 420px;
    padding: 52px;
    border-radius: 10px;
    background-color: #ffffff;

    .portlet-icon-back {
        display: none;
    }
}
