$control-radius: $radius !default;
$control-radius-small: $radius-small !default;

$control-border-width: 1px !default;

$control-height: 2.5em !default;
$control-line-height: 1.5 !default;

$control-padding-vertical: calc(0.5em - #{$control-border-width}) !default;
$control-padding-horizontal: calc(0.75em - #{$control-border-width}) !default;

@mixin control {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    height: $control-height;
    padding: $control-padding-vertical $control-padding-horizontal;
    border: $control-border-width solid transparent;
    border-radius: $control-radius;
    box-shadow: none;
    font-size: 1.125rem;
    line-height: $control-line-height;
    vertical-align: top;
    -moz-appearance: none; // stylelint-disable-line property-no-vendor-prefix
    -webkit-appearance: none; // stylelint-disable-line property-no-vendor-prefix

    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
        outline: none;
    }

    &[disabled],
    fieldset[disabled] & {
        cursor: not-allowed;
    }
}

%control {
    @include control;
}

// The controls sizes use mixins so they can be used at different breakpoints
@mixin control-small {
    border-radius: $control-radius-small;
    font-size: $size-small;
}

@mixin control-medium {
    font-size: $size-medium;
}

@mixin control-large {
    font-size: $size-large;
}
