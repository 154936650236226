#wrapper {

    .text-tabs-grid {

        &.is-primary {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }
        }

        &.is-secondary {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }
        }

        .container {
            position: relative;
            z-index: 2;
        }

        .text-tabs-container-header {
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 20px;
        }

        .text-tabs-buttons {
            display: flex;
            margin-bottom: 1.5em;

            gap: 1em;
            flex-wrap: wrap;

            .button {
                white-space: nowrap;
                margin-bottom: 0;

                &.is-active {
                    background: $white !important;
                    color: $secondary-color !important;
                }
            }
        }

        .text-tabs-text {
            display: none !important;

            :last-child {
                margin-bottom: 0 !important;
            }

            &.is-active {
                display: block !important;
            }

            ul, ol {
                padding-left: 2rem;
            }

            ul {

                list-style-position: outside;

                li {
                    list-style-type: disc;
                }
            }
        }

        .link-button-container {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;

            margin: 30px 0 40px 0;
            @include mobile {
                margin: 20px 0 30px 0;
            }
            font-weight: 700;

            position: relative;
            z-index: 2;

            .link-button {
                display: inline-flex;
                align-items: center;
                font-size: 20px;

                .icon {
                    width: 30px;
                    height: 30px;
                    flex-shrink: 0;
                    margin-right: 10px;
                }
            }
        }
    }
}

