#wrapper {

    .hanse-container {

        &.primarygrey {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }
        }

        &.secondarygrey {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }
        }

        .hanse-action-list {
            display: flex;
            gap: 1rem;

            @include mobile {
                flex-direction: column;
            }
        }

        .action-link {
            .icon {
                padding: 5px;
                background: $secondary-color;
                color: white;
                border-radius: 100%;
            }
        }


    }
}
