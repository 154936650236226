body.sitepage-search {
    .toggle-search {
        visibility: hidden;
        opacity: 0;
    }
}

#wrapper .page-content .container.search-container {
    max-width: calc(907px + 20%);
}

#wrapper {
    #app-search {
        @include darkMode {

        }

        .form-container {
            margin-bottom: 70px;
            padding: 70px 0 0 0;

            .results {
                min-height: 28px;
            }
        }

        #search-document-types {
            &.hide-document-types {
                ul {
                    display: none;
                }
            }

            label {
                display: flex;
                align-items: center;
                font-size: 14px !important;
            }

            input[type='checkbox'] {
                left: -10px;
                opacity: 1 !important;
            }

            .checkbox {
                display: inline-block;
                width: auto;
            }
        }
    }
}

#wrapper .search-container,
#wrapper .quick-search-container {
    * {
        box-sizing: border-box !important;
    }


    .search-form {
        position: relative;
        margin-bottom: 42px;

        @include mobile {
            margin-bottom: 20px;
            font-size: 1.4em;
        }

        @include tablet {
            font-size: 2em;
        }

        .form-group {
            margin: 0;
        }

        .search-input {
            display: block;
            color: $primary-color;
            font-size: 32px;
            height: 82px;
            line-height: 0;
            padding: 0 5em 0 1.1em;
            background: #fff;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            border-color: transparent;
            margin: 0;
            white-space: nowrap;
            word-break: keep-all;
            text-overflow: ellipsis;
            font-family: $family-sans-serif;

            &[disabled="disabled"] {
                cursor: wait;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                background: #fff !important;
                -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
            }

            &,
            &:focus {
                outline: none;
            }

            &:focus {
                caret-color: #56636780;
            }

            @include darkMode {
                color: #fff;
                background: $bg-dark-secondary;
            }

            @include mobile {
                height: 55px;
                font-size: 24px;
                padding: 0 4em 0 1.1em;
            }

            @include placeholder {
                color: #56636780;
                font-weight: 300;
                font-size: 20px;

                font-family: $family-sans-serif;
                @include darkMode {
                    color: #FFFFFF80;
                }
            }

            &.is-danger {
                box-shadow: 0 1px 0 0 $error-color;
                @include placeholder {
                    color: $error-color !important;
                }
            }
        }

        .icons {
            position: absolute;
            top: calc(50% - 21.5px);
            right: 1em;
            display: grid;
            grid-template-columns: 1fr 1fr;
            flex-direction: row;
            align-items: center;
            height: 42px;
            gap: 31px;
            visibility: visible;
            opacity: 1;

            @include mobile {
                width: 31px;
                right: 2.2em;
                gap: 20px;
            }

            .loupe {
                width: 28px;
                cursor: pointer;

                svg {
                    width: 28px;
                    height: 28px;
                }

                @include mobile {
                    width: 20.22px;
                    svg {
                        width: 20.22px;
                        height: 20.22px;
                    }
                }

                &[disabled="disabled"] {
                    cursor: wait;
                }

                svg path {
                    fill: $bg-light-secondary;
                    transition: all 0.12s ease-in-out;

                    @include darkMode {
                        fill: rgba(255, 255, 255, 0.5);
                    }
                }

                &:hover svg path {
                    fill: $secondary-color;
                    @include darkMode {
                        fill: rgba(255, 255, 255, 0.8);
                    }
                }
            }

            .reset {
                width: 26px;
                height: 26px;
                cursor: pointer;
                position: relative;


                &[disabled="disabled"] {
                    cursor: wait;
                }

                @include mobile {
                    width: 19px;
                }

                svg {
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                }

                svg path {
                    stroke: $bg-light-secondary;
                    transition: all 0.12s ease-in-out;
                    @include darkMode {
                        stroke: rgba(255, 255, 255, 0.5);
                    }
                }

                &:hover svg path {
                    stroke: $secondary-color;
                    @include darkMode {
                        stroke: rgba(255, 255, 255, 0.8);
                    }
                }
            }

            &:after {
                display: block;
                content: '';
                position: absolute;
                left: calc(50% - 0.5px);
                width: 1px;
                height: 100%;
                background: #BBC1C2;

                @include mobile {
                    left: calc(100%);
                }
                @include darkMode {
                    background: rgba(255, 255, 255, 0.5);
                }
            }
        }

        .input-spinner {
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 22px;
            height: 22px;
            margin-top: -11px;
            margin-left: -11px;
            border-radius: 50%;
            border-top: 2px solid $secondary-color;
            border-right: 2px solid transparent;
            animation: spinner .8s linear infinite;

            opacity: 0;
            display: none;
            @include mobile {
                width: 19px;
                height: 19px;
            }

        }

        &.is-loading {
            .icons .reset svg {
                opacity: 0;
                visibility: hidden;
                display: none;
            }

            .input-spinner {
                opacity: 1;
                visibility: visible;
                display: block;
            }
        }
    }
}

#wrapper .search-container {

    .search-empty {
        text-align: center;
        max-width: 340px;
        margin: 0 auto;

        .empty {
            &-heading,
            &-text {
                display: block;
            }

            &-heading {
                font-size: 2em;
                line-height: 1.8;
                margin-top: 0;
            }

            &-text {
                line-height: 1.6;
                margin-bottom: 0;
            }
        }
    }

    .results {

        .search-facets {
            @include mobile {
                margin-bottom: 20px;
            }

            .facet-group:not(:first-child) {
                display: none;
            }

            .facet-items {
                display: flex;
                padding: 0;
                margin: 0;
                gap: 1.3em;
                flex-wrap: wrap;

                .facet-item {
                    margin: 0;
                    padding: 0;
                    display: block;
                    color: rgba(255, 255, 255, 0.5);
                    font-size: 14px;
                    border-bottom: 3px solid transparent;
                    transition: opacity 0.12s ease-in-out;
                    background: none;

                    .name {
                        line-height: 19px;
                        height: 25px;
                        display: block;
                    }

                    &.is-active {
                        border-bottom-color: $secondary-color;

                    }

                    &.is-active,
                    &:hover {
                        color: #fff;
                    }

                    .quiet {
                        color: inherit !important;
                    }
                }
            }
        }

        .search-results {
            @include clearfix;

            .search-result {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                background: #fff;
                @include darkMode {
                    background: $bg-dark-secondary;
                }
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                overflow: hidden;

                .full-result {
                    display: block;
                    padding-left: 30px;
                    padding-right: 30px;
                }

                &.has-image,
                &.has-video {
                    .full-result {
                        display: grid;
                        grid-template-columns: 1fr 2fr;
                        column-gap: 30px;
                    }

                    @include mobile {
                        grid-template-columns: unset;
                    }

                    .result-preview {
                        display: block;
                        padding: 20px 0;
                        max-width: 23.548%;
                        max-width: 20.184em;
                        max-width: 282.58px;
                        width: 100%;
                        object-fit: contain;
                        @include mobile {
                            max-width: 100%;
                        }


                        .result-video,
                        .result-image {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                            border-radius: 10px;
                        }

                        .result-image {
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-size: cover;
                        }

                        img,
                        iframe {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .result-content {
                        .result-description {
                            @include mobile {
                                margin: 0;
                            }
                        }

                        .result-button {
                            @include mobile {
                                position: absolute;
                                z-index: 1;
                                top: -20px;
                                right: 30px;
                                svg {
                                    width: 40px;
                                    height: 40px;
                                }
                            }

                            span {
                                @include mobile {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .result-content {
                    padding: 20px 30px 20px 0;
                    position: relative;
                    display: grid;
                    gap: 10px;

                    .title-bar {
                        .choose {
                            display: none;
                        }
                    }

                    .title {
                        color: $primary-color;
                        margin: 0;
                        @include darkMode {
                            color: #fff;
                        }
                    }

                    .result-description {
                        color: $primary-color;
                        font-size: 16px;
                        margin: 0;

                        p:last-child {
                            margin-bottom: 0;
                        }

                        @include darkMode {
                            color: rgba(255, 255, 255, 0.5);
                        }
                    }

                    .result-button {
                        color: $secondary-color;
                        font-size: 16px;
                        margin-bottom: 0;
                        flex-wrap: wrap;
                        gap: 8px;
                        font-weight: 700;
                        display: inline-flex;
                        align-items: center;

                        .more {
                            display: inline-block;
                            border-radius: 50%;
                            width: 16px;
                            height: 16px;
                            background-color: $secondary-color;
                            color: $white;
                            text-align: center;
                            vertical-align: top;
                            cursor: pointer;
                            position: relative;

                            .icon {
                                width: 8px;
                                height: 8px;
                                flex-shrink: 0;
                                transition: opacity 0.12s ease-in-out;
                                position: absolute;
                                top: calc(50% - 4px);
                                left: calc(50% - 4px);
                            }
                        }
                    }
                }
            }
        }
    }
}

#wrapper .quick-search {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.12s ease-in-out;


    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(86, 99, 103, 0.8);
        backdrop-filter: blur(12px);
        z-index: 12;

        @include darkMode {
            background: rgba(19, 21, 22, 0.6);;
        }
    }

    .close-quick-search {
        position: absolute;
        z-index: 12;
        cursor: pointer;

        body.has-edit-mode-menu &,
        body.staging-ready.has-staging-bar & {
            top: 160px;
        }

        svg path {
            fill-opacity: 1 !important;
            fill: $secondary-color !important;
        }

        @include mobile {
            top: 54px;
            right: 20px;
            width: 24px;
            height: 24px;
        }
        @include tablet {
            top: 54px;
            right: 20px;
        }
        @include desktop {
            width: 36px;
            height: 36px;
            top: 60px;
            right: 60px;
        }
    }

    .quick-search-container {
        z-index: 13;
        position: absolute;
        max-height: 514px;
        overflow: hidden;

        @include mobile {
            max-width: 100%;
            top: 98px;
            right: 20px;
            left: 20px;
        }

        @include tablet {
            max-width: 100%;
            top: 100px;
            right: 20px;
            left: 20px;
        }

        @include desktop {
            max-width: calc(907px);
            left: calc(50% - 453.5px);
            top: 200px;
        }
        @include smaller-screen {
            top: 120px;
        }

        .search-form {
            margin-bottom: 0;

            .search-input {
                white-space: nowrap;
                word-break: keep-all;
                text-overflow: ellipsis;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom-width: 2px;
                border-bottom-color: rgba(86, 99, 103, 0.2);
                box-shadow: none;
            }
        }

        .search-results {
            background: #fff;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 0 15px;

            .category {
                padding: 20px 0;

                + .category {
                    padding-top: 0;
                }
            }

            .category-title {
                font-size: 12px;
                line-height: 1.334em;
                font-weight: 700;
                color: #566367;
                padding: 0 20px;
                margin-bottom: 5px;

                @include darkMode {
                    color: #fff;
                }
            }

            @include darkMode {
                color: #fff;
                background: $bg-dark-secondary;
            }

            .result-content {
                grid-template-columns: 10fr 2fr;
            }

            .search-result {
                transition: all 0.12s ease-in-out;
                border-radius: 6px;
                cursor: pointer;
                display: grid;
                grid-template-columns: 12fr auto;
                align-items: center;
                padding: 10px 20px;

                .title {
                    display: block;
                    margin: 0;
                    padding: 0;
                    width: calc(100% - 10px);
                    color: $secondary-color;
                    font-weight: 400;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-size: 16px;
                    line-height: 1.313em;
                }

                .choose {
                    display: grid;
                    grid-template-columns: auto auto;
                    gap: 8px;
                    align-items: center;
                    visibility: hidden;
                    opacity: 0;
                    transition: all 0.12s ease-in-out;
                    color: rgba(86, 99, 103, 0.5);

                    span {
                        color: #56636780;
                        @include darkMode {
                            color: #56636780;
                        }
                    }

                    svg path {
                        fill: #56636780;
                        @include darkMode {
                            fill: #56636780;
                        }
                    }
                }

                &:hover, &:focus, &.is-active {
                    background: rgba(86, 99, 103, 0.08);

                    @include darkMode {
                        background: rgba(255, 255, 255, 0.08);
                    }

                    .choose {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }

            .more-results {
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 8px;
                align-items: center;
                padding: 0 20px 20px 20px;
                font-size: 16px;
                font-weight: 700;
                line-height: 21px;
                color: $secondary-color;
            }
        }

        .search-empty {
            background: #fff;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            color: $primary-color;
            padding: 2.5em;

            h1 {
                color: inherit;
                margin-top: 0;
                margin-bottom: 0;

                @include mobile {
                    font-size: 20px;
                }
            }

            p {
                margin: 0;
                padding: 0;

                @include mobile {
                    font-size: 14px;
                }
            }

            @include mobile {
                padding: 1.8em;
            }
            @include darkMode {
                color: #fff;
                background: $bg-dark-secondary;
            }
        }
    }
}


body.sitepage-search {
    #quicksearch {
        display: none;
    }
}

body.active-quick-search {
    .page-content {
        z-index: 10;
    }

    #wrapper {
        overflow-y: scroll;

        .quick-search {
            z-index: 100;
            opacity: 1;
            visibility: visible;
        }
    }
}

body.has-edit-mode-menu,
body.signed-in.has-control-menu,
body.signed-in.has-staging-bar {

    #wrapper {
        .close-quick-search {
            top: 160px;
            @include smaller-screen {
                top: 160px;
            }


            @include mobile {
                top: 165px;
            }
            @include tablet {
                top: 160px;

            }

        }

        .quick-search-container {

            @include smaller-screen {
                top: 200px;
            }
        }
    }
}

.the-end {
    .search-spinner {
        padding: 20px;
    }
}
