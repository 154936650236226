#wrapper {
    .video {
        &.primarygrey {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }
        }

        &.secondarygrey {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }
        }

        .container {
            padding: 16px 10%;

            .card {
                margin: 0;
            }
        }

        .header {
            margin-bottom: 60px;

            .title {
                text-align: left;
            }
        }

        .video-container {
            position: relative;
            background: transparent;
            border: none;
            box-shadow: none;

            &.video-visible {
                .iframe_video {
                    display: block !important;
                }

                .video-button-container {
                    display: none !important;
                }
            }

            &.fitToContent {

                .play-button-beschriftung {
                    top: 30% !important;
                }
            }

            .platzhalter-bild {
                margin: auto;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                border-radius: 10px;
                width: 100%;
                height: 100%;
            }

            .video-button-container {
                position: absolute;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                z-index: 123;

                .video-helper {
                }

                // stylelint-disable-line

                .video-icon {
                    width: 20vw;
                    height: 20vw;
                    border: 3px solid $white;
                    border-radius: 50%;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    position: relative;
                    max-width: 300px;
                    max-height: 300px;

                    .icon-play {
                        color: $white;
                        margin-top: 25%;
                        margin-left: 25%;
                        height: 10vw;
                        width: 10vw;
                        max-height: 150px;
                        max-width: 150px;
                    }
                }

                .play-button-beschriftung {
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    font-size: 26px;
                    text-align: center;
                    margin-top: 20px;
                    position: relative;
                }
            }

            iframe {
                display: none;
            }
        }

        .content-header-separator {
            width: 100%;
            max-width: 1200px;
            border: 0 none;
            border-top: 1px solid $white;
            background: transparent;
        }

        &.has-edit-mode {
            .video-button-container {
                z-index: 1;
            }

            .platzhalter-bild {
                opacity: 0.7;
                z-index: 2;
            }
        }
    }
}

body:not(.has-edit-mode-menu) {
    #wrapper {
        .video {
            .video-container {
                &.fullscreen {
                    width: 100vw;
                    height: 99vh;
                    border-radius: 0px !important;

                    @include tablet-only {
                        height: auto;
                    }

                    @include mobile {
                        height: auto;
                    }

                    .platzhalter-bild {
                        border-radius: 0px !important;
                    }
                }
            }
        }

    }
}
