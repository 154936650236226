.is-highlight-color {
    color: $secondary-color !important;
}

.disabled-element {
    pointer-events: none;
}

.is-clearfix {
    @include clearfix;
}

.is-pulled-left {
    float: left !important; // stylelint-disable-line declaration-no-important
}

.is-pulled-right {
    float: right !important; // stylelint-disable-line declaration-no-important
}

// Overflow

.is-clipped {
    overflow: hidden !important; // stylelint-disable-line declaration-no-important
}

// Typography

$alignments: ("centered": "center", "justified": "justify", "left": "left", "right": "right");

@each $alignment, $text-align in $alignments {
    .has-text-#{$alignment} {
        text-align: #{$text-align} !important; // stylelint-disable-line declaration-no-important
    }
}

@each $alignment, $text-align in $alignments {
    @include mobile {
        .has-text-#{$alignment}-mobile {
            text-align: #{$text-align} !important; // stylelint-disable-line declaration-no-important
        }
    }

    @include tablet {
        .has-text-#{$alignment}-tablet {
            text-align: #{$text-align} !important; // stylelint-disable-line declaration-no-important
        }
    }

    @include tablet-only {
        .has-text-#{$alignment}-tablet-only {
            text-align: #{$text-align} !important; // stylelint-disable-line declaration-no-important
        }
    }

    @include touch {
        .has-text-#{$alignment}-touch {
            text-align: #{$text-align} !important; // stylelint-disable-line declaration-no-important
        }
    }

    @include desktop {
        .has-text-#{$alignment}-desktop {
            text-align: #{$text-align} !important; // stylelint-disable-line declaration-no-important
        }
    }

    @include desktop-only {
        .has-text-#{$alignment}-desktop-only {
            text-align: #{$text-align} !important; // stylelint-disable-line declaration-no-important
        }
    }

    @include widescreen {
        .has-text-#{$alignment}-widescreen {
            text-align: #{$text-align} !important; // stylelint-disable-line declaration-no-important
        }
    }

    @include widescreen-only {
        .has-text-#{$alignment}-widescreen-only {
            text-align: #{$text-align} !important; // stylelint-disable-line declaration-no-important
        }
    }

    @include fullhd {
        .has-text-#{$alignment}-fullhd {
            text-align: #{$text-align} !important; // stylelint-disable-line declaration-no-important
        }
    }
}

.is-capitalized {
    text-transform: capitalize !important; // stylelint-disable-line declaration-no-important
}

.is-lowercase {
    text-transform: lowercase !important; // stylelint-disable-line declaration-no-important
}

.is-uppercase {
    text-transform: uppercase !important; // stylelint-disable-line declaration-no-important
}

.is-italic {
    font-style: italic !important; // stylelint-disable-line declaration-no-important
}

.font-size-xs {
    &,
    p {
        font-size: 14px;
    }
}

.has-regular-text {
    &,
    p {
        font-size: 20px;
        line-height: 28px;
    }
}

.flow-text {
    &,
    p {
        font-size: 1.68rem;
    }
}

.has-small-text {
    &,
    p {
        font-size: 16px;
        line-height: 23px;
    }
}

.has-big-text {
    font-size: 25px;
}

.has-text-weight-light {
    font-weight: $weight-light !important; // stylelint-disable-line declaration-no-important
}

.has-text-weight-normal {
    font-weight: $weight-normal !important; // stylelint-disable-line declaration-no-important
}

.has-text-weight-medium {
    font-weight: $weight-medium !important; // stylelint-disable-line declaration-no-important
}

.has-text-weight-semibold {
    font-weight: $weight-semibold !important; // stylelint-disable-line declaration-no-important
}

.has-text-weight-bold {
    font-weight: $weight-bold !important; // stylelint-disable-line declaration-no-important
}

.is-family-sans-serif {
    font-family: $family-sans-serif !important; // stylelint-disable-line declaration-no-important
}

.is-family-monospace {
    font-family: $family-monospace !important; // stylelint-disable-line declaration-no-important
}

#wrapper {
    .container-separator {
        &.container {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        .separator {
            margin: 0;
            padding: 0;
            border-top: 1px solid #eee;
        }
    }
}

// Visibility
$displays: "block" "flex" "inline" "inline-block" "inline-flex";

@each $display in $displays {
    .is-#{$display} {
        display: #{$display} !important; // stylelint-disable-line declaration-no-important
    }

    @include mobile {
        .is-#{$display}-mobile {
            display: #{$display} !important; // stylelint-disable-line declaration-no-important
        }
    }

    @include tablet {
        .is-#{$display}-tablet {
            display: #{$display} !important; // stylelint-disable-line declaration-no-important
        }
    }

    @include tablet-only {
        .is-#{$display}-tablet-only {
            display: #{$display} !important; // stylelint-disable-line declaration-no-important
        }
    }

    @include touch {
        .is-#{$display}-touch {
            display: #{$display} !important; // stylelint-disable-line declaration-no-important
        }
    }

    @include desktop {
        .is-#{$display}-desktop {
            display: #{$display} !important; // stylelint-disable-line declaration-no-important
        }
    }

    @include desktop-only {
        .is-#{$display}-desktop-only {
            display: #{$display} !important; // stylelint-disable-line declaration-no-important
        }
    }

    @include widescreen {
        .is-#{$display}-widescreen {
            display: #{$display} !important; // stylelint-disable-line declaration-no-important
        }
    }

    @include widescreen-only {
        .is-#{$display}-widescreen-only {
            display: #{$display} !important; // stylelint-disable-line declaration-no-important
        }
    }

    @include fullhd {
        .is-#{$display}-fullhd {
            display: #{$display} !important; // stylelint-disable-line declaration-no-important
        }
    }
}

.hidden,
.is-hidden {
    display: none !important; // stylelint-disable-line declaration-no-important
}

.is-sr-only {
    position: absolute !important; // stylelint-disable-line declaration-no-important
    width: 0.01em !important; // stylelint-disable-line declaration-no-important
    height: 0.01em !important; // stylelint-disable-line declaration-no-important
    padding: 0 !important; // stylelint-disable-line declaration-no-important
    overflow: hidden !important; // stylelint-disable-line declaration-no-important
    clip: rect(0, 0, 0, 0) !important; // stylelint-disable-line declaration-no-important
    border: none !important; // stylelint-disable-line declaration-no-important
    white-space: nowrap !important; // stylelint-disable-line declaration-no-important
}

.is-print-only,
.is-hidden-screen {
    @media only screen {
        display: none;
    }
}

.is-screen-only,
.is-hidden-print {
    @media only print {
        display: none;
    }
}

.is-hidden-until-tablet {
    display: block; // stylelint-disable-line declaration-no-important
}

.is-show-until-tablet {
    display: none;
}

@media screen and (max-width: 992px) {
    .is-hidden-until-tablet {
        display: none !important; // stylelint-disable-line declaration-no-important
    }

    .is-show-until-tablet {
        display: block !important; // stylelint-disable-line declaration-no-important
    }
}

@include mobile {
    .is-hidden-mobile {
        display: none !important; // stylelint-disable-line declaration-no-important
    }
}

@include tablet {
    .is-hidden-tablet {
        display: none !important; // stylelint-disable-line declaration-no-important
    }
}

@include tablet-only {
    .is-hidden-tablet-only {
        display: none !important; // stylelint-disable-line declaration-no-important
    }
}

@include touch {
    .is-hidden-touch {
        display: none !important; // stylelint-disable-line declaration-no-important
    }
}

@include desktop {
    .is-hidden-desktop {
        display: none !important; // stylelint-disable-line declaration-no-important
    }
}

@include desktop-only {
    .is-hidden-desktop-only {
        display: none !important; // stylelint-disable-line declaration-no-important
    }
}

@include widescreen {
    .is-hidden-widescreen {
        display: none !important; // stylelint-disable-line declaration-no-important
    }
}

@include widescreen-only {
    .is-hidden-widescreen-only {
        display: none !important; // stylelint-disable-line declaration-no-important
    }
}

@include fullhd {
    .is-hidden-fullhd {
        display: none !important; // stylelint-disable-line declaration-no-important
    }
}

// Other

.is-marginless {
    margin: 0 !important; // stylelint-disable-line declaration-no-important
}

.is-paddingless {
    padding: 0 !important; // stylelint-disable-line declaration-no-important
}

.is-radiusless {
    border-radius: 0 !important; // stylelint-disable-line declaration-no-important
}

.is-shadowless {
    box-shadow: none !important; // stylelint-disable-line declaration-no-important
}

.is-unselectable {
    @extend %unselectable;
}

.is-relative {
    position: relative !important; // stylelint-disable-line declaration-no-important
}

$ratios: ("1x1": 100%, "2x1": 50%, "16x9": 56.25%, "3x1": 33.333%, "3x2": 66.6666%, "4x3": 75%, "8x7": 114%, "3x4": 133%, "2x3": 150%);
.ratio {
    position: relative;

    @each $name, $ratio in $ratios {
        &.ratio-#{$name} {
            width: 100%;
            height: auto;
            padding-bottom: $ratio !important; // stylelint-disable-line

            img {
                position: absolute;
                width: 100% !important; // stylelint-disable-line
                height: 100% !important; // stylelint-disable-line
            }

            iframe {
                position: absolute;
                width: 100% !important; // stylelint-disable-line
                height: 100% !important; // stylelint-disable-line
            }
        }
    }
}

@each $name, $ratio in $ratios {
    .ratio-box-#{$name} {
        position: relative;
        max-width: 100%;
        padding-bottom: $ratio !important;
        box-sizing: border-box;
        resize: horizontal;

        .ratio-box-content {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}

$paddings: ("0": 0, "1": 20px, "2": 60px, "3": 100px, "4": 140px);
$margins: ("0": 0, "1": 20px, "2": 60px, "3": 100px, "4": 140px);
@each $name, $padding in $paddings {
    .p-#{$name} {
        padding: $padding !important; // stylelint-disable-line
    }

    .pt-#{$name} {
        padding-top: $padding !important; // stylelint-disable-line
    }

    .pr-#{$name} {
        padding-right: $padding !important; // stylelint-disable-line
    }

    .pb-#{$name} {
        padding-bottom: $padding !important; // stylelint-disable-line
    }

    .pl-#{$name} {
        padding-left: $padding !important; // stylelint-disable-line
    }

    .px-#{$name} {
        padding-right: $padding !important; // stylelint-disable-line
        padding-left: $padding !important; // stylelint-disable-line
    }

    .py-#{$name} {
        padding-top: $padding !important; // stylelint-disable-line
        padding-bottom: $padding !important // stylelint-disable-line
    }
}

@each $name, $margin in $margins {
    .m-#{$name} {
        margin: $margin !important; // stylelint-disable-line
    }

    .mt-#{$name} {
        margin-top: $margin !important; // stylelint-disable-line
    }

    .mr-#{$name} {
        margin-right: $margin !important; // stylelint-disable-line
    }

    .mb-#{$name} {
        margin-bottom: $margin !important; // stylelint-disable-line
    }

    .ml-#{$name} {
        margin-left: $margin !important; // stylelint-disable-line
    }

    .mx-#{$name} {
        margin-right: $margin !important; // stylelint-disable-line
        margin-left: $margin !important; // stylelint-disable-line
    }

    .my-#{$name} {
        margin-top: $margin !important; // stylelint-disable-line
        margin-bottom: $margin !important; // stylelint-disable-line
    }
}

