@charset "utf-8";

/* dont use - until end */
$primary-color: #566367;
$secondary-color: #64b32c;
/* end */

/* use only this variables */
$bg-light-primary: $primary-color;
$bg-light-secondary: #677276;
$bg-dark-primary: #131516;
$bg-dark-secondary: #2c373b;
$bg-dark-cards: #2c373b;
$bg-grey: #eeefef;
$bg-grey-dark: #788285;
$link-color: $secondary-color;
$text: #ffffff;
$text-dark: rgba(0, 0, 0, 0.87);
$text-light-primary: $text;
$text-dark-primary: $bg-light-secondary;
$grey-light: #b3b9ba;
$grey-dark: #616d71;
$white: $text;
$black: #000000;
$text-primary-color: $white;
$card-bg-color: $white;
$card-bg-color-dark: #768285;
$card-bg-color-grey: #eeefef;
$border-light-color: #e0e6e4;
$border-dark-color: #e1e6e5;
$box-shadow-color: #0e1f35;

$accent: $secondary-color;
$info-color: $secondary-color;
$warning-color: #ffa724;
$error-color: #e34a4a;
$not-included-color: #f73f40;

$theme-jaffa: #ee8643;
$theme-cream: #f3cc5f;
$theme-olive: #a7c087;
$theme-viridian: #449778;
$theme-cello: #224663;
$theme-waikawa: #597a9d;
$theme-fuzzy: #be4c5a;
$theme-tan: #681127;

$theme-colors: (
    "bg-light-primary": $primary-color,
    "bg-light-secondary": $bg-light-secondary,
    "bg-dark-primary": $bg-dark-primary,
    "bg-dark-secondary": $bg-dark-secondary,
    "text": $text,
    "text-light-primary": $text,
    "text-light-secondary": $text,
    "text-dark-primary": $text,
    "text-dark-secondary": $text,
    "accent": $accent,
    "white": $white,
    "grey-light": $grey-light,
    "grey-dark": $grey-dark,
);

$theme-background: (
    "light": $primary-color,
    "dark": $bg-dark-primary,
);

$theme-background-secondary: (
    "light": $bg-light-secondary,
    "dark": $bg-dark-secondary,
);

$theme-button-disabled: (
    "light": $grey-light,
    "dark": $grey-dark,
);

:root {
    --primary: #{$primary-color};
    --secondary: #{$secondary-color};
    --black: #{$black};
    --white: #{$white};
}

$family-sans-serif: Boston, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$family-monospace: monospace;
$render-mode: optimizeLegibility;

$size-1: 36px;
$size-2: 30px;
$size-3: 26px;
$size-4: 20px;
$size-5: 16px;
$size-6: 14px;

$size-7: 0.75rem;

$size-small: $size-7 !default;
$size-normal: $size-6 !default;
$size-medium: $size-5 !default;
$size-large: $size-4 !default;

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7;

$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;

// Responsiveness
// The container horizontal gap, which acts as the offset for breakpoints
$gap: 2em;
// Tablets 1024px
$tablet-new: 74em;
// Tablets pro 1372px
$tablet-pro-landscape: 98em;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 48em;
// 960px container + 4rem
$desktop: 60em + (2 * $gap);
$desktop-small-screen: 53em;
// 1152px container + 4rem
$widescreen: 72em + (2 * $gap);
$widescreen-enabled: true;
// 1344px container + 4rem
$fullhd: 84em + (2 * $gap);
$fullhd-enabled: true;

// Miscellaneous
$easing: ease-out;
$radius-small: 0.125em;
$radius: 0.25em;
$radius-large: 0.375em;
$radius-rounded: 290486px;
$speed: 86ms;

$sp0: 0em;
$sp1: 0.25em;
$sp2: 0.50em;
$sp3: 0.75em;
$sp4: 1em;
$sp5: 1.25em;

$hamburger-padding-x: 8px;
$hamburger-padding-y: 8px;
$hamburger-layer-width: 24px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 5px;
$hamburger-layer-color: $white;
$hamburger-hover-opacity: 1;
$hamburger-types: (spin);
