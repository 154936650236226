$title-color: $white !default;
$title-family: false !default;
$title-size: $size-3 !default;
$title-weight: $weight-bold !default;
$title-line-height: 1.125 !default;
$title-strong-color: inherit !default;
$title-strong-weight: inherit !default;
$title-sub-size: 0.75em !default;
$title-sup-size: 0.75em !default;

$subtitle-color: $white !default;
$subtitle-family: false !default;
$subtitle-size: $size-5 !default;
$subtitle-weight: $weight-normal !default;
$subtitle-line-height: 1.25 !default;
$subtitle-strong-color: $white !default;
$subtitle-strong-weight: $weight-semibold !default;
$subtitle-negative-margin: -1.25rem !default;

.title,
.subtitle {
    word-break: break-word;
    hyphens: auto;

    em,
    span {
        font-weight: inherit;
    }

    sub {
        font-size: $title-sub-size;
    }

    sup {
        font-size: $title-sup-size;
    }

    .tag {
        vertical-align: middle;
    }
}

.title {
    color: $title-color;
    font-weight: $title-weight;
    line-height: $title-line-height;

    strong {
        color: $title-strong-color;
        font-weight: $title-strong-weight;
    }

    & + .highlight {
        margin-top: -0.75rem;
    }

    &:not(.is-spaced) + .subtitle {
        margin-top: $subtitle-negative-margin;
    }

    @each $size in $sizes {
        $i: index($sizes, $size);
        &.is-#{$i} {
            font-size: $size;

            @if $i < 5 {
                @include mobile {
                    font-size: $size * 0.86;
                }
            }

            @if $i > 4 {
                @include mobile {
                    font-size: $size * 1.16;
                }
            }
        }
    }
}

.subtitle {
    color: $subtitle-color;
    font-size: $subtitle-size;
    font-weight: $subtitle-weight;
    line-height: $subtitle-line-height;

    strong {
        color: $subtitle-strong-color;
        font-weight: $subtitle-strong-weight;
    }

    &:not(.is-spaced) + .title {
        margin-top: $subtitle-negative-margin;
    }

    @each $size in $sizes {
        $i: index($sizes, $size);
        &.is-#{$i} {
            font-size: $size;
        }
    }
}
