#wrapper {
    .content-header {
        color: $white;

        @include mobile {
            padding: 40px 0;
        }

        .container {
            padding: 0 10%;
            padding-top: 0 !important;
        }

        .header-container {
            background-color: transparent;

            &.is-cover {
                background-size: cover !important;
            }
        }

        &:not(.is-secondary) {
            background-color: $bg-light-primary;

            @include darkMode {
                background-color: $bg-dark-primary;
            }

            &:after {
                background-color: $bg-light-primary;

                @include darkMode {
                    background-color: $bg-dark-primary;
                }
            }
        }

        &.is-secondary {
            background-color: $bg-light-secondary;

            @include darkMode {
                background-color: $bg-dark-secondary;
            }

            &:after {
                background-color: $bg-light-secondary;

                @include darkMode {
                    background-color: $bg-dark-secondary;
                }
            }
        }

        &.has-image,
        &.has-background-image,
        &.has-video {

            &:before,
            &:after {
                display: none !important;
            }

            .header-container {
                display: grid;
                grid-template-columns: 2fr 3fr;
                grid-template-rows: 1fr;
                grid-gap: 100px;

                @include mobile {
                    grid-template-columns: 1fr;
                    grid-template-rows: auto;
                    grid-gap: 40px;
                    margin-bottom: 0;
                }

                @include tablet-only {
                    grid-template-columns: 1fr;
                    grid-template-rows: auto;
                    grid-gap: 40px;
                    margin-bottom: 0;
                }

                &:before,
                &:after {
                    display: none !important;
                }

                .explanation-video {
                    margin-top: 1rem;

                    iframe {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            &.has-image-right,
            &.has-video-right {
                .header-container {
                    grid-template-columns: 3fr 2fr;

                    @include mobile {
                        grid-template-columns: 1fr;
                        padding-right: 10%;
                    }

                    @include tablet-only {
                        grid-template-columns: 1fr;
                        padding-right: 10%;
                    }
                }

                .content-header-body {
                    @include tablet {
                        grid-area: 1 / 1 / 2 / 2;
                    }
                }

                .content-header-disclaimer {
                    text-align: right;

                    @include mobile {
                        text-align: left;
                    }

                    @include tablet-only {
                        text-align: left;
                    }
                }
            }

            &:not(.explanation):after {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 0;
                width: 100%;
                height: 160px;
                margin-top: -134px;

                @include tablet-only {
                    display: none;
                }

                @include mobile {
                    display: none;
                }
            }
        }

        &.has-background-image {
            .container {
                @include mobile {
                    padding: 0;
                }

                .header-container {
                    padding: 80px 2rem;
                    border-radius: 10px;
                    -webkit-box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
                    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
                    background-position: center center;
                    background-size: cover;

                    @include mobile {
                        padding: 80px 10%;
                    }
                }
            }

            .header-container {
                align-items: center;
            }
        }

        &:not(.explanation).has-image,
        &:not(.explanation).has-video {
            .header-container,
            .explanation-container {

                @include tablet {
                    padding-left: 0;
                }
            }

            &.has-image-right,
            &.has-video-right {
                .header-container {
                    padding-right: 0;
                }
            }
        }

        &-disclaimer {
            margin-top: 10px;
        }

        &-image {
            overflow: hidden;
            border-radius: 10px;

            img {
                width: 100%;
            }
        }

        &-images {
            display: grid;
            position: relative;
            z-index: 2;
            grid-template-columns: repeat(1, 1fr);
            grid-auto-rows: minmax(min-content, max-content);

            @include mobile {
                order: 2;
            }

            @include tablet-only {
                order: 2;
            }

            &.is-1-2,
            &.is-2-2 {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, auto) 1fr;
                grid-gap: 20px;

                .content-header-disclaimer,
                .explanation-disclaimer {
                    grid-column: 1 / span 2;
                    margin-top: -10px;
                }
            }

            &.is-1-2 {
                .content-header-image,
                .explanation-image {
                    &:first-child {
                        grid-column: 1 / span 2;
                    }
                }
            }
        }

        &-body {
            @include mobile {
                order: 1;
            }
        }

        &:not(.has-background-image) {
            .list-element {
                &.has-text-centered {
                    justify-content: center;
                    text-align: center !important;
                }

                + .list-element {
                    margin-top: 1rem;
                }

            }
        }

        .list-element {
            width: 100%;
            display: flex;
            text-align: left !important;
            flex-direction: row;
            align-content: center;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem .5rem;
            overflow: hidden;


            &-icon {
                height: auto;
                width: 30px;
                min-width: 30px;
                max-width: 30px;
                text-align: center;
                padding: 2px;
                position: relative;

                svg {
                    width: 100%;
                    height: auto;
                }
            }

            &-title,
            &-text {
                color: #ffffff;
            }

            &-title {
                font-size: 18px;
            }

        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 1rem;

        }

        &.has-text-centered:not(.has-image) .content-header-body,
        &.has-text-centered:not(.has-video) .content-header-body {
            margin-right: auto;
            margin-left: auto;
            max-width: 864px;
        }

        .title.is-3 {
            margin: 60px 0 0;

            @include mobile {
                margin: 0;
            }
        }

        .title.is-2 {
            margin: 10px 0 30px;
        }

        &-paragraph,
        &-actions {
            position: relative;
            z-index: 2;
        }

        &-paragraph {
            margin-bottom: 20px;
            hyphens: auto;
        }

        &-actions {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            margin-top: 40px;
            font-weight: 700;

            &.has-text-centered {
                justify-content: center;
            }

            .icon {
                width: 30px;
                height: 30px;
                flex-shrink: 0;

                svg {
                    fill: #fff;
                }
            }

            a:not(.button) {
                display: inline-flex;
                align-items: center;
                font-size: 20px;

                .icon {
                    margin-right: 10px;
                }
            }
        }


        .content-header-separator {
            margin: 20px auto;
            width: 100%;
            max-width: 1200px;
            border: 0 none;
            border-top: 1px solid #ffffff;
            background: transparent;
        }

        &.has-edit-mode {
            .content-header-actions {
                div.page-editor__root {
                    display: flex !important;

                    > div {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }

    div[id*='fragment'] {
        .content-header {
            .content-header-actions {
                > div {
                    display: flex;

                    > div {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}
